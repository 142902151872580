import { BASE_COLORS, FONT_WEIGHT } from "@app/styles/themes/constants";
import { Button } from "antd";
import styled from "styled-components";

export const SuperhubPinkFilledButton = styled(Button)`
height: 35px;
background:var(--primary-color);
color:${BASE_COLORS.white};
font-family: 'Segoe UI Bold', 'Poppins', sans-serif;
font-weight: ${FONT_WEIGHT.semibold};
font-size: 16px;
padding:5px 10px;
border-radius:5px;
border: 0px;
margin:5px;
text-align: center;

&:hover {
  background:var(--primary-color)!important;
  color:${BASE_COLORS.white}!important;
  cursor: pointer;
}

&:focus{
  background:var(--primary-color)!important;
  color:${BASE_COLORS.white}!important;
}

&:disabled, 
[disabled]{
  background:rgba(0,0,0,0.25)!important;
  color:rgba(0,0,0,0.25)!important;
}
`;

export const RenewalCenterButton = styled(SuperhubPinkFilledButton)`
  margin:5px 0px;
`;

export const SuperhubPinkSmallButton = styled(SuperhubPinkFilledButton)`
  font-size:12px;
`

export const SuperhubPinkOutlineButton = styled(SuperhubPinkFilledButton)`
background:${BASE_COLORS.white};
color:var(--primary-color);
border: 2px solid var(--primary-color);
text-shadow: none;
text-align: center;
text-decoration: none;

&:hover,
:focus {
  border: 2px solid var(--primary-color);
  }
  
`;

export const BlackOutlineButton = styled(SuperhubPinkFilledButton)`
background:${BASE_COLORS.white};
color:#000000;
border: 1px solid #000000;
`;

export const RedOutlineButton = styled(BlackOutlineButton)`
color:#FF0000;
border: 1px solid #FF0000!important;
`;

export const PaleGreyTag = styled.div`
width:fit-content;
background:var(--textLightGrey);
color:${BASE_COLORS.white};
font-family: 'Segoe UI Bold', 'Poppins', sans-serif;
font-weight: ${FONT_WEIGHT.semibold};
font-size: 16px;
padding:5px 20px 5px 20px;
border-radius:5px;
margin:5px 0px;

`;

export const TransparentButton = styled.button`
background: transparent;
margin: 0px;
border: 0px;
display:flex;
align-items:center;

&:hover,
:focus {
  cursor: pointer;
}
`;