export interface MenuProps{
  [key: string]: {
    theme: string;
    subtitles: SubMenuProps[];
  }
};
export interface SubMenuProps
{
  subtitle:string;
  link:string;
  badge:boolean;
  openNewTab:boolean;
}

export const AdminSubMenuContent: MenuProps= {
  profile: {
    theme: "Profile Center", subtitles: [
      { subtitle: "Accounts", link: `${process.env.REACT_APP_ADMIN_URL}/profile-center/accounts`, badge: false ,openNewTab:false },
    ]
  },
  document: {
    theme: "Document Center ", subtitles: [
      { subtitle: "Uploaded Document", link: `${process.env.REACT_APP_ADMIN_URL}/document-center/uploaded-document`, badge: false,openNewTab:false },
      { subtitle: "Upload Center", link: `${process.env.REACT_APP_ADMIN_URL}/document-center/upload-center`, badge: false,openNewTab:false },
    ]
  },
  token: {
    theme: "Token Center ", subtitles: [
      { subtitle: "Customers", link: `${process.env.REACT_APP_ADMIN_URL}/token-center/customers`, badge: false,openNewTab:false },
    ]
  },
  renewal_superadmin:{
    theme: "Renewal Center ", 
    subtitles:[
      { subtitle: "Sales - Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/sales/overview`, badge: false,openNewTab:false },
      { subtitle: "Sales - Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/sales/customers`, badge: false,openNewTab:false },
      { subtitle: "AS - Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/accounts/overview`, badge: false,openNewTab:false },
      { subtitle: "AS - Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/accounts/customers`, badge: false,openNewTab:false },
      { subtitle: "OP - Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/op/overview`, badge: false,openNewTab:false },
      { subtitle: "OP - Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/op/customers`, badge: false,openNewTab:false },
      { subtitle: "AR - Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/ar/overview`, badge: false,openNewTab:false },
      { subtitle: "AR - Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/ar/customers`, badge: false,openNewTab:false },
      { subtitle: "Customer Success - Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/customer-success/overview`, badge: false, openNewTab:false },
      { subtitle: "Customer Success - Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/customer-success/customers`, badge: false, openNewTab:false }
    ]
  },
  renewal_as:{
    theme: "Renewal Center ", 
    subtitles:[
      { subtitle: "Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/accounts/overview`, badge: false,openNewTab:false },
      { subtitle: "Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/accounts/customers`, badge: false,openNewTab:false },
    ]
  },
  renewal_sales:{
    theme: "Renewal Center ", 
    subtitles:[
      { subtitle: "Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/sales/overview`, badge: false,openNewTab:false },
      { subtitle: "Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/sales/customers`, badge: false,openNewTab:false },
    ]
  },
  renewal_cs:{
    theme: "Renewal Center ", 
    subtitles:[
      { subtitle: "Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/op/overview`, badge: false,openNewTab:false },
      { subtitle: "Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/op/customers`, badge: false,openNewTab:false },
    ]
  },
  renewal_finance:{
    theme: "Renewal Center ", 
    subtitles:[
      { subtitle: "Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/ar/overview`, badge: false,openNewTab:false },
      { subtitle: "Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/ar/customers`, badge: false,openNewTab:false },
    ]
  },
  renewal_customersuccess:{
    theme: "Renewal Center ", 
    subtitles:[
      { subtitle: "Overview", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/customer-success/overview`, badge: false,openNewTab:false },
      { subtitle: "Customers", link:`${process.env.REACT_APP_ADMIN_URL}/renewal-center/customer-success/customers`, badge: false,openNewTab:false }
    ]
  },
  advisory: { theme: "Advisory Center ", subtitles: [] },
  msUpdate: { theme: "MS Update Center ", subtitles: [] },
  promotion: { theme: "Promotion Center ", subtitles: [] },
  support: {
    theme: "Support Center", subtitles: [
      { subtitle: "Special Announcement (Admin) ", link: `${process.env.REACT_APP_ADMIN_URL}/support-center/special-announcement`, badge: false ,openNewTab:false},
      { subtitle: "Superhub Hotline (Admin) ", link: `${process.env.REACT_APP_ADMIN_URL}/support-center/superhub-hotline`, badge: false,openNewTab:false },
      { subtitle: "Standard Support SLA (Admin) ", link: `${process.env.REACT_APP_ADMIN_URL}/support-center/standard-support-sla`, badge: false ,openNewTab:false},
      { subtitle: "MS Admin Center Links (Admin) ", link: `${process.env.REACT_APP_ADMIN_URL}/support-center/ms-admin-center-links`, badge: false,openNewTab:false },
    ]
  }
};

export const UserSubMenuContent = {
  profile: {
    theme: "Profile Center", subtitles: [
      { subtitle: "Contacts", link: `${process.env.REACT_APP_USER_URL}/profile-center`, badge: false,openNewTab:false },
    ]
  },
  document: {
    theme: "Document Center ", subtitles: [
      { subtitle: "Uploaded Document", link: `${process.env.REACT_APP_USER_URL}/document-center/uploaded-document`, badge: false,openNewTab:false },
      { subtitle: "Upload Center", link: `${process.env.REACT_APP_USER_URL}/document-center/upload-center`, badge: false,openNewTab:false },
    ]
  },
  token: {
    theme: "Token Center ", subtitles: [
      // { subtitle: "Token Tracker ", link: `${process.env.REACT_APP_USER_URL}/token-center/token-tracker`, badge: false },
      { subtitle: "Token Consumption Log ", link: `${process.env.REACT_APP_USER_URL}/token-center/token-consumption-log`, badge: false,openNewTab:false },
    ]
  },
  renewal:{
    theme: "Renewal Center ", subtitles:[
      { subtitle: "Overview", link: `${process.env.REACT_APP_USER_URL}/renewal-center`, badge: false,openNewTab:false },
      { subtitle: "Subscriptions", link: `${process.env.REACT_APP_USER_URL}/renewal-center/subscription`, badge: false,openNewTab:false },
    ]
  },
  billing:{
    theme: "Billing Center ", subtitles:[
      { subtitle: "Overview", link: `${process.env.REACT_APP_USER_BILLING_CENTER_URL}`, badge:false,openNewTab:false},
    ]
  },
  advisory: { theme: "Advisory Center ", subtitles: [] },
  msUpdate: { theme: "MS Update Center ", subtitles: [] },
  promotion: { theme: "Promotion Center ", subtitles: [] },
  support: {
    theme: "Support Center", subtitles: [
      { subtitle: "Special Announcement ", link: `${process.env.REACT_APP_USER_URL}/support-center/special-announcement`, badge: true ,openNewTab:false},
      { subtitle: "Superhub Hotline ", link: `${process.env.REACT_APP_USER_URL}/support-center/superhub-hotline`, badge: false,openNewTab:false },
      { subtitle: "Standard Support SLA ", link: `${process.env.REACT_APP_USER_URL}/support-center/standard-support-sla`, badge: false ,openNewTab:false},
      { subtitle: "MS Admin Center Links ", link: `${process.env.REACT_APP_USER_URL}/support-center/ms-admin-center-links`, badge: false ,openNewTab:false},
    ]
  }
};