export enum ClarityAPIEventType {
    // Authentication
    REQUEST_MAGIC_LINK_SUCCESS_EVENT = "request-magic-link-success",
    REQUEST_MAGIC_LINK_ERROR_EVENT = "request-magic-link-error",
    AUTHENTICATE_MAGIC_LINK_SUCCESS_EVENT = "authenticate-magic-link-success",
    AUTHENTICATE_MAGIC_LINK_ERROR_EVENT = "authenticate-magic-link-error",
    AUTHENTICATE_MSOL_SUCCESS_EVENT = "authenticate-msol-success",
    AUTHENTICATE_MSOL_ERROR_EVENT = "authenticate-msol-error",
    LOGOUT_USER_EVENT = 'logout-success',

    // Profile Center
    GET_CUSTOMER_ACCOUNT_SUCCESS_EVENT = "get-customer-account-success",
    GET_CUSTOMER_ACCOUNT_ERROR_EVENT = "get-customer-account-error",
    GET_CUSTOMER_TOKEN_SUMMARY_SUCCESS_EVENT = "get-customer-token-summary-success",
    GET_CUSTOMER_TOKEN_SUMMARY_ERROR_EVENT = "get-customer-token-summary-error",
    GET_CUSTOMER_CONTACTS_SUCCESS_EVENT = "get-customer-contacts-success",
    GET_CUSTOMER_CONTACTS_ERROR_EVENT = "get-customer-contacts-error",
    GET_CUSTOMER_CONTACTS_HISTORY_SUCCESS_EVENT = "get-customer-contacts-history-success",
    GET_CUSTOMER_CONTACTS_HISTORY_ERROR_EVENT = "get-customer-contacts-history-error",
    GET_CUSTOMER_CONTACTS_LEGENDS_SUCCESS_EVENT = "get-customer-contacts-legends-success",
    GET_CUSTOMER_CONTACTS_LEGENDS_ERROR_EVENT = "get-customer-contacts-legends-error",
    SEND_REQUEST_FOR_CONTACTS_CHANGE_SUCCESS_EVENT = "send-request-for-contacts-change-success",
    SEND_REQUEST_FOR_CONTACTS_CHANGE_ERROR_EVENT = "send-request-for-contacts-change-error",

    // Token Center
    GET_CONTRACTS_SUCCESS_EVENT="get-contracts-success",
    GET_CONTRACTS_ERROR_EVENT="get-contracts-error",
    GET_CONTRACT_SET_SUCCESS_EVENT="get-contracts-with-same-contract-number-success",
    GET_CONTRACT_SET_ERROR_EVENT="get-contracts-same-contract-number-error",
    GET_TICKETS_SUCCESS_EVENT="get-tickets-success",
    GET_TICKETS_ERROR_EVENT="get-tickets-error",
    GET_LOGS_SUCCESS_EVENT="get-logs-success",
    GET_LOGS_ERROR_EVENT="get-logs-error",
    SEARCH_CONTRACTS_SUCCESS_EVENT="search-contracts-success",
    SEARCH_CONTRACTS_ERROR_EVENT="search-contracts-error",
    UPDATE_CONTRACT_SUCCESS_EVENT="update-contract-success",
    UPDATE_CONTRACT_ERROR_EVENT="update-contract-error",
    SEARCH_TICKETS_SUCCESS_EVENT="search-tickets-success",
    SEARCH_TICKETS_ERROR_EVENT="search-tickets-error",
    UPDATE_TICKET_SUCCESS_EVENT="update-ticket-success",
    UPDATE_TICKET_ERROR_EVENT="update-ticket-error",
    ADD_LOG_SUCCESS_EVENT="add-log-success",
    ADD_LOG_ERROR_EVENT="add-log-error",
    UPDATE_LOG_SUCCESS_EVENT="update-log-success",
    UPDATE_LOG_ERROR_EVENT="update-log-error",

    // Renewal Center
    GET_RENEWAL_TASKS_SUCCESS_EVENT="get-renewal-tasks-success",
    GET_RENEWAL_TASKS_ERROR_EVENT="get-renewal-tasks-error",
    GET_OFFER_LETTERS_SUCCESS_EVENT="get-offer-letters-success",
    GET_OFFER_LETTERS_ERROR_EVENT="get-offer-letters-error",
    GET_CUSTOMER_OFFER_LETTERS_SUCCESS_EVENT="get-customer-offer-letters-success",
    GET_CUSTOMER_OFFER_LETTERS_ERROR_EVENT="get-customer-offer-letters-error",
    GET_OFFER_LETTER_HISTORY_SUCCESS_EVENT="get-offer-letter-history-success",
    GET_OFFER_LETTER_HISTORY_ERROR_EVENT="get-offer-letter-history-error",
    GET_OFFER_LETTER_VERSIONS_SUCCESS_EVENT="get-offer-letter-versions-success",
    GET_OFFER_LETTER_VERSIONS_ERROR_EVENT="get-offer-letter-hisversionstory-error",
    GET_PROFORMA_INVOICES_SUCCESS_EVENT="get-overview-proforma-invoices-success",
    GET_PROFORMA_INVOICES_ERROR_EVENT="get-overview-proforma-invoices-error",
    GET_SUBSCRIPTIONS_SUCCESS_EVENT="get-subscriptions-success",
    GET_SUBSCRIPTIONS_ERROR_EVENT="get-subscriptions-error",
    GET_BILLING_SUBSCRIPTIONS_SUCCESS_EVENT="get-other-bill-to-subscriptions-success",
    GET_BILLING_SUBSCRIPTIONS_ERROR_EVENT="get-other-bill-to-subscriptions-error",
    GET_ENQUIRY_RECORD_SUCCESS_EVENT="get-enquiry-records-success",
    GET_ENQUIRY_RECORD_ERROR_EVENT="get-enquiry-records-error",
    GET_LETTER_SUCCESS_EVENT = "get-offer-letter-success",
    GET_LETTER_ERROR_EVENT = "get-offer-letter-error",
    UPDATE_OVERALL_LETTER_SUCCESS_EVENT="update-overall-letter-success",
    UPDATE_OVERALL_LETTER_ERROR_EVENT="update-overall-letter-error",
    UPDATE_LETTER_ITEM_QUANTITY_SUCCESS_EVENT="update-letter-item-quantity-success",
    UPDATE_LETTER_ITEM_QUANTITY_ERROR_EVENT="update-letter-item-quantity-error",
    CONFIRM_LETTER_SUCCESS_EVENT="confirm-letter-success",
    CONFIRM_LETTER_ERROR_EVENT="confirm-letter-error",
    SEND_ASK_FOR_HELP_SUCCESS_EVENT = "send-ask-for-help-success",
    SEND_ASK_FOR_HELP_ERROR_EVENT = "send-ask-for-help-error",
    DOWNLOAD_LETTER_PDF_SUCCESS_EVENT="download-letter-pdf-success",
    DOWNLOAD_LETTER_PDF_ERROR_EVENT="download-letter-pdf-error",
    DOWNLOAD_PROFORMA_INVOICE_SUCCESS_EVENT="download-proforma-invoice-pdf-success",
    DOWNLOAD_PROFORMA_INVOICE_ERROR_EVENT="download-proforma-invoice-pdf-error",
    UPDATE_PROFORMA_INVOICE_AS_PAID_SUCCESS_EVENT="update-proforma-invoice-as-paid-success",
    UPDATE_PROFORMA_INVOICE_AS_PAID_ERROR_EVENT="update-proforma-invoice-as-paid-error",
    SEND_EMAIL_PROFORMA_INVOICE_SUCCESS_EVENT="send-email-alert-for-proforma-invoice-success",
    SEND_EMAIL_PROFORMA_INVOICE_ERROR_EVENT="send-email-alert-for-proforma-invoice-error",
    SEND_GET_QUOTE_REQUEST_SUCCESS_EVENT="send-get-quote-request-success",
    SEND_GET_QUOTE_REQUEST_ERROR_EVENT="send-get-quote-request-error",
    ADD_RENEWAL_TASK_SUCCESS_EVENT = "add-renewal-task-success",
    ADD_RENEWAL_TASK_ERROR_EVENT = "add-renewal-task-error",
    EDIT_RENEWAL_TASK_SUCCESS_EVENT = "edit-renewal-task-success",
    EDIT_RENEWAL_TASK_ERROR_EVENT = "edit-renewal-task-error",
    DELETE_RENEWAL_TASK_SUCCESS_EVENT = "delete-renewal-task-success",
    DELETE_RENEWAL_TASK_ERROR_EVENT = "delete-renewal-task-error",
    ARCHIVE_LETTER_SUCCESS_EVENT="archive-letter-success",
    ARCHIVE_LETTER_ERROR_EVENT="archive-letter-error",
    UNARCHIVE_LETTER_SUCCESS_EVENT="unarchive-letter-success",
    UNARCHIVE_LETTER_ERROR_EVENT="unarchive-letter-error",
    GET_SUBSCRIPTION_SUGGESTION_SUCCESS_EVENT="get-subscription-suggestion-success",
    GET_SUBSCRIPTION_SUGGESTION_ERROR_EVENT="get-subscription-suggestion-error",
    REMOVE_SUBSCRIPTION_SUGGESTION_SUCCESS_EVENT="remove-subscription-suggestion-success",
    REMOVE_SUBSCRIPTION_SUGGESTION_ERROR_EVENT="remove-subscription-suggestion-error",
    ADD_OFFER_LETTER_ITEM_SUCCESS_EVENT = "add-offer-letter-item-success",
    ADD_OFFER_LETTER_ITEM_ERROR_EVENT = "add-offer-letter-item-success",
    DELETE_OFFER_LETTER_ITEM_SUCCESS_EVENT = "delete-offer-letter-item-success",
    DELETE_OFFER_LETTER_ITEM_ERROR_EVENT = "delete-offer-letter-item-success",

    // Billing Center
    ACCOUNT_STATEMENT_GET_VALID_MSG_SUCCESS_EVENT= "account-statement-get-valid-res-success",
    ACCOUNT_STATEMENT_GET_VALID_MSG_ERROR_EVENT = "account-statement-get-valid-res-error",
    ACCOUNT_STATEMENT_SEND_ACTIVATE_EMAIL_SUCCESS_EVENT = "account-statement-send-activate-email-success",
    ACCOUNT_STATEMENT_SEND_ACTIVATE_EMAIL_ERROR_EVENT = "account-statement-send-activate-email-error",
    ACCOUNT_STATEMENT_GET_INVALID_MSG_SUCCESS_EVENT = "account-statement-get-invalid-res-success",
    ACCOUNT_STATEMENT_GET_INVALID_MSG_ERROR_EVENT = "account-statement-get-invalid-res-error",
}