import React, { useState } from 'react';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from "../../../nft-dashboard/common/CommonComponentStyle.styles";
import { Outlet } from 'react-router-dom';
import { BurgerIcon } from '@app/components/common/icons/BurgerIcon';
import { ProfileDropdown } from '@app/components/header/components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { Col, Row } from 'antd';
import "./MainLayout.css";
import { MenuProps} from '@app/components/router/Menu';
import { Sider } from './Sider';
import { GlobalSearch } from './GlobalSearch';
import { SubSider } from './SubSider';
import { readClaim } from '@app/services/localStorage.service';
import { useClarity } from 'use-clarity';


export interface MainLayoutProps {
  menuItems: MenuProps
}

const MainLayout: React.FC<MainLayoutProps> = ({
  menuItems = {}
}) => {
  // Store user email from claim in clarity 
  const { identify } = useClarity();
  const claim = readClaim();
  if (claim) identify(claim.email);

  const [subMenu, setSubMenu] = useState(menuItems["profile"])
  const [isToggled, setToggled] = useState(false);

  const BurgerButton = () => {
    return (
      <button className={isToggled == true ? "burger-button-expand" : "burger-button-collapse"} onClick={() => { setToggled(!isToggled) }}><BurgerIcon /></button>
    )
  }

  return (
    <S.LayoutMaster>
      <>
        <Sider menuItems={menuItems} setSubMenu={setSubMenu} setToggled={setToggled}/>
        <SubSider subMenu={subMenu} isToggled={isToggled}/>
      </>
      <S.LayoutMain style={{ width: '100vw', height: '100vh' }}>
        <MainHeader isToggled={isToggled}>
          <Row className={isToggled == true ? "header-container-expand" : "header-container-collapse"}>
            <Col xs={2} sm={0} md={0} lg={0} xl={0}>
              <BurgerButton />
            </Col>
            <Col xs={0} sm={0} md={12} lg={8} xl={8}>
              {/* <div className={isToggled == false ? "logo-expand" : "logo-collapse"}>
                <Logo />
              </div> */}
              <BurgerButton />
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <GlobalSearch menuItems={menuItems}/>
            </Col>
            <Col xs={24} sm={24} md={10} lg={8} xl={8}>
              <S.AlignRightCol style={{ width: "75vw" }}>
                <ProfileDropdown />
              </S.AlignRightCol>
            </Col>
          </Row>
        </MainHeader>
        <S.MainContent>
          <div className={isToggled == true ? "main-content-expand" : "main-content-collapse"}>
            <Outlet />
          </div>
        </S.MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
