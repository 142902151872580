import { BASE_COLORS, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const CountDownText = styled.div`
padding-top: 8px;
font-size: 0.8rem;
font-weight: ${FONT_WEIGHT.semibold};
display: none;

@media only screen and ${media.md} {
  display: flex;
}
`
