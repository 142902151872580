import { httpApi } from "../http.api";
import { AdminLog, ContractData, CustomerData, FlattenedAdminLogData, GeneralLog, GeneralLogContract, TicketData, StatusType, flattenAdminLogData, flattenGeneralData, getTicketData, updateTokenConsumptionLog, LogData, ContractSummary } from "../tokenCenter.api";
import { httpApiMock } from "./http.api.mock";

// httpApiMock.onGet("/getTokenTracker").reply((config) => {
//     return [
//         200,
        // [
//             {
//                 tokenContract: "Modern+Token",
//                 contractNo: "QO0293045",
//                 contractPeriod: "Yearly",
//                 balanceCycle: "Yearly",
//                 expiryDate: "1/5/2024",
//                 openBalance: 10,
//                 latestBalance: 2,
//                 latestBalanceExpiryDate: "1/5/2024",
//                 tokenRecords: "Details -> Token Consumption Log",
//             },
//             {
//                 tokenContract: "Modern+Token",
//                 contractNo: "QO0293045",
//                 contractPeriod: "Yearly",
//                 balanceCycle: "Monthly",
//                 expiryDate: "1/5/2024",
//                 openBalance: 5,
//                 latestBalance: 2,
//                 latestBalanceExpiryDate: "3/5/2023",
//                 tokenRecords: "",
//             }
//         ]
//     ]
// })


// export const getMockAdminLog: AdminLog[] = [
//     {
//         customer: "ABC Company Ltd",
//         contracts: [
//             {
//                 tokenContract: "QO0293045",
//                 groups: [
//                     {
//                         tokenContract: "QO0293045",
//                         serviceTicketRefNo: "ME-230123-01",
//                         ticketDate: "2023-03-23",
//                         tokenBalanceFrom: "10",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "7",
//                         ticketStatus: "Completed",
//                         description: "Customer reported an issue with login. Checked the system logs and found an error related to session timeout. Increased session timeout in the system to resolve the issue.",
//                         internalRemark: "Weeks: 3, Engineers: 2, Cost: $12,000, PIC: Jane Smith"
//                     },
//                     {
//                         tokenContract: "QO0293045",
//                         serviceTicketRefNo: "ME-230225-03",
//                         ticketDate: "2023-02-25",
//                         tokenBalanceFrom: "7",
//                         tokenConsumed: "2",
//                         tokenBalanceTo: "7",
//                         ticketStatus: "Cancelled",
//                         description: "Customer reported a missing feature in the product. Confirmed that the feature is not available in the current version. Added the feature request to the product roadmap.",
//                         internalRemark: "Weeks: 1, Engineers: 1, Cost: $4,000, PIC: Mark Johnson",
//                     },
//                     {
//                         tokenContract: "QO0293045",
//                         serviceTicketRefNo: "ME-230327-02",
//                         ticketDate: "2023-03-27",
//                         tokenBalanceFrom: "7",
//                         tokenConsumed: "2",
//                         tokenBalanceTo: "5",
//                         ticketStatus: "Pending",
//                         description: "Customer reported a billing issue. Investigated and found that the issue was caused by a system error. Provided a refund and fixed the error.",
//                         internalRemark: "Weeks: 4, Engineers: 3, Cost: $18,000, PIC: Sarah Lee"
//                     },
//                     {
//                         tokenContract: "QO0293045",
//                         serviceTicketRefNo: "ME-230429-05",
//                         ticketDate: "2023-04-29",
//                         tokenBalanceFrom: "5",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "2",
//                         ticketStatus: "Completed",
//                         description: "Customer reported a performance issue with the product. Checked the system logs and found high CPU usage. Optimized the code to improve performance.",
//                         internalRemark: "Weeks: 2, Engineers: 1, Cost: $6,000, PIC: Michael Chen",
//                     },
//                     {
//                         tokenContract: "QO0293045",
//                         serviceTicketRefNo: "ME-230117-01",
//                         ticketDate: "2023-01-17",
//                         tokenBalanceFrom: "6",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "3",
//                         ticketStatus: "In Progress",
//                         description: "Customer reported a performance issue with the cloud system. Checked the system logs and found high CPU usage. Optimized the code to improve performance.",
//                         internalRemark: "Weeks: 2, Engineers: 1, Cost: $5,500, PIC: Mike Fung",
//                     },
//                 ]
//             },
//             {
//                 tokenContract: "QS8495069",
//                 groups: [
//                     {
//                         tokenContract: "QS8495069",
//                         serviceTicketRefNo: "ME-230123-02",
//                         ticketDate: "2023-01-03",
//                         tokenBalanceFrom: "5",
//                         tokenConsumed: "1",
//                         tokenBalanceTo: "4",
//                         ticketStatus: "Completed",
//                         description: "Customer reported a security vulnerability in the product. Verified the vulnerability and patched the code to fix the issue.",
//                         internalRemark: "Weeks: 3, Engineers: 2, Cost: $10,000, PIC: David Kim",
//                     },
//                     {
//                         tokenContract: "QS8495069",
//                         serviceTicketRefNo: "ME-230125-03",
//                         ticketDate: "2023-01-25",
//                         tokenBalanceFrom: "4",
//                         tokenConsumed: "2",
//                         tokenBalanceTo: "4",
//                         ticketStatus: "Cancelled",
//                         description: "Customer reported an issue with the product documentation. Reviewed the documentation and found errors. Updated the documentation and published the new version.",
//                         internalRemark: "Weeks: 1, Engineers: 1, Cost: $3,000, PIC: Melissa Wong"
//                     },
//                     {
//                         tokenContract: "QS8495069",
//                         serviceTicketRefNo: "ME-230127-04",
//                         ticketDate: "2023-01-27",
//                         tokenBalanceFrom: "4",
//                         tokenConsumed: "2",
//                         tokenBalanceTo: "2",
//                         ticketStatus: "Pending",
//                         description: "Customer reported a connectivity issue with the product. Checked the system logs and found a network error. Restarted the network service to resolve the issue.",
//                         internalRemark: "Weeks: 4, Engineers: 3, Cost: $20,000, PIC: Kevin Liu",
//                     },
//                     {
//                         tokenContract: "QS8495069",
//                         serviceTicketRefNo: "ME-230210-05",
//                         ticketDate: "2023-02-23",
//                         tokenBalanceFrom: "5",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "2",
//                         ticketStatus: "Pending",
//                         description: "Customer reported an issue with the product UI. Investigated and found a CSS conflict. Fixed the conflict and confirmed with the customer that the issue is resolved.",
//                         internalRemark: "Weeks: 2, Engineers: 1, Cost: $5,000, PIC: Emily Chang",
//                     },
//                 ]
//             }
//         ]
//     },
//     {
//         customer: "DEF Company Ltd",
//         contracts: [
//             {
//                 tokenContract: "QP0407098",
//                 groups: [
//                     {
//                         tokenContract: "QP0407098",
//                         serviceTicketRefNo: "ME-230521-04",
//                         ticketDate: "2022-01-23",
//                         tokenBalanceFrom: "10",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "7",
//                         ticketStatus: "Completed",
//                         description: "Customer reported a feature request related to integration with a third-party service. Researched the integration and confirmed that it is possible. Added the integration to the product roadmap.",
//                         internalRemark: "Weeks: 5, Engineers: 4, Cost: $25,000, PIC: Brian Lee",
//                     },
//                     {
//                         tokenContract: "QP0407098",
//                         serviceTicketRefNo: "ME-230123-09",
//                         ticketDate: "2022-01-23",
//                         tokenBalanceFrom: "10",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "7",
//                         ticketStatus: "Completed",
//                         description: "Customer reported an issue with the product performance during peak load. Analyzed the system logs and identified a bottleneck in the database. Optimized the database and improved the performance.",
//                         internalRemark: "Weeks: 1, Engineers: 1, Cost: $2,000, PIC: Rachel Chen",
//                     },
//                     {
//                         tokenContract: "QP0407098",
//                         serviceTicketRefNo: "ME-230225-03",
//                         ticketDate: "2022-02-11",
//                         tokenBalanceFrom: "7",
//                         tokenConsumed: "2",
//                         tokenBalanceTo: "7",
//                         ticketStatus: "Cancelled",
//                         description: "",
//                         internalRemark: "",
//                     },
//                     {
//                         tokenContract: "QP0407098",
//                         serviceTicketRefNo: "ME-230327-01",
//                         ticketDate: "2022-03-28",
//                         tokenBalanceFrom: "7",
//                         tokenConsumed: "2",
//                         tokenBalanceTo: "5",
//                         ticketStatus: "Pending",
//                         description: "",
//                         internalRemark: "",
//                     },
//                     {
//                         tokenContract: "QP0407098",
//                         serviceTicketRefNo: "ME-230429-05",
//                         ticketDate: "2023-02-17",
//                         tokenBalanceFrom: "5",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "2",
//                         ticketStatus: "Completed",
//                         description: "",
//                         internalRemark: "",
//                     },
//                 ]
//             },
//             {
//                 tokenContract: "QF0407066",
//                 groups: [
//                     {
//                         tokenContract: "QF0407066",
//                         serviceTicketRefNo: "ME-230123-09",
//                         ticketDate: "2023-01-23",
//                         tokenBalanceFrom: "5",
//                         tokenConsumed: "1",
//                         tokenBalanceTo: "4",
//                         ticketStatus: "Completed",
//                         description: "",
//                         internalRemark: "",
//                     },
//                     {
//                         tokenContract: "QF0407066",
//                         serviceTicketRefNo: "ME-230125-08",
//                         ticketDate: "2023-01-25",
//                         tokenBalanceFrom: "4",
//                         tokenConsumed: "2",
//                         tokenBalanceTo: "4",
//                         ticketStatus: "Cancelled",
//                         description: "",
//                         internalRemark: "",
//                     },
//                     {
//                         tokenContract: "QF0407066",
//                         serviceTicketRefNo: "ME-230127-05",
//                         ticketDate: "2023-01-27",
//                         tokenBalanceFrom: "6",
//                         tokenConsumed: "3",
//                         tokenBalanceTo: "3",
//                         ticketStatus: "Cancelled",
//                         description: "",
//                         internalRemark: "",
//                     },
//                     {
//                         tokenContract: "QF0407066",
//                         serviceTicketRefNo: "ME-230210-02",
//                         ticketDate: "2023-02-10",
//                         tokenBalanceFrom: "11",
//                         tokenConsumed: "4",
//                         tokenBalanceTo: "7",
//                         ticketStatus: "Pending",
//                         description: "",
//                         internalRemark: "",
//                     },
//                 ]
//             }
//         ]
//     }
// ];


// export const getMockCustomerData: CustomerData[] = [
//     // {
//     //     id:1,
//     //     bssId: "2201",
//     //     contacts:[
//     //         {
//     //             email:"alice@abccompanyltd.com.hk",
//     //             phone:"2222 3456",
//     //         }
//     //     ],

//     //     contractsCount: "1",
//     //     subscribedTokens:[
//     //         {
//     //             id:1,
//     //             tokenType:"modernPlusToken",
//     //         }
//     //     ],
//     //     link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/2201`
//     // },
//     // {
//     //     id:2,
//     //     bssId: "489",
//     //     contacts:[{
//     //         email:"ben@defcompanyltd.com.hk",
//     //         phone:"2222 9876",
//     //     }],
//     //     contractsCount: "3",
//     //     subscribedTokens:[
//     //         {id:2,
//     //         tokenType:"safetyPlusToken"
//     //         },
//     //         {
//     //             id:3,
//     //             tokenType:"MITToken"
//     //         }
//     //         ,
//     //     ],

//     //     link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489`
//     // },
//     // {
//     //     id:3,
//     //     bssId: "3501",
//     //     contacts:[
//     //         {
//     //             email:"ben@defcompanyltd.com.hk",
//     //             phone:"2222 9876",
//     //         }
//     //     ],
//     //     subscribedTokens:[],
//     //     contractsCount: "0",
//     //     link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/3501`
//     // },
// ]

// export const getMockContractSummary: ContractSummary = 
//     {
//         customerId: "489",
//         modernPlusService: "40",
//         safetiPlusService: "35",
//         MITService: "52",
//         superToken: "100",
//     }

// export const getMockContractData: ContractData[] = [
//     {
//         id:1,
//         type: "Modern+ Token",
//         customerId:"489",
//         contractId: "QO00293043",
//         status: "Expired",
//         period: "Yearly",
//         startDate: "2021-06-01",
//         expiryDate: "2022-05-31",
//         openBalance: 10,
//         latestBalance: 0,
//         internalRemark: "",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293043`,
//     },
//     {
//         id:2,
//         type: "Modern+ Token",
//         customerId:"489",
//         contractId: "QO00293043",
//         status: "Suspended",
//         period: "Yearly",
//         startDate: "2022-06-01",
//         expiryDate: "2023-05-31",
//         openBalance: 10,
//         latestBalance: 10,
//         internalRemark: "The status of payment from the user is pending, thus this token is temporarily suspended",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293043`,
//     },
//     {
//         id:3,
//         type: "Modern+ Token",
//         customerId:"489",
//         contractId: "QO00293043",
//         status: "Inactive",
//         period: "Yearly",
//         startDate: "2023-06-01",
//         expiryDate: "2024-05-31",
//         openBalance: 20,
//         latestBalance: 10,
//         internalRemark: "This open balance was updated on 2023-05-23 upon user's additional request.",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293043`,
//     },
//     {
//         id:4,
//         type: "Super Token",
//         customerId:"489",
//         contractId: "QO00293044",
//         status: "Expired",
//         period: "Monthly",
//         startDate: "2023-04-01",
//         expiryDate: "2023-04-30",
//         openBalance: 5,
//         latestBalance: 2,
//         internalRemark: "",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293044`,
//     },
//     {
//         id:5,
//         type: "Super Token",
//         customerId:"489",
//         contractId: "QO00293044",
//         status: "Active",
//         period: "Monthly",
//         startDate: "2023-05-01",
//         expiryDate: "2023-05-31",
//         openBalance: 5,
//         latestBalance: -4,
//         internalRemark: "The information is provided in [Microsoft 365 website](https://www.microsoft.com/en-us/microsoft-365/buy/compare-all-microsoft-365-products?market=hk)",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293044`,
//     },
//     {
//         id:6,
//         type: "Safeti+ Token",
//         customerId:"489",
//         contractId: "QO00293045",
//         status: "Active",
//         period: "Yearly",
//         startDate: "2022-04-01",
//         expiryDate: "2023-03-31",
//         openBalance: 10,
//         latestBalance: 2,
//         internalRemark: "",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293045`,
//     },
//     {
//         id:7,
//         type: "Super Token",
//         customerId:"489",
//         contractId: "QO00293046",
//         status: "Cancelled",
//         period: "Yearly",
//         startDate: "2023-04-01",
//         expiryDate: "2024-03-31",
//         openBalance: 5,
//         latestBalance: 5,
//         internalRemark: "This token was cancelled on 2023-04-25.",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293046`,
//     },
//     {
//         id:8,
//         type: "MIT Token",
//         customerId:"489",
//         contractId: "QO00293047",
//         status: "Active",
//         period: "Yearly",
//         startDate: "2024-01-01",
//         expiryDate: "2024-12-31",
//         openBalance: 60,
//         latestBalance: 28,
//         internalRemark: "",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047`,
//     },
//     {
//         id:9,
//         type: "MIT Token",
//         customerId:"489",
//         contractId: "QO00293048",
//         status: "Pending To Cancel",
//         period: "Yearly",
//         startDate: "2024-02-01",
//         expiryDate: "2025-01-31",
//         openBalance: 20,
//         latestBalance: 17,
//         internalRemark: "",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293048`,
//     }
// ]

// export const getMockTicketData: TicketData[] = [
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Pending",
//         ticketDate: "2023-04-29",
//         ticketNumber: "ME-230429-05",
//         consumed: "12",
//         description: "The superhub link is provided [here](http://www.superhub.com.hk)",
//         internalRemark: "<msg for admin>",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-230429-05`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Pending",
//         ticketDate: "2023-03-27",
//         ticketNumber: "ME-230327-02",
//         consumed: "9",
//         description: "Sharepoint link is provided [**here**](https://www.microsoft.com/zh-hk/microsoft-365/sharepoint/collaboration) for users.",
//         internalRemark: "once the Sharepoint link is updated, please update in description column",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-230327-02`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Cancelled",
//         ticketDate: "2023-03-27",
//         ticketNumber: "ME-230123-01",
//         consumed: "6",
//         description: "~~The sharepoint link is provided here~~",
//         internalRemark: "This ticket is cancelled due to ticket change. Another ticket will be built in the other contract of the same user.",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-230123-01`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Completed",
//         ticketDate: "2023-01-01",
//         ticketNumber: "ME-230101-01",
//         consumed: "5",
//         description: `- Our colleague scheduled an appointment at **5pm**.
// - Please contact our colleague at 2222 5432`,
//         internalRemark: `- This ticket was built during public holiday. 
// - This is an urgent ticket, need to be fixed during public holiday.`,
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-230101-01`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Cancelled",
//         ticketDate: "2022-12-31",
//         ticketNumber: "ME-221231-09",
//         consumed: "1",
//         description: "<text:PDF link to Doc Centre>",
//         internalRemark: `1. This ticket was built just before public holiday.  
// 2. VIP user, please note any possible follow-up action.`,
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221231-09`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Cancelled",
//         ticketDate: "2022-12-19",
//         ticketNumber: "ME-221210-03",
//         consumed: "1",
//         description: "<text:ME link>",
//         internalRemark: `- [ ] contact the user after holiday  
// - [X] prepared solutions for user to choose`,
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221210-03`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Pending",
//         ticketDate: "2022-12-04",
//         ticketNumber: "ME-221204-02",
//         consumed: "3",
//         description: "",
//         internalRemark: "User mentioned to provide 1 month for us to research on solutions",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221204-02`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Completed",
//         ticketDate: "2022-12-01",
//         ticketNumber: "ME-221201-01",
//         consumed: "4",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221201-01`,
//         description: "<text:PDF link to Doc Center>",
//         internalRemark: "",
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Cancelled",
//         ticketDate: "2022-11-31",
//         ticketNumber: "ME-221131-09",
//         consumed: "2",
//         description: "<text:PDF link to Doc Centre>",
//         internalRemark: "User cancelled this ticket because he wanted to use fewer tokens and changed to a request during normal workdays.",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221131-09`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Pending",
//         ticketDate: "2022-11-19",
//         ticketNumber: "ME-221119-03",
//         consumed: "3",
//         description: "<text:ME link>",
//         internalRemark: "User mentioned to provide 1-2 weeks for us to research on solutions",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221119-03`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Pending",
//         ticketDate: "2022-11-18",
//         ticketNumber: "ME-221118-07",
//         consumed: "5",
//         description: "",
//         internalRemark: "User mentioned that he/she could wait after public holiday",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221118-07`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "Completed",
//         ticketDate: "2022-11-03",
//         ticketNumber: "ME-221103-08",
//         consumed: "2",
//         description: "<text:PDF link to Doc Center>",
//         internalRemark: "",
//         link: `${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-221103-08`,
//     },
//     {
//         customerId: "489",
//         contractId: "QO00293047",
//         status: "In Progress",
//         ticketDate: "2023-01-17",
//         ticketNumber: "ME-230117-01",
//         consumed: "3",
//         description: "Customer reported a performance issue with the cloud system. Checked the system logs and found high CPU usage. Optimized the code to improve performance.",
//         internalRemark: "Weeks: 2, Engineers: 1, Cost: $5,500, PIC: Mike Fung",
//         link:`${process.env.REACT_APP_BASE_URL}/admin/token-center/customers/489/contracts/QO00293047/tickets/ME-230117-01`,
//     },
// ]

export const getMockLogData: LogData[] = [
    {
        logNumber: "ME-230429-05-001",
        ticketId: "ME-230429-05",
        resourceType: "Technician",
        consumed: "3",
        date: "2023-04-29",
        workProgress: "Inspected and analyzed the issues behind the technical accidents, then troubleshooted the cloud system as temporary solution. Confirmed with user that another appointment after public holiday would be arranged to provide long-term solution for fixation.",
        internalRemark: "Provided support outside office hour",
    },
    {
        logNumber: "ME-230429-05-002",
        ticketId: "ME-230429-05",
        resourceType: "Technician",
        consumed: "2",
        date: "2023-05-02",
        workProgress: "Repaired and resolved the connection issues between database and web application",
        internalRemark: "",
    },
    {
        logNumber: "ME-230429-05-003",
        ticketId: "ME-230429-05",
        resourceType: "Development",
        consumed: "2",
        date: "2023-05-03",
        workProgress: "Bug fixation has been completed",
        internalRemark: "",
    },
    {
        logNumber: "ME-230429-05-004",
        ticketId: "ME-230429-05",
        resourceType: "Customer Success",
        consumed: "2",
        date: "2023-05-04",
        workProgress: "Provided suggestions on choosing cloud infrastructure plans",
        internalRemark: "",
    },
    {
        logNumber: "ME-230429-05-005",
        ticketId: "ME-230429-05",
        resourceType: "Infrastructure Engine",
        consumed: "3",
        date: "2023-05-06",
        workProgress: "Run emergency maintenance tests; Installed, updated and optimized digital networks",
        internalRemark: "Provided support during weekend",
    },
]
export const getMockGeneralLog: GeneralLogContract[] = [

    {
        tokenContract: "QO0293045",
        groups: [
            {
                tokenContract: "QO0293045",
                serviceTicketRefNo: "ME-230123-01",
                ticketDate: new Date("2023-03-23"),
                tokenBalanceFrom: "10",
                tokenConsumed: "3",
                tokenBalanceTo: "7",
                ticketStatus: "Completed",
                description: "Customer reported an issue with login. Checked the system logs and found an error related to session timeout. Increased session timeout in the system to resolve the issue.",
            },
            {
                tokenContract: "QO0293045",
                serviceTicketRefNo: "ME-230225-03",
                ticketDate: new Date("2023-02-25"),
                tokenBalanceFrom: "7",
                tokenConsumed: "2",
                tokenBalanceTo: "7",
                ticketStatus: "Cancelled",
                description: "Customer reported a missing feature in the product. Confirmed that the feature is not available in the current version. Added the feature request to the product roadmap.",
            },
            {
                tokenContract: "QO0293045",
                serviceTicketRefNo: "ME-230327-02",
                ticketDate: new Date("2023-03-27"),
                tokenBalanceFrom: "7",
                tokenConsumed: "2",
                tokenBalanceTo: "5",
                ticketStatus: "Pending",
                description: "Customer reported a billing issue. Investigated and found that the issue was caused by a system error. Provided a refund and fixed the error.",
            },
            {
                tokenContract: "QO0293045",
                serviceTicketRefNo: "ME-230429-05",
                ticketDate: new Date("2023-04-29"),
                tokenBalanceFrom: "5",
                tokenConsumed: "3",
                tokenBalanceTo: "2",
                ticketStatus: "Completed",
                description: "Customer reported a performance issue with the product. Checked the system logs and found high CPU usage. Optimized the code to improve performance.",
            },
        ]
    },
    {
        tokenContract: "QS8495069",
        groups: [
            {
                tokenContract: "QS8495069",
                serviceTicketRefNo: "ME-230123-02",
                ticketDate: new Date("2023-01-03"),
                tokenBalanceFrom: "5",
                tokenConsumed: "1",
                tokenBalanceTo: "4",
                ticketStatus: "Completed",
                description: "Customer reported a security vulnerability in the product. Verified the vulnerability and patched the code to fix the issue.",
            },
            {
                tokenContract: "QS8495069",
                serviceTicketRefNo: "ME-230125-03",
                ticketDate: new Date("2023-01-25"),
                tokenBalanceFrom: "4",
                tokenConsumed: "2",
                tokenBalanceTo: "4",
                ticketStatus: "Cancelled",
                description: "Customer reported an issue with the product documentation. Reviewed the documentation and found errors. Updated the documentation and published the new version.",
            },
            {
                tokenContract: "QS8495069",
                serviceTicketRefNo: "ME-230127-04",
                ticketDate: new Date("2023-01-27"),
                tokenBalanceFrom: "4",
                tokenConsumed: "2",
                tokenBalanceTo: "2",
                ticketStatus: "Pending",
                description: "Customer reported a connectivity issue with the product. Checked the system logs and found a network error. Restarted the network service to resolve the issue.",

            },
            {
                tokenContract: "QS8495069",
                serviceTicketRefNo: "ME-230210-05",
                ticketDate: new Date("2023-02-23"),
                tokenBalanceFrom: "5",
                tokenConsumed: "3",
                tokenBalanceTo: "2",
                ticketStatus: "Pending",
                description: "Customer reported an issue with the product UI. Investigated and found a CSS conflict. Fixed the conflict and confirmed with the customer that the issue is resolved.",

            },
        ]
    }
];


httpApiMock.onGet("/getTokenConsumptionLog").reply((config) => {
    return [
        200,
        flattenGeneralData(getMockGeneralLog)
    ]
})


httpApiMock.onGet("/getAllCustomerData").reply(() => {
    return [
        200,
        // getMockCustomerData
    ]
})

httpApiMock.onGet("/getContractSummary").reply(() => {
    return [
        200,
        // getMockContractSummary
    ]
})

httpApiMock.onGet("/getAllContractData").reply(() => {
    return [
        200,
        //getMockContractData
    ]
})

// httpApiMock.onGet("/getAllTicketData").reply(() => {
//     return [
//         200,
//         getMockTicketData
//     ]
// })

// httpApiMock.onGet("/getAllLogData").reply(() => {
//     return [
//         200,
//         getMockLogData
//     ]
// })

// httpApiMock.onPost("/postTicket").reply(() => {
//     const newTicket = {
//         customerBSSId: "489",
//         contract: "QO00293047",
//         status: "Pending",
//         ticketDate: "2023-05-27",
//         ticketNumber: "ME-230527-06",
//         consumed: "3",
//         description: "User applied during weekend.",
//         internalRemark: "Pending for endorsement",
//     };

//     const data = [...getMockTicketData, newTicket]
//     return [
//         200,
//         data
//     ]
// })

// httpApiMock.onPut("/updateTicket").reply(() => {
//     const updateTicket = getMockTicketData;
//     updateTicket[0].status = "Completed";
//     updateTicket[0].internalRemark = "The status of this ticket has been updated as completed on 8/5/2023."

//     return [
//         200,
//         updateTicket
//     ]
// })

// httpApiMock.onPost("postLog").reply(() => {
//     const newLog = {
//             logNo: "ME-230429-05-006",
//             resourceType: "Security",
//             consumed: "3",
//             date: "2023-05-11",
//             workProgress: "Developed cloud security service upon users' requirement.",
//             internalRemark: "Expected that users will have further enquiry",
        
//     }
//     const data = [...getMockLogData, newLog]
//     return [
//         200,
//         data
//     ]
// })

// httpApiMock.onPut("/updateLog").reply(() => {
//     const updateLog = getMockLogData;
//     updateLog[1].internalRemark = "No follow-up action is needed"

//     return [
//         200,
//         updateLog
//     ]
// })

// httpApiMock.onPut("/updateContract").reply(() => {
//     const updateContract = getMockContractData;
//     updateContract[0].internalRemark = "No further change is required";
//     updateContract[0].openBalance = 25;
//     return [
//         200,
//         updateContract
//     ]
// })

// httpApiMock.onGet("/getAllTokenConsumptionLog").reply(() => {
//     return [
//         200,
//         flattenAdminLogData(getMockAdminLog)
//     ]
// })

// httpApiMock.onPost("/postTokenConsumptionLog").reply(() => {
//     //ME-240523-06
//     const postMockAdminLogInput = getMockAdminLog;
//     postMockAdminLogInput[0].contracts[0].groups.push(
//         {
//             tokenContract: "QO0293045",
//             serviceTicketRefNo: "ME-240523-06",
//             ticketDate: "2023-05-29", // may need to update
//             tokenBalanceFrom: "13",
//             tokenConsumed: "2",
//             tokenBalanceTo: "11",
//             ticketStatus: "Pending",
//             description: "Customer reported an issue with login. Checked the system logs and found an error related to session timeout. Increased session timeout in the system to resolve the issue.",
//             internalRemark: "Weeks: 3, Engineers: 2, Cost: $12,000, PIC: Jane Smith"
//         })

//     return [
//         200,
//         flattenAdminLogData(postMockAdminLogInput)
//     ]
// })



// httpApiMock.onPut("/updateTokenConsumptionLog").reply((config) => {
//     //ME-230123-01
//     const updateMockAdminLogInput = getMockAdminLog;
//     updateMockAdminLogInput[0].contracts[0].groups[0].tokenBalanceFrom = "20";
//     updateMockAdminLogInput[0].contracts[0].groups[0].tokenConsumed = "14";
//     updateMockAdminLogInput[0].contracts[0].groups[0].tokenBalanceTo = "6";

//     return [
//         200,
//         flattenAdminLogData(updateMockAdminLogInput)
//     ]
// })

// httpApiMock.onGet("/searchAllTokenConsumptionLog").reply((config) => {
//     let data: FlattenedAdminLogData[] = flattenAdminLogData(getMockAdminLog).filter((item) => item.customer == "ABC Company Ltd")
//     return [
//         200,
//         data
//     ]
// })

// httpApiMock.onGet("/searchAllCustomerData").reply((config) => {
//     let data: CustomerData[] = getMockCustomerData.filter((item) => item.bssId == "489")
//     return [
//         200,
//         data
//     ]
// })

// httpApiMock.onGet("/searchAllContractData").reply((config) => {
//     let data: ContractData[] = getMockContractData.filter((item) => item.contractId == "QO00293047")
//     return [
//         200,
//         data
//     ]
// })

// httpApiMock.onGet("/searchAllTicketData").reply((config) => {
//     let data: TicketData[] = getMockTicketData.filter((item) => item.status == "Pending")
//     return [
//         200,
//         data

//     ]
// })
