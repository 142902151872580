import { restApi } from '@app/api/rest.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
import { graphConfig } from '@app/store/slices/authSlice';

export interface AuthData {
  email: string;
  password: string;
}

export interface AccessTokenData {
  token: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface MarketplaceAccountPayload {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface SsoResponse {
  tokenType: string;
  accessToken: string;
  region: string;
  group: string;
  role: string;
  modules: string;
}

export interface MagicLinkRequest {
  email: string,
}

export interface SsoRequest {
  accessToken: string,
  isFromMagicLink: boolean
}

export const sendSsoRequest = (request: SsoRequest): Promise<SsoResponse> =>
  restApi.post<SsoResponse>('api/auth/generate/token', request).then(({ data }) => data);

export const sendRequestMagicLink = (request: MagicLinkRequest) => 
  restApi.post('api/auth/generate/magiclink', request).then(({ data }) => data);

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  restApi.post<LoginResponse>('login', { ...loginPayload }).then(({ data }) => data);

export const me = (accessToken: string): Promise<any> => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(graphConfig.graphMeEndpoint, options)
          .then(response => response.json())
          .catch(error => console.log(error));
}

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  restApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  restApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifyMarketplaceAccount = (payload: MarketplaceAccountPayload): Promise<undefined> =>
  restApi.post<undefined>('api/auth/marketplace/verifyuser', null, { params: { ...payload }}).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  restApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  restApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);
