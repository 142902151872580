import { AdminSubMenuContent, UserSubMenuContent } from "@app/components/router/Menu";
import * as S from "../../../nft-dashboard/common/CommonComponentStyle.styles";
import { SiderItem } from "./SiderItem";
import { readClaim } from '@app/services/localStorage.service';
import { useCan } from "@app/components/common/CustomHook/useCan";
import { InfoIcon } from "@app/components/common/icons/InfoIcon";
import { useEffect, useState } from "react";
import { CustomizedModal } from "@app/components/nft-dashboard/admin/RenewalCenter/CustomizedModal";
import { IconButton } from "@mui/material"
import { useTranslation } from "react-i18next";
import FAQs from "@app/assets/markdowns/FAQs.md";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";


export const Sider = ({ menuItems, setSubMenu, setToggled }: any) => {
  const claim = readClaim();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [FAQText, setFAQText] = useState('')

  const fetchDataSyncInfoAPI = () => {
    fetch(FAQs)
      .then(res => res.text())
      .then((text) => {
        setFAQText(text)
      })
      .catch((err) => { console.log('cannot fetch faq from markdown', err) });
  }

  useEffect(() => {
    if(location.pathname.includes('/admin/')){
      fetchDataSyncInfoAPI();
    }
  }, [])

  const getProfileCenter = (targetPath:string) => {
    const profileCenter = useCan({
      canAccess: claim && claim.modules.includes("profile"),
      Component: <SiderItem isCurrentLocation={location.pathname.includes(targetPath)} title1="Profile" title2="Center" 
                    onClick={() => { setSubMenu(menuItems["profile"]); setToggled(true); }} />
    });

    return profileCenter;
  }

  const getTokenCenter = (targetPath:string) => {
    const tokenCenter = useCan({
      canAccess: claim && claim.modules.includes("token"),
      Component: <SiderItem isCurrentLocation={location.pathname.includes(targetPath)} title1="Token" title2="Center" 
                    onClick={() => { setSubMenu(menuItems["token"]); setToggled(true); }} />
    });

    return tokenCenter;
  }

  const getRenewalCenter = (targetPath:string, isAdmin:boolean) => {
    const hasRenewalModule = claim && claim.modules.includes("renewal");
    const hasRenewalMenuAccess = isAdmin ? Object.keys(menuItems).includes(`renewal_${claim?.role}`) : true;
    const renewalCenter = useCan({
      canAccess: hasRenewalModule && hasRenewalMenuAccess,
      Component: <SiderItem isCurrentLocation={location.pathname.includes(targetPath)} title1="Renewal" title2="Center" 
                    onClick={() => 
                      { 
                        if (isAdmin) setSubMenu(menuItems[`renewal_${claim!.role}`]);
                        else setSubMenu(menuItems["renewal"]);
                        setToggled(true);
                      }} />
    });

    return renewalCenter
  }

  const getBillingCenter = (targetPath:string) => {
    const billingCenter = useCan({
      canAccess: claim && claim.modules.includes("billing"),
      Component: <SiderItem isCurrentLocation={location.pathname.includes(targetPath)} title1="Billing" title2="Center" 
                    onClick={() => { setSubMenu(menuItems["billing"]); setToggled(true); }} />
    });

    return billingCenter;
  }

  const getSupportCenter = (targetPath:string) => {
    const component = useCan({
      canAccess: true,
      Component: <SiderItem isCurrentLocation={location.pathname.includes(targetPath)} title1="Support" title2="Center" 
                    onClick={() => { window.open("https://support.superhub.com.hk/", '_blank')?.focus() }} />
    });

    return component;
  }

  const AdminMenu = useCan({
    canAccess: menuItems == AdminSubMenuContent,
    Component:
      <>
        <div style={{overflowY:'clip', height:'100vh'}}>
          {getProfileCenter('/admin/profile-center')}
          {getTokenCenter('/admin/token-center')}
          {getRenewalCenter('/admin/renewal-center', true)}
        </div>
        <div style={{ position: 'absolute', bottom: '5px', left: '15px' }}>
          <IconButton onClick={() => setShowModal(true)}>
            <InfoIcon />
          </IconButton>
          <CustomizedModal
            showModal={showModal}
            setShowModal={setShowModal}
            width={60}
            title={"FAQ Corner"}
            props={<ReactMarkdown children={FAQText} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} linkTarget="_blank" />}
          />
        </div>
      </>

  })

  const UserMenu = useCan({
    canAccess: menuItems == UserSubMenuContent,
    Component:
      <div>
        {getProfileCenter('/app/profile-center')}
        {getRenewalCenter('/app/renewal-center', false)}
        {getBillingCenter('/app/billing-center')}
        {getSupportCenter('/app/support-center')}
      </div>
  })

  return (
    <S.Sider width={"75px"}>
      <div>
        {AdminMenu}
        {UserMenu}
      </div>
    </S.Sider>
  )
}