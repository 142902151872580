import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  me,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  verifyMarketplaceAccount,
  MarketplaceAccountPayload,
  NewPasswordData,
  setNewPassword,
  sendSsoRequest,
  SsoRequest,
} from '@app/api/auth.api';
import { setServiceUser, setUser } from '@app/store/slices/userSlice';
import { setClaim } from '@app/store/slices/claimSlice';
import { deleteToken, deleteUser, deleteClaim, persistToken, readToken, deleteServiceUser, clearLocalStorage, readClaim, deleteRedirectPath, readRedirectPath } from '@app/services/localStorage.service';
import { LogLevel, Configuration } from "@azure/msal-browser";
import { getValueFromToken } from '@app/utils/jwtutils';
import { getRenewalUserHomepageByRole } from '@app/components/nft-dashboard/admin/TokenCenter/Customers/ConversionHelper';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doMagicLink = createAsyncThunk('auth/doMagicLink', async (accessToken: string, { dispatch }) => {
  // Prepare request
  let request:SsoRequest = {
    accessToken: accessToken,
    isFromMagicLink: true
  }

  return doSso(request, dispatch);
});

export const doMsol = createAsyncThunk('auth/doMsol', async (accessToken: string, { dispatch }) => {
  // Prepare request
  let request:SsoRequest =  {
    accessToken: accessToken,
    isFromMagicLink: false
  }

  return doSso(request, dispatch);
});

const doSso = (request:SsoRequest, dispatch:any) => {
  // Send sso request to backend api
  const result = sendSsoRequest(request).then((res) => {
    dispatch(setClaim({ 
      region: res.region, 
      group: res.group, 
      role: res.role, 
      modules: res.modules.split(','), 
      email: getValueFromToken(res.accessToken, "unique_name"),
      expiration: getValueFromToken(res.accessToken, "exp"), }));
    persistToken(res.accessToken);
    return res;
  })
  .catch((err) => {
    return null;
  });
  return result;
}

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    dispatch(setUser(res.user));
    persistToken(res.token);

    return res.token;
  }),
);

export const doCallMsGraph = createAsyncThunk('auth/doCallMsGraph', async (accessToken: string) =>
  me(accessToken).then((res) => {
    // console.log('MS Graph Me: ', res);
    return res;
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifyMarketplaceAccount = createAsyncThunk(
  'auth/doVerifyMarketplaceAccount',
  async (marketplaceAccountPayload: MarketplaceAccountPayload) => verifyMarketplaceAccount(marketplaceAccountPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  deleteClaim();
  deleteRedirectPath();
  dispatch(setUser(null));
  dispatch(setClaim(null));
  clearLocalStorage();
});

export const msalConfig : Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? "",
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: "/",
  },
  cache: {
    cacheLocation: 'sessionStorage',
    temporaryCacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const loginRequest = {
  scopes: ["User.Read"]
};

export const loginOptionsRequest= {
  scopes: ["User.Read", "mail.send"]
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doMsol.fulfilled, (state, action) => {
      state.token = action.payload?.accessToken || "";
    });
    builder.addCase(doMagicLink.fulfilled, (state, action) => {
      state.token = action.payload?.accessToken || "";
    });
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export const getClaimForRedirect = (navigate: any, setLoading: any) => {
  const claim = readClaim();
  if (claim?.group === "customer") {
    let path = getCustomerRedirect(claim?.modules);
    navigate(path);
    setServiceUser("valid")
  } else if (claim?.group === "admin") {
    navigate(getRenewalUserHomepageByRole(claim?.role));
    setServiceUser("valid")
  } else {
    setServiceUser("invalid")
  }
  setLoading(false);
}

export const getCustomerRedirect = (modules:string[]):string => {
    // Set default path for customer
    let defaultRedirectPath = modules.includes("profile") ? '/app/profile-center' : '/app/renewal-center';

    // Get initial path stored on session for customer
    let redirectPath = readRedirectPath();

    // Get final path to be redirect for customer
    return redirectPath == null || redirectPath == "" ? defaultRedirectPath : redirectPath;
}

export default authSlice.reducer;
