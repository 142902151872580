import { httpApi } from '@app/api/http.api';
import './mocks/tokenCenter.api.mock';
import axios from 'axios';
import { restApi } from './rest.api';

export interface TokenTrackerItem {
    tokenContract: string;
    contractNo: string;
    contractPeriod: string;
    balanceCycle: string;
    expiryDate: string;
    openBalance: number | string;
    latestBalance: number | string;
    latestBalanceExpiryDate: string;
    tokenRecords: string;
};

export interface UpdateTokenTrackerItem {
    id: number | string
    tokenContract: string;
    contractNo: string;
    contractPeriod: string;
    balanceCycle: string;
    expiryDate: string;
    openBalance: number | string;
    latestBalance: number | string;
    latestBalanceExpiryDate: string;
    tokenRecords: string;
}

export interface FlattenedAdminLogData {
    id: number | string;
    customer: string;
    tokenContract: string;
    ticketNumber: string;
    ticketDate: string;
    tokenBalanceFrom: string;
    tokenConsumed: string;
    tokenBalanceTo: string;
    ticketStatus: string;
    description: string;
    internalRemark: string;
}

export interface FlattenedGeneralLogData {
    id?: number | string;
    tokenContract: string;
    serviceTicketRefNo: string;
    ticketDate: string;
    tokenBalanceFrom: string;
    tokenConsumed: string;
    tokenBalanceTo: string;
    ticketStatus: string;
    description: string;
}

export const initialTokenTrackerItems: TokenTrackerItem[] = [{
    tokenContract: "---",
    contractNo: "---",
    contractPeriod: "---",
    balanceCycle: "---",
    expiryDate: "---",
    openBalance: "---",
    latestBalance: "---",
    latestBalanceExpiryDate: "---",
    tokenRecords: "---",
}];

export const initTokenTrackerFormFields: TokenTrackerItem = {
    tokenContract: 'Please input Token Contract',
    contractNo: 'Please input Contract No',
    contractPeriod: 'Please input Contract Period',
    balanceCycle: 'Please input Balance Cycle',
    expiryDate: 'Please input Expiry Date',
    openBalance: 0,
    latestBalance: 0,
    latestBalanceExpiryDate: 'Please input Latest Balance Expiry Date',
    tokenRecords: 'Please input Token Records',
};

export const initLogFormFields: FlattenedAdminLogData =
{
    id: "Please input Id",
    customer: "Please input Organization",
    tokenContract: 'Please input Token Contract',
    ticketNumber: 'Please input Service Ticket Ref No',
    ticketDate: 'Please input Ticket Date',
    tokenBalanceFrom: 'Please input Token Balance From',
    tokenConsumed: 'Please input Token Consumed',
    tokenBalanceTo: 'Please input Token Balance To',
    ticketStatus: 'Please input Ticket Status',
    description: 'Please input Description',
    internalRemark: 'Please input Internal Remark',
}

export const initTicketFields: TicketData =
{
    customerId: "Please input Id",
    customer: {
        bssId: "Please input Id",
    },
    status: 'Pending',
    ticketDate: 'Please input Ticket Date',
    ticketNumber: '',
    totalConsumedToken: 'Please input Consumed Token',
    description: 'Please input Description',
    internalRemark: 'Please input Internal Remark',
    logGroup:[]
}

export const initAdminLog: AdminLog[] = [
    {
        customer: "---",
        contracts: [
            {
                tokenContract: '---',
                groups: [{
                    tokenContract: '---',
                    serviceTicketRefNo: '---',
                    ticketDate: '---',
                    tokenBalanceFrom: '---',
                    tokenConsumed: '---',
                    tokenBalanceTo: '---',
                    ticketStatus: '---',
                    description: '---',
                    internalRemark: '---',
                }]
            }
        ]
    }
]

export const initGeneralTog: GeneralLogContract[] = [
    {
        tokenContract: "---",
        groups: [
            {
                tokenContract: "---",
                serviceTicketRefNo: "---",
                ticketDate: "---",
                tokenBalanceFrom: "---",
                tokenConsumed: "---",
                tokenBalanceTo: "---",
                ticketStatus: "---",
                description: "---",
            }
        ]
    }
]

export const initAdminTableLog: FlattenedAdminLogData[] = [
    {
        id: "---",
        customer: "---",
        tokenContract: '---',
        ticketNumber: '---',
        ticketDate: '---',
        tokenBalanceFrom: '---',
        tokenConsumed: '---',
        tokenBalanceTo: '---',
        ticketStatus: '---',
        description: '---',
        internalRemark: '---',
    }
]

export const initGeneralTableLog: FlattenedGeneralLogData[] = [
    {
        id: "---",
        tokenContract: '---',
        serviceTicketRefNo: '---',
        ticketDate: '---',
        tokenBalanceFrom: '---',
        tokenConsumed: '---',
        tokenBalanceTo: '---',
        ticketStatus: '---',
        description: '---',
    }
]

export interface AdminLog {
    customer: string,
    contracts: AdminLogContract[]
}

export interface GeneralLog {
    customer: string,
    contracts: GeneralLogContract[]
}

export interface AdminLogContract {
    tokenContract: string;
    groups: AdminLogGroupItem[];
};

export interface GeneralLogContract {
    tokenContract: string;
    groups: GeneralLogGroupItem[];
};

export interface GeneralLogGroupItem {
    tokenContract: string;
    serviceTicketRefNo: string;
    ticketDate: string | Date;
    tokenBalanceFrom: string;
    tokenConsumed: string;
    tokenBalanceTo: string;
    ticketStatus: string;
    description: string;
}

export interface AdminLogGroupItem {
    customer?: string;
    tokenContract: string;
    serviceTicketRefNo: string;
    ticketDate: string;
    tokenBalanceFrom: string;
    tokenConsumed: string;
    tokenBalanceTo: string;
    ticketStatus: string;
    description: string;
    internalRemark: string;
}

export interface UpdateAdminLog {
    id: string | number;
    tokenContract: string;
    group: AdminLog[];
};

export interface SearchAdminLogForm {
    customer?: string;
    tokenContract?: string;
    serviceTicketRefNo?: string;
    ticketDate?: string;
    ticketStatus?: string;
}


export const flattenAdminLogData = (logData: AdminLog[]) => {
    let flattenedData: any[] = [];
    logData.forEach(x => {
        x.contracts.forEach(c => {
            c.groups.forEach(g => {
                g.customer = x.customer;
                flattenedData.push(g);
            });
        });
    });
    return flattenedData;
}

export const flattenGeneralData = (logData: GeneralLogContract[]) => {
    let flattenedData: any[] = [];
    logData.forEach(c => {
        c.groups.forEach(g => {
            flattenedData.push(g);
        }
        );
    });
    return flattenedData;
}

export interface CustomerData {
    id: string | number;
    status: string;
    bssId: string;
    contacts: CustomerAbstract[];
    contractsCount: string;
    subscribedTokens: number[];
}

export interface PagedCustomerData{
    meta:{
        pageNumber:number;
        pageSize:number;
        totalRowCount:number;
    },
    data:CustomerData[];
}

export interface TokenTypeObject {
    id: number;
    tokenType: string;
    tokenFeature:{
        id:string|number;
        source:string|number;
        isUnlimitedTokens: boolean;
        shouldPaybackOverdraft:boolean;
        isAllowedOverdraft: boolean;
        isAutoWaivedConsumption: boolean;
        isAutoWaivedOverdraft: boolean;
    }
}
export interface CustomerAbstract {
    email: string;
    phone: string;
}
export const initCustomerData: CustomerData[] = [
    {
        id: "---",
        status: "---",
        bssId: "---",
        contacts: [
            {
                email: '---',
                phone: '---'
            }
        ],
        contractsCount: "---",
        subscribedTokens: [],
    }
]
export interface ContractData {
    id: number | string;
    type: number |string;
    sourceName: string;
    customerId: string;
    contractId: string;
    contractPhase: number | string;
    status: string;
    period: string;
    startDate: string;
    expiryDate: string;
    openBalance: string | number;
    latestBalance: string | number;
    internalRemark: string;
    link?: string;
}

export const initContractData: ContractData[] = [
    {
        id: "---",
        type: "---",
        sourceName: "---",
        customerId: "---",
        contractId: "---",
        contractPhase: "---",
        status: "---",
        period: "---",
        startDate: "---",
        expiryDate: "---",
        openBalance: "---",
        latestBalance: "---",
        internalRemark: "---",
        link: "---",
    }
]

export const initContractFields: ContractData = {
    id: "---",
    type: "---",
    sourceName: "---",
    customerId: "---",
    contractId: "---",
    contractPhase: "---",
    status: "---",
    period: "---",
    startDate: "---",
    expiryDate: "---",
    openBalance: "---",
    latestBalance: "---",
    internalRemark: "---",
    link: "---",
}

export interface TicketData {
    customerId: string | number;
    customer: {
        bssId: string;
    }
    status: string;
    ticketDate: string;
    ticketNumber: string;
    totalConsumedToken: string | number;
    description: string;
    internalRemark: string;
    logGroup:PostLogPayload[]
}

export enum StatusType {
    Pending, Cancelled, Completed
}

export const initTicketData: TicketData[] = [
    {
        customerId: "---",
        customer: {
            bssId: "---",
        },
        status: "---",
        ticketDate: "---",
        ticketNumber: "---",
        totalConsumedToken: "---",
        description: "---",
        internalRemark: "---",
        logGroup:[]
    }
]

export interface LogData {
    logNumber: string;
    ticketId: string;
    resourceType: string;
    resourceTypeName: string;
    consumed: string;
    date: string;
    workProgress: string;
    internalRemark: string;
}

export const initLogData: LogData[] = [
    {
        logNumber: "---",
        ticketId: "---",
        resourceType: "---",
        resourceTypeName: "---",
        consumed: "---",
        date: "---",
        workProgress: "---",
        internalRemark: "---",
    }
]

export const initLogFields: LogData = {
    logNumber: "---",
    ticketId: "---",
    resourceType: "---",
    resourceTypeName: "---",
    consumed: "0",
    date: "---",
    workProgress: "---",
    internalRemark: "---",
}

export enum Source {
    ModernPlusService = "ModernPlusService",
    SafetiPlusService = "SafetiPlusService",
    MITService = "MITService",
    MITBufferService = "MITBufferService",
    MITUnlimited = "MITUnlimited",
    SuperToken = "SuperToken",
    ModernPlusServiceBundleM365BusinessBasic = "ModernPlusServiceBundleM365BusinessBasic",
    ModernPlusServiceBundleM365BusinessStandard = "ModernPlusServiceBundleM365BusinessStandard"
}

export interface TokenType {
    id:string|number;
    tokenType:Source;
}

export interface ContractSummary {
    customerId: string,
    modernPlusService: number | string;
    safetiPlusService: number | string;
    MITService: number | string;
    noOfSubscribedSuperToken: number | string;
    superToken: number | string;
    waivedToken: number | string;
    isUnlimited: boolean | string;
    isAutoWaiveConsumption: boolean | string;
    isAutoWaiveOverdraft: boolean | string;
}

export const initContractSummary: ContractSummary = {
    customerId: "--",
    modernPlusService: "--",
    safetiPlusService: "--",
    MITService: "--",
    noOfSubscribedSuperToken: "--",
    superToken: "--",
    waivedToken: "--",
    isUnlimited: "--",
    isAutoWaiveConsumption: "--",
    isAutoWaiveOverdraft: "--",
}

export enum ContractStatus {
    Active = "Active",
    Inactive = "Inactive",
    Suspended = "Suspended",
    Expired = "Expired",
    PendingForCancel = "PendingForCancel",
    Cancelled = "Canceled"
}

export enum TicketStatus {
    Pending = "Pending",
    InProgress = "InProgress",
    Cancelled = "Cancelled",
    Completed = "Completed",
}

export interface filterCustomerPayload {
    "bssId": string,
    "subscribedTokens"?: TokenTypeObject[]
}

export interface filterContractPayload {
    "type"?: string,
    "contracts"?: string[],
    "status"?: ContractStatus[],
    "contractPeriod"?: string,
    "contractStartDate"?: Date,
    "contractExpiryDate"?: Date,
}

export interface filterTicketPayload {
    "status"?: TicketStatus[],
    "ticketStartDate"?: Date,
    "ticketEndDate"?: Date,
    "ticketNumbers"?: string[]
}

export interface SearchLogData {
    customerId: string,
    bssId: string,
    status: string,
    ticketDate: string,
    ticketNumber: string,
    totalConsumedToken: string | number,
    description: string,
    internalRemark: string,
    logGroup: LogData[],
}

export const initSearchLogData: SearchLogData = {
    customerId: "---",
    bssId: "Please input Id",
    status: "",
    ticketDate: "---",
    ticketNumber: "---",
    totalConsumedToken: "",
    description: "",
    internalRemark: "",
    logGroup: [],
}

export interface SuggestedConsumedRecord {
    isAutoWaivedConsumption:boolean;
    isAutoWaivedOverdraft: boolean;
    consumptionList: SuggestedConsumptionFormModel[];
    overdraft: SuggestedOverdraft;
}

export interface ExistingConsumedRecord {
    noOfTotalConsumption: number | string;
    consumptionList: ExistingConsumptionFormModel [];
    overdraftList: ExistingOverdraft[];
}

export interface SuggestedOverdraft {
    id?:number|string;
    consumedToken:number|string;
    isWaived: string | boolean;
    WaivedBy?: string;
}

export const initSuggestedOverdraftList: PostOverdraftDTO[]=[{
    id:"---",
    consumedToken:"---",
    isWaived: "---",
    waivedBy: "---",
}]

export interface ExistingOverdraft {
    id:number|string;
    logId:number|string;
    customer:{
        bssId:string;
    };
    log:{
        logNumber: string;
        ticketId: string;
        resourceType: string;
        consumed: string;
        date: string;
        workProgress: string;
        internalRemark: string;
    },
    consumedToken:number|string;
    isWaived: string | boolean;
    WaivedBy?: string;
}

export const initExistingOverdraftList: ExistingOverdraft[]=[{
    id:"---",
    logId: "---" ,
    customer:{
        bssId:"---" ,
    },
    log:{
        logNumber: "---" ,
        ticketId:"---" ,
        resourceType: "---" ,
        consumed:"---" ,
        date: "---" ,
        workProgress:"---" ,
        internalRemark: "---" ,
    },
    consumedToken:"---" ,
    isWaived: "---",
    WaivedBy: "---",
}]

export interface SuggestedConsumptionFormModel {
    id: string | number;
    contractNo: string;
    phase:string;
    expiryDate: string;
    status: string;
    consumed: string | number;
    isWaived: string | boolean;
    WaivedBy?: string;
}

export const initSuggestedConsumptionList: SuggestedConsumptionFormModel[]=[{
    id: "---",
    contractNo:  "---",
    phase: "---",
    expiryDate:  "---",
    status:  "---",
    consumed: "---",
    isWaived:"---",
    WaivedBy: "---",
}]

export interface ExistingConsumptionFormModel {
    id?: string;
    contractNo: string;
    phase:string;
    expiryDate: string;
    status: string;
    consumed: string | number;
    isWaived: string | boolean;
    WaivedBy?: string;
}

export const initExistingConsumptionList: ExistingConsumptionFormModel[] = [{
    id: "---",    
    contractNo: "---", 
    phase:"---", 
    expiryDate: "---", 
    status: "---", 
    consumed: "---", 
    isWaived:"---",
    WaivedBy: "---",

}]

export interface getSuggestedConsumptionPayload {
    "consumedTokens": number;
}

export interface updateContractPayload {
    "contractPhase": number,
    "openBalance"?: number,
    "internalRemark"?: string;
}
export interface updateTicketPayload {
    "ticketStatus": TicketStatus;
    "ticketDate": Date;
    "ticketNumber": string;
    "description"?: string;
    "internalRemark"?: string;
}

export interface PostTicketPayload {
    "bssId": string;
    "status": string;
    "ticketDate": Date;
    "ticketNumber": string;
    "description": string;
    "internalRemark": string;
}

export interface UpdateLogPayload {
    "bssId":string;
    "logNumber": string;
    "ticketNumber": string;
    "resourceType": ResourceType;
    "consumed": string | number;
    "date": Date;
    "workProgress"?: string;
    "internalRemark"?: string;
    "consumptionList"?: ExistingConsumptionFormModel[];
    "overdraft"?:PostOverdraftDTO
}

export interface PostLogPayload {
    "bssId":string;
    "logNumber": string;
    "ticketNumber": string;
    "resourceType": ResourceType;
    "consumed": string | number;
    "date": Date;
    "workProgress"?: string;
    "internalRemark"?: string;
    "consumptionList"?: ExistingConsumptionFormModel[];
    "overdraft"?:PostOverdraftDTO;
}

export interface PostOverdraftDTO{
    "id"?:string | number;
    "consumedToken":string | number;
    "isWaived"?: boolean |string;
    "waivedBy"?: string;
}

export interface ConsumptionRecord {
    // contractNumber:string;
    // consumed:number;
    id: number | string;
    contractNo: string;
    status: string;
    expiryDate: string;
    originalTokens: string | number;
    consumed: string | number;
    remainingTokens: string | number;
}

export enum ResourceType {
    Technician = "Technician",
    InfrastructureEngineer = "InfrastructureEngineer",
    SecurityEngineer = "SecurityEngineer",
    VoiceEngineer = "VoiceEngineer",
    AppDeveloper = "AppDeveloper",
    SolutionConsultant = "SolutionConsultant",
    CustomerSuccess = "CustomerSuccess",
}

// ME APIs
// GET API

// Admin APIs
// GET API
export const getCustomerData = (pageIndex:number, pageSize:number) =>
    restApi.get<PagedCustomerData>(`api/tokens/customers?PageNumber=${pageIndex}&PageSize=${pageSize}`).then(({ data }) => data);

export const getContractSummary = (customerId: string) =>
    restApi.get<ContractSummary>(`api/tokens/customers/${customerId}/contractsummary`).then(({ data }) => data);

export const getContractDataByContractId = (customerId: string, contractId: string) =>
    restApi.get<ContractData[]>(`api/tokens/customers/${customerId}/contracts/${contractId}/contract-detail`).then(({ data }) => data);

export const getContractDataByCustomerId = (customerId: string) =>
    restApi.get<ContractData[]>(`api/tokens/customers/${customerId}/contracts`).then(({ data }) => data);

export const getTicketData = (customerId: string) =>
    restApi.get<TicketData[]>(`api/tokens/customers/${customerId}/tickets`).then(({ data }) => data);

export const getLogData = (customerId: string, contractId: string, ticketId: string) =>
    restApi.get<LogData[]>(`api/tokens/customers/${customerId}/contracts/${contractId}/tickets/${ticketId}/logs`).then(({ data }) => data);

export const getSearchLogDataByTicketId = (ticketId: string) =>
    restApi.get<SearchLogData>(`api/tokens/tickets/${ticketId}/logs`).then(({ data }) => data);

// POST API
export const postTicket = (customerId: string, postTicketPayload: PostTicketPayload): Promise<TicketData[]> =>
    restApi.post<TicketData[]>(`api/tokens/customers/${customerId}/tickets`, { ...postTicketPayload }).then(({ data }) => data);

export const postLog = (ticketId: string, postLogPayload: PostLogPayload): Promise<LogData[]> =>
    restApi.post<LogData[]>(`api/tokens/tickets/${ticketId}/logs`, { ...postLogPayload }).then(({ data }) => data);

// PUT API
export const updateContract = (customerId: string, contractId: string, updateContractPayload: updateContractPayload): Promise<ContractData[]> =>
    restApi.put<ContractData[]>(`api/tokens/customers/${customerId}/contracts/${contractId}`, { ...updateContractPayload }).then(({ data }) => data);

export const updateTicket = (ticketId: string, updateTicketPayload: updateTicketPayload): Promise<TicketData[]> =>
    restApi.put<TicketData[]>(`api/tokens/tickets/${ticketId}`, { ...updateTicketPayload }).then(({ data }) => data);

export const updateLog = (ticketId: string, logId: number, updateLogPayload: UpdateLogPayload): Promise<LogData> =>
    restApi.put<LogData>(`api/tokens/tickets/${ticketId}/log/${logId}`, { ...updateLogPayload }).then(({ data }) => data);

// GET API: get filter data
export const getFilterCustomerData = (pageIndex:number, pageSize:number, filterPayload: filterCustomerPayload): Promise<PagedCustomerData> =>
    restApi.post<PagedCustomerData>(`api/tokens/customers/filter?PageNumber=${pageIndex}&PageSize=${pageSize}`, { ...filterPayload }).then(({ data }) => data);

export const getFilterTicketData = (customerId: string, filterPayload: filterTicketPayload): Promise<TicketData[]> =>
    restApi.post<TicketData[]>(`api/tokens/customers/${customerId}/tickets/filter`, { ...filterPayload }).then(({ data }) => data);

export const getFilterContractData = (customerId: string, filterPayload: filterContractPayload): Promise<ContractData[]> =>
    restApi.post<ContractData[]>(`api/tokens/customers/${customerId}/contracts/filter`, { ...filterPayload }).then(({ data }) => data);

// POST API: Add/edit Log Form: get suggested consumption list

export const getSuggestedConsumptionList = (customerId: string, payload: getSuggestedConsumptionPayload): Promise<SuggestedConsumedRecord> =>
    restApi.post<SuggestedConsumedRecord>(`api/tokens/customers/${customerId}/add-log-form/consumption-list`, { ...payload }).then(({ data }) => data);

// POST API: View Log Form: get existing consumption list
export const getExistingConsumedRecord = (logId: number): Promise<ExistingConsumedRecord> =>
    restApi.get<ExistingConsumedRecord>(`/api/tokens/consumptions/${logId}`).then(({ data }) => data);

// GET API: check if can edit log
export const checkCanEditLog = (logId:number):Promise<boolean>=>
    restApi.get<boolean>(`/api/tokens/log/${logId}/can-edit`).then(({ data }) => data);

// old apis

export const getTokenTracker = () =>
    httpApi.get<TokenTrackerItem[]>('getTokenTracker').then(({ data }) => data);

export const getAdminLogs = () =>
    httpApi.get<FlattenedAdminLogData[]>('getAllTokenConsumptionLog').then(({ data }) => data);

export const getGeneralLogs = () =>
    httpApi.get<FlattenedGeneralLogData[]>('getTokenConsumptionLog').then(({ data }) => data);

export const postTokenConsumptionLog = (postTokenTrackerPayload: AdminLog): Promise<FlattenedAdminLogData[]> =>
    httpApi.post<FlattenedAdminLogData[]>('postTokenConsumptionLog', { ...postTokenTrackerPayload }).then(({ data }) => data);

export const updateTokenConsumptionLog = (uploadTokenTrackerPayload: UpdateAdminLog): Promise<FlattenedAdminLogData[]> =>
    httpApi.put<FlattenedAdminLogData[]>('updateTokenConsumptionLog', { ...uploadTokenTrackerPayload }).then(({ data }) => data);

export const getSearchAdminLogs = (): Promise<FlattenedAdminLogData[]> =>
    httpApi.get<FlattenedAdminLogData[]>('searchAllTokenConsumptionLog', { params: { customer: "ABC Company Ltd" } }).then(({ data }) => data);
