import { doMsol, getClaimForRedirect, loginOptionsRequest } from "@app/store/slices/authSlice";
import { FONT_SIZE } from "@app/styles/themes/constants";
import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setUser } from "@app/store/slices/userSlice";

export const SwitchAccountButton = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
  
    const switchAccountText = t("login.switchAccount");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
  
    const handleSwitch = async () => {
      const myAccounts: AccountInfo[] = instance.getAllAccounts();
      const msalSwitchedAccount = await instance.loginPopup(loginOptionsRequest);
  
      //console.log('msalSwitchedAccount:', msalSwitchedAccount)
      //console.log('myAccounts:', myAccounts);
      setUser(msalSwitchedAccount.account?.name);
  
      dispatch(doMsol(msalSwitchedAccount.accessToken))
        .unwrap()
        .then((res) => {
          getClaimForRedirect(navigate, setLoading);
        })
    }
  
    return (
      <div style={{display:'flex'}}>
        <a
          style={{ fontSize: FONT_SIZE.xs, textDecoration: 'underline' }}
          onClick={(event) => handleSwitch()}
          color="inherit"
        >
          {switchAccountText}
        </a>
      </div>
    )
  };