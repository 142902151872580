import React from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '@app/store/slices/authSlice';
import 'typeface-montserrat';
import 'typeface-lato';
import { ClarityProvider } from 'use-clarity';

const App: React.FC = () => {

  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  const msalInstance = new PublicClientApplication(msalConfig);
  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  return (
    <>
      <ClarityProvider clarityId={process.env.REACT_APP_CLARITY_PROJECT_ID!}>
        <MsalProvider instance={msalInstance}>
          <meta name="theme-color" content={themeObject[theme].primary} />
          <GlobalStyle />
          <HelmetProvider>
            <ConfigProvider locale={language === 'en' ? enUS : deDe}>
              <AppRouter />
            </ConfigProvider>
          </HelmetProvider>
        </MsalProvider>
      </ClarityProvider>
    </>
  );
};

export default App;
