import { Button, Result, Image } from 'antd'
import { useNavigate } from 'react-router-dom';
import LogoImage from '@app/assets/images/SH_Logo_CR_PNG.png';

const AccessTokenExpiredPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <div style={{width: '100vw', paddingTop: '48px', display:'flex', justifyContent:'center'}}>
                <Image style={{width: '300px'}} src={LogoImage} alt="SUPERHUB" preview={false} />
            </div>
            <Result
                status="403"
                title="Login Session Expired"
                subTitle={
                    <>
                        If you wish to log into <b>SUPERHUB MyHUB</b> again, you may request for a new one-time secure link from login page.
                    </>
                }
                extra={<Button type="primary" onClick={() => navigate("/app/login")}>Back to Login</Button>}
            />
        </>
    )
}

export default AccessTokenExpiredPage