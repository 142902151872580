import { RedExclamationIcon } from "@app/components/common/icons/RedExclamationIcon";
import * as S from "../../../nft-dashboard/common/CommonComponentStyle.styles";
import { Logo } from "./Logo";

export const SubSider =({subMenu, isToggled}:any)=>{
    return(
        <S.SubSider $isToggled={isToggled} width={"250px"} >
          <S.Container>
            <Logo />
          </S.Container>
          <S.SubMenuContainer>
            <S.ThemeText >{subMenu.theme}</S.ThemeText>
            {subMenu.subtitles.map((item: any) => (
              <S.LinkText key={item.subtitle}>
                <a href={item.link}  target={item.openNewTab == true?'_blank':'_self'}>
                  {item.subtitle}
                  {
                    item.badge == true 
                      ? <RedExclamationIcon width={15} height={15} /> 
                      : <></>
                  }
                </a>
              </S.LinkText>
            ))}
          </S.SubMenuContainer>
        </S.SubSider>
    )
}