import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Image, Row } from 'antd';
import LogoImage from '@app/assets/images/SH_Logo_CR_PNG.png';
import * as L from '../components/auth/LoginForm/LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {

    // Hooks
    const { t } = useTranslation();
    
    return (
        <>
          <PageTitle>Welcome</PageTitle>
          <Auth.FormWrapper>
            <L.ImageWrapper>
              <Image src={LogoImage} alt="SUPERHUB" preview={false} />
            </L.ImageWrapper>
            <Auth.FormTitle>Welcome!</Auth.FormTitle>
            <p>With the launch of our new Renewal Center application, we have introduced remarkable features to enhance your renewal experience. Now, you have the convenience of:</p>
            <ul style={{ paddingLeft: '1.5em' }}>
              <li>Checking your overall subscription renewal profile;</li>
              <li>Accessing the latest renewal offer;</li>
              <li>Confirming your subscription upon renewal;</li>
              <li>Making changes to your subscriptions upon renewal.</li>
            </ul>
            <p>To explore these exciting features, pleaes log in to <a href="/app/login" style={{ textDecoration: 'underline' }}><b>MyHUB</b></a>.</p>
          </Auth.FormWrapper>
        </>
    );
}

export default WelcomePage