import styled, { css } from 'styled-components';
import { Col, Collapse } from 'antd';
import { FONT_SIZE, FONT_WEIGHT, LAYOUT, media } from '@app/styles/themes/constants';
import { BurgerIcon } from '../common/icons/BurgerIcon';

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`;

export const SuperhubLogo = styled.span`
    font-weight: ${FONT_WEIGHT.bold};
    font-size: ${FONT_SIZE.xxxl};
    color: var(--primary-color);
    margin-right: 5%;
`;

export const AdvisionLogo = styled.span `
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.md};
    color: var(--primary-color);
    margin-right: 5%;
`;

export const SideMenuBurger = styled(BurgerIcon)`

`;

export const DropdownCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and ${media.md} {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`;

export const BurgerCol = styled(Col)`
  z-index: 999;
  display: flex;
`;

// export const MobileBurger = styled(BurgerIcon)`
//   width: 1.75rem;
//   height: 1.75rem;
//   margin-right: -0.5rem;
//   color: var(--text-main-color);

//   ${(props) =>
//     props.isCross &&
//     css`
//       color: var(--text-secondary-color);
//     `};
// `;

export const SearchColumn = styled(Col)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

interface ProfileColumn {
  $isTwoColumnsLayout: boolean;
}

export const ProfileColumn = styled(Col)<ProfileColumn>`
  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        background-color: var(--sider-background-color);
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

