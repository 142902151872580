import React from 'react';
import { Avatar, Col, Row, Skeleton } from 'antd';
import { useEffect, useState } from "react";
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import * as S from './ProfileDropdown.styles';
import { Popover } from '@app/components/common/Popover/Popover';
import { DefaultProfilePicIcon } from '@app/components/common/icons/DefaultProfilepic';
import { readClaim, readUser } from '@app/services/localStorage.service';
import AccessTokenExpirationCountDown from '../AccessTokenExpirationCountDown/AccessTokenExpirationCountDown';
import { getMyAccountData } from "@app/api/my.api";
import { Account, initAccountValues } from "@app/interfaces/accounts";
import { logClarityEvent } from '@app/utils/clarityutils';
import { ClarityAPIEventType } from '@app/enums/ClarityAPIEventType';
import { useClarity } from 'use-clarity';

export const ProfileDropdown: React.FC = () => {

  const claim = readClaim();
  const user = readUser();
  const { event } = useClarity();

  const [isAccountLoading, setAccountLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<Account>(initAccountValues);

  const renderCountDown = () => {
    return (
        <>
            {claim?.group === "customer" && <AccessTokenExpirationCountDown expiration={claim?.expiration} />}
        </>
    )
  }

  const renderProfileCard = () => {
    if (claim?.group === "customer") {
      return (
        <>
          <S.ProfileTitle>{account == initAccountValues ? user : account.name}</S.ProfileTitle>
          <S.ProfileEmailTitle>{claim?.email}</S.ProfileEmailTitle>
        </>
      )
    }

    if (claim?.group === "admin") {
      return (
        <>
          <S.ProfileTitle>{user}</S.ProfileTitle>
          <S.ProfileEmailTitle>{claim?.email}</S.ProfileEmailTitle>
        </>
      )
    }
    return (
      <>
      </>
    )
  }

  useEffect(() => {
    fetchAccount();
  }, [])

  const fetchAccount = ()=>{
    if (claim?.group === "customer") {
      setAccountLoading(true);
      getMyAccountData()
        .then((res) => { 
          setAccount(res);
          logClarityEvent(event, ClarityAPIEventType.GET_CUSTOMER_ACCOUNT_SUCCESS_EVENT);
        })
        .catch(() => logClarityEvent(event, ClarityAPIEventType.GET_CUSTOMER_ACCOUNT_ERROR_EVENT))
        .finally(()=> setAccountLoading(false));
    }
  }

  return (
    <Popover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          {isAccountLoading ? <Skeleton.Input active={true} size="small" /> : renderProfileCard()}
          {renderCountDown()}
        </Col>
        <Col>
          <Avatar style={{ width: "40px", height: "40px" }} src={<DefaultProfilePicIcon />} />
        </Col>
      </S.ProfileDropdownHeader>
    </Popover>
  );  
};
