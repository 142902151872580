import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { persistRedirectPath, readClaim, readToken } from '@app/services/localStorage.service';

export const restApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

restApi.interceptors.request.use((config) => {
  const token = readToken();
  if (token)
  {
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  }

  return config;
});

restApi.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response?.status === 401) {
    // Get claim if any
    let claim = readClaim();

    // If the claim group is customer store the current path as a redirect path for login success
    if (claim?.group === "customer") persistRedirectPath(window.location.pathname);

    var destination = '/';
    if (claim?.group === 'customer') destination = '/app/login';
    if (claim?.group === 'admin')    destination = '/admin/login';
    window.location.href = destination;
  }
  else {
    throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
  }
});

export interface ApiErrorData {
  message: string;
}
