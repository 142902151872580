import { RenewalOrderItemType, RenewalOrderItemTypeDescription } from "@app/enums/RenewalOrderItemType";
import { MRT_ColumnFiltersState } from "material-react-table";
import React, { ReactNode } from "react";
import { StringIdDropdownItem } from "./interfaces";
import { getLoadingTabName } from "@app/components/nft-dashboard/admin/RenewalCenter/Dictionaries/DictionaryHelper";

// Customer
export interface ConfirmedRenewal {
    id: number | string,
    orderNumber: string,
    orderStatus: string,
    newServicePeriodStartAt: Date | string,
    newServicePeriodEndAt: Date | string,
    productName: string,
    newQuantity: number | string,
    currency: string,
    newUnitPrice: number,
    newTotal: number | string,
    newCustomerSubscriptionCycle: string,
    newCustomerPaymentCycle: string,
    newCustomerContractTerm: string,
    newVendorSubscriptionCycle: string,
    newVendorPaymentCycle: string,
    newVendorContractTerm: string,
    renewalScheduleOn: Date | string,
    customerId: string,
    customerName: string,
    customerType: string,
}

export interface OutstandingRenewalOffer {
    renewalOffer: string,
    productService: string,
    quantity: number | string,
    unitPrice: number,
    subscriptionCycle: string,
    paymentCycle: string,
    currency: string,
    total: number | string,
    autoRenewDate: Date | string,
    autoConfirmDate: Date | string,
    status: string,
}

export interface TransactionDocument {
    invoiceNumber: string,
    status: string,
    currency: string,
    total: number | string,
    issueDate: Date | string,
    dueDate: Date | string,
}

export interface ProformaInvoice {
    renewalInvoiceNumber: string,
    amountPaid: number | string,
    status: string,
    paymentDueAt: Date | string,
    createdAt: Date | string,
    dueInDays: number | string,
}

export interface Product {
    id: string,
    name: string,
    code: string,
    productGroup: string,
    productType: string,
    requireQuotation: boolean,
    requireQtyAlignWith365: boolean,
    description: string,
    itemGrouping: string,
    prices: ProductPrice[],
    lastSyncAt?: string,
}

export interface ProductPrice {
    productId: string,
    name: string,
    unitPrice: number | string | null,
}

export interface SubscriptionOverview {
    productService: string,
    quantity: number | string,
    subscriptionCycle: string,
    paymentCycle: string,
    nextRenewalDate: string,
}

export interface Subscription {
    id: string,
    name: string,
    currency: string,
    quantity: number | string | null,
    unitPrice: number | string | null,
    total: number | string,
    customerSubscriptionCycle: string | null,
    customerPaymentCycle: string | null,
    customerContractTerm: string | null,
    vendorSubscriptionCycle: string,
    vendorContractTerm: string,
    vendorContractPeriodEndAt: string,
    vendorPaymentCycle: string,
    servicePeriodEndAt: Date | string,
    anniversaryDate: Date | string | null,
    scheduledTerminationDate: Date | string | null,
    expiringIn: number | string,
    status: string,
    productId: string,
    productGroup: string,
    productType: string | ReactNode,
    productName: string | null,
    subscriptionId: number | string,
    subscriptionChargeType: string,
    subscriptionChargeAmount: number | string,
    renewalOrderId: number | string,
    renewalOrderNumber: string | null,
    customerId: number | string,
    customerName: string,
    billToCustomerId: number | string,
    billToCustomerName: string,
    canConsolidate?: boolean | null,
    hasTerminationTask: boolean,
    terminationTaskId: number | null,
    lastSyncAt: string,
}

export const initSubscription = {
    id: "---",
    name: "---",
    currency: "---",
    quantity: null,
    unitPrice: null,
    total: "---",
    customerContractTerm: null,
    customerSubscriptionCycle: null,
    customerPaymentCycle: null,
    vendorSubscriptionCycle: "---",
    vendorContractTerm: "---",
    vendorContractPeriodEndAt: "---",
    vendorPaymentCycle: "---",
    servicePeriodEndAt: "---",
    anniversaryDate: null,
    scheduledTerminationDate: null,
    expiringIn: "---",
    status: "---",
    productId: "---",
    productGroup: "---",
    productType: "---",
    productName: null,
    subscriptionId: "---",
    subscriptionChargeType: "---",
    subscriptionChargeAmount: "---",
    renewalOrderId: "---",
    renewalOrderNumber: "---",
    customerId: "---",
    customerName: "---",
    billToCustomerId: "---",
    billToCustomerName: "---",
    hasTerminationTask: false,
    terminationTaskId: null,
    lastSyncAt: "---"
}

export const initSubscriptionMetaData = {
    key: "---",
    value: "---",
    data: [],
    dataCounts: [],
    mode: "---"
}



// Admin

export interface RenewalCustomer {
    id: string,
    name: string,
    email?:string,
    telephone?:string,
    billTo?: string,
    billToId?: string|null,
    billToName?: string |null,
    type: string,
    accountManageType?: string,
    isBelowMOV4AdvPayment?: string|boolean,
    isVIP?: string|boolean,
    isRisky?: string|boolean,
    noOfSubscriptions: number | string,
    noOfRemainingTokens?:number | string,
    noOfSubscribedSuperToken?:number | string,
    noOfRenewalOrders?:number | string,
    noOfProformaInvoices?:number | string,
    noOfBSSOrders?:number | string,
    noOfBSSInvoices?:number | string,
    otherBillToAccounts?: any,
    accountServiceManager: string,
    accountSalesManager: string,
    renewalEmailTemplateGroup: string,
    isOverrideEmailRecipients?: boolean,
    isBalanceCustomerViewEnabled?: boolean,
    isBalanceVisible?: boolean,
    isMOVVisible?: boolean,
    isInvoiceVisible?:boolean,
    lastSyncAt: string,
    monthlyRunRate?: string | number | null,
    billingAddress: string,
    currency: string
    customerClass?:string,
    group?:string,
    groupAccounts?: any,	
    balance?:number,
    createdAt?: string | Date,
}

export interface SimplifiedProfile {
    'Join Date': string | Date,
    'Class': string,
    'Group': string,
    'Account Balance': string | number,
    'Outstanding Invoices': string | number,
    'Risky': string | boolean,
    'MOV': string | number,
    'Active Subscriptions': string | number,
    'Remaining/ Tokens': string | number,
}

export const initRenewalCustomer:RenewalCustomer = {
    id: "--",
    name: "--",
    billTo: "--",
    type: "--",
    accountManageType: "--",
    isBelowMOV4AdvPayment: "--",
    isVIP: "--",
    isRisky: "--",
    noOfSubscriptions: "--",
    otherBillToAccounts:[],
    accountServiceManager: "--",
    accountSalesManager: "--",
    renewalEmailTemplateGroup: "--",
    lastSyncAt: "--",
    billingAddress: "--",
    currency: "--",
    monthlyRunRate: null,
    group:"--",
    groupAccounts:[],
    isBalanceCustomerViewEnabled:false,
    balance:0,
}



export interface RenewalInvoice {
    id: number | string,
    invoiceNumber: string,
    total: number | string,
    status: string,
    paymentDueAt: Date | string,
    dueInDays: number | string,
    paidAt: Date | string | null,
    createdAt: Date | string,
    earliestAnniversaryDate: Date | string,
    earliestNewServicePeriodStartDate: Date | string,
    renewalOrderId: number | string,
    renewalOrderNumber: number | string,
    customerId: string,
    customerName: string,
    customerType: string,
    renewalInvoiceItems: RenewalInvoiceItems[],
    collectOverduePaymentTask: RenewalTask
}

export interface RenewalInvoiceItems {
    id: number | string,
    currency: string
}

export interface ConfirmedRenewalAdmin {
    id: number | string,
    orderNumber: string,
    servicePeriodStartAt: Date | string,
    servicePeriodEndAt: Date | string,
    renewalScheduleOn: Date | string,
    currency: string,
    unitPrice: number,
    total: number | string,
    customerContractTerm: string,
    customerSubscriptionCycle: string,
    customerPaymentCycle: string,
    vendorContractTerm: string,
    vendorSubscriptionCycle: string,
    vendorPaymentCycle: string,
    confirmationDueAt?: Date | string,   // missing
    confirmedAt?: Date | string,         // missing
    invoiceNumber?: string,              // missing
    escalation?: string,                 // missing
    customerId: string,
    customerName: string,
    customerType: string,
    productName: string,
    quantity: number | string,
    assistance?: string,                // missing
    tokens: number | string | null,
    remarks: string | null,
}

export interface AwaitingConfirmation {
    id: number | string,
    orderNumber: string,
    customerId: string,
    customerName: string,
    customerType?: string,
    noOfItems: number | string,
    confirmationDueAt: Date | string,
    requiredUpfrontPayment?: boolean | string,
    earliestAnniversaryDate?: Date | string,
    earliestNewServicePeriodStartDate?: Date | string,
    renewalTaskIds: number[],
    noOfPendingOpenExecuteConfirmedRenewals: number | string,
    kickRenewalCallTaskResult: null | string,
    taskAssignee: null | string,
    status: string,
    tokens: number | string | null,
    remarks: string | null,
}

export interface FollowUpSyncQuantity {
    renewalOffer: string,
    bssId: string,
    companyName: string,
    noOfSubscriptions: number | string,
    currency: string,
    total: number | string,
    autoConfirm: string | Date,
    nextRenewalDate: string,
    status: string,
    dueDate: string,
}

export interface FollowUpAskForHelp {
    subscriptionName: string,
    customerId: string,
    customerName: string,
    quantity: number | string,
    currency: string,
    unitPrice: number,
    customerContractTerm: string,
    customerSubscriptionCycle: string,
    customerPaymentCycle: string,
    total: number | string,
    nextRenewalDate: string | Date,
    requestDate?: string | Date,
    status: string,
    escalation?: string,
}

export interface FollowUpGetQuotation extends FollowUpAskForHelp {
    newUnitPrice: number | string,
    dueDate: string | Date,
}

export interface Termination {
    subscriptionName: string,
    customerId: string,
    customerName: string,
    nextRenewalDate: string | Date,
    quantity: number | string,
    currency: string,
    total: number | string,
    requestDate: string | Date,
    status: string,
}

export interface UpdateRenewalOrderPayload {
    deposit: number | string | null,
    tokens: number | string | null,
    remarks?: string,
    notificationScheduledAt: string | null,
    confirmationDueAt: string | null,
    renewalOrderItems: UpdateRenewalOrderItemPayload[],
}

export interface UpdateRenewalOrderItemPayload {
    subscriptionId: string | null,
    productId: string | null,
    newDescription: string | null,
    newToken: string | number | null,
    newQuantity: string | number | null,
    newUnitPrice: string | number | null,
    newServicePeriodStartAt: string | Date | null,
    newServicePeriodEndAt: string | Date | null,
    newCustomerContractTerm: string | null,
    newCustomerContractPeriodEndAt: string | Date | null,
    newCustomerSubscriptionCycle: string | null,
    newCustomerPaymentCycle: string | null,
    newVendorContractTerm: string | null,
    newVendorContractPeriodEndAt: string | Date | null,
    newVendorSubscriptionCycle: string | null,
    newVendorPaymentCycle: string | null,
    newVendor: string | null,
    newVendorUnitPrice: string | number | null,
    newRequirePrepay: boolean | null,
    newUpgradeOrDowngradeAction: string | null,
    newUpgradeOrDowngradeUnit: number | null,
    newRemarks: string | null,
}

export interface RenewalOrder {
    id: number | string,
    orderNumber: number | string,
    status: string,
    confirmationDueAt: string,
    confirmedAt: string,
    notificationScheduledAt: string,
    customerId: number | string,
    customerName: string,
    deposit: number | string | null,
    tokens: number | string | null,
    remarks?: string,
    renewalOrderItems: RenewalOrderItem[],
    renewalInvoices: RenewalInvoice[],
    renewalTasks: RenewalTask[],
    meta: {
        isEditable: boolean | string | null,
    }
}

export interface RenewalOrderItem {
    id: number | string,
    orderId: number | string,
    status: string,
    itemGroup: string,
    productGroup: string,
    productType: string,
    productName: string,
    currentServicePeriodStartAt: string | Date,
    currentServicePeriodEndAt: string | Date,
    currentCustomerContractTerm: string,
    currentCustomerSubscriptionCycle: string,
    currentCustomerPaymentCycle: string,
    currentQuantity: number | string,
    currentUnitPrice: number | string,
    currentTotal: number | string,
    currentChargeType: string,
    currentToken: number | string | null,
    currentDescription: string | null,
    currentVendorUnitPrice: number | string,
    newQuantity: number | string | null,
    newUnitPrice: number | string | null,
    newCustomerContractTerm: string | null,
    newCustomerSubscriptionCycle: string | null,
    newCustomerPaymentCycle: string | null,
    newVendorContractTerm: string | null,
    newVendorSubscriptionCycle: string | null,
    newVendorPaymentCycle: string | null,
    newServicePeriodStartAt: string | Date,
    newServicePeriodEndAt: string | Date,
    newTotal: number | string | null,
    newToken: number | string | null,
    newDescription: string | null,
    newVendorUnitPrice: number | string | null,
    currency: string,
    newRequirePrepay: boolean | null,
    newChargeType: string,
    currentChargeAmount: number | string | null,
    newChargeAmount: number | string | null,
    createdAt: string | Date,
    currentVendorContractTerm: string,
    currentVendorSubscriptionCycle: string,
    currentVendorPaymentCycle: string,
    productId: string,
    subscriptionId: string,
    subscriptionName: string,
    subscriptionRemarks?:string,
    renewalTasks?: RenewalTask[],
    previousProductId: string | null,
    previousProductCode: string | null,
    previousProductName: string | null,
    productCode: string,
    newRequireQuotation: boolean | null,
    newRequireQtyAlignWith365: boolean | null,
    newCustomerContractPeriodEndAt: string | Date,
    newVendorContractPeriodEndAt: string | Date,
    newVendor: string | null,
    upgradeOrDowngradeUnit: number | null,
    upgradeOrDowngradeAction: string | null,
    remarks: string | null,
    itemType: RenewalOrderItemType | string,
    itemTypeDescription: RenewalOrderItemTypeDescription | string,
}


export const defaultRenewalOrder: RenewalOrder = {
    id: "",
    orderNumber: "",
    status: "",
    confirmationDueAt: "",
    confirmedAt: "",
    notificationScheduledAt: "",
    customerId: "",
    customerName: "",
    deposit: null,
    tokens: null,
    renewalOrderItems: [],
    renewalInvoices: [],
    renewalTasks:[],
    meta: {
        isEditable: "",
    }
};

export const defaultOfferLetterItem: RenewalOrderItem = {
    id: "",
    orderId: "",
    status: "",
    productName: "",
    currentServicePeriodStartAt: "",
    currentServicePeriodEndAt: "",
    currentQuantity: "",
    currentCustomerContractTerm: "",
    currentCustomerSubscriptionCycle: "",
    currentCustomerPaymentCycle: "",
    currency: "",
    currentUnitPrice: "",
    currentTotal: "",
    productType: "",
    itemGroup: "",
    productGroup: "",
    newQuantity: null,
    newUnitPrice: null,
    newCustomerContractTerm: null,
    newCustomerSubscriptionCycle: null,
    newCustomerPaymentCycle: null,
    newVendorContractTerm: null,
    newVendorSubscriptionCycle: null,
    newVendorPaymentCycle: null,
    newServicePeriodStartAt: "",
    newServicePeriodEndAt: "",
    newTotal: null,
    newToken: null,
    newRequirePrepay: null,
    currentChargeType: "",
    newChargeType: "",
    currentChargeAmount: null,
    newChargeAmount: null,
    currentVendorContractTerm: "",
    currentVendorSubscriptionCycle: "",
    currentVendorPaymentCycle: "",
    currentToken: null,
    currentDescription: "",
    newDescription: "",
    currentVendorUnitPrice: "",
    newVendorUnitPrice: null,
    productId: "",
    subscriptionId: "",
    subscriptionName: "",
    createdAt: "",
    previousProductId:  null,
    previousProductCode: null,
    previousProductName: null,
    productCode: "",
    newRequireQuotation: null,
    newRequireQtyAlignWith365: null,
    newCustomerContractPeriodEndAt: "",
    newVendorContractPeriodEndAt: "",
    newVendor: "",
    upgradeOrDowngradeUnit: null,
    upgradeOrDowngradeAction: null,
    remarks: null,
    itemType: "",
    itemTypeDescription: "",
}

export interface CustomerOfferLetterItem extends RenewalOrderItem {
    chargeType: string
}

export interface UpcomingRenewal {
    bssId: string,
    productService: string,
    nextRenewalDate: string | Date,
    subscriptionCycle: string,
    paymentCycle: string,
    quantity: number | string,
    currency: string,
    unitPrice: number | string,
    total: number | string,
}

export interface CustomerRenewalOrder {
    id: number | string,
    orderNumber: string,
    noOfItems: number | string,
    confirmationDueAt: Date | string,
    confirmedAt: Date | string,
    status: string,
    requiredUpfrontPayment?: boolean | string,
    tokens: number | string | null,
    remarks: string | null,
}

export interface AddRenewalOrderItem {
    subscriptionId?: string,
    productId?: string,
    newQuantity?: number | string,
    newUnitPrice?: number | string | null,
    newToken?: number | string,
    newDescription?: string,
    newCustomerContractTerm?: string,
    newCustomerPaymentCycle?: string,
    newVendorSubscriptionCycle?: string,
    newVendorPaymentCycle?: string,
    newVendorUnitPrice?: number | string | null,
    newServicePeriodStartAt: Date | string,
    newServicePeriodEndAt: Date | string,
    newRequirePrepay?: boolean | null
}

export interface AddRenewalOrder {
    renewalOrderItems: AddRenewalOrderItem[],
}

export interface AddRenewalOrderItemsMetaData extends AddRenewalOrder {
    orderId: number | string,
}

export interface InvoicePaidPayload {
    customerId: string,
    orderNumber: number | string,
    invoiceNumber: string,
    paidAt: string | undefined
}

export interface PatchResponse {
    message: string,
}

export interface RenewalOrderItemResponse {
    message: string,
    orderNumber: string,
}

export interface TransferOrderItemResponse {
    message: string,
    orderNumbers: string[],
}

export interface CallLog {
    id: number | string,
    remarks: string,
    calledAt: Date | string,
    createdBy: string,
    customerId: number | string,
    customerName: string,
}

export interface Task {
    id: number | string,
    name: string,
    description: string,
    owner: string,
    assignee: string,
    status: string | null,
    dueAt: Date | string,
    completedAt: Date | string | null,
    createdAt: Date | string,
    stepId: string,
    stepName: string,
    resourceType: string,
    resourceInstanceId: string,
}

export interface FilterPayload {
    filters: FilterItem[],
    sortings?: SortItem[],
    pagination: {
        pageNumber: number,
        pageSize: number
    }
}

export interface FilterItem {
    property: string,
    value: string,
    condition: string,
}

export interface SortItem {
    property: string,
    condition: string,
}

export interface PayInvoicePayload {
    customerId: string,
    orderNumber: string,
    paidAt: string,
}

export interface GetChargeTypePayload {
    customerId: number,
    subOrProdId: string,
    isSubscription: boolean,
    newSubscriptionCycle: string,
    newPaymentCycle: string
}

export const initTask = {
    id: "---",
    name: "---",
    description: "---",
    owner: "---",
    assignee: "---",
    status: null,
    dueAt: "---",
    completedAt: "---",
    createdAt: "---",
    stepId: "---",
    stepName: "---",
    resourceType: "---",
    resourceInstanceId: "---",
}

// MetaData

export interface MetaData {
    key: string,
    value: string,
    dataCounts: NameValuePair[],
    mode: string,
}

export interface CustomerMetaData extends MetaData {
    data: RenewalCustomer[],
}

export interface ConfirmedRenewalsCustomerMetaData extends MetaData {
    data: ConfirmedRenewal[],
}

export interface RenewalOrderCustomerMetaData extends MetaData {
    data: CustomerRenewalOrder[],
}

export interface SubscriptionsMetaData extends MetaData {
    data: Subscription[],
}

export interface RenewalOfferAdminMetaData extends MetaData {
    data: ConfirmedRenewalAdmin[] | AwaitingConfirmation[],
}

export interface RenewalOrderItemMetaData extends MetaData {
    data: RenewalOrderItem[];
}
export interface InvoiceAdminMetaData extends MetaData {
    data: RenewalInvoice[],
}

export interface OutstandingRenewalInvoiceMetaData extends MetaData {
    data: RenewalInvoice[],
}

export interface CallLogMetaData extends MetaData {
    data: CallLog[],
}

export interface ProductMetaData extends MetaData {
    data: Product[],
}

export interface RenewalTaskMetaData extends MetaData {
    data: RenewalTask[],
}

export interface RenewalTaskBulkUpdateRequest {
    "renewalTaskIds": number[],
    "status"?: string,
    "result"?: string,
    "assignTo"?: string,
    "owner"?: string,
}

export const defaultMetaData = {
    key: "",
    value: "",
    data: [],
    dataCounts: [],
    mode: ""
}

export interface RenewalTask {
    id: number | string,
    name: string,
    resourceType: string,
    resourceInstanceId: string,
    resourceName: string | null,
    description: string,
    remark: string,
    owner: string,
    assignee: string,
    status: number | string | null,
    result: number | string | null,
    statusName: string,
    resultName: string,
    dueAt: string | null,
    completedAt: string | null,
    completedBy: string | null,
    createdAt: string,
    createdBy:string,
    customer: string,
}

export const initRenewalTask: RenewalTask = {
    id: "",
    resourceType: "",
    resourceInstanceId: "",
    resourceName: "",
    name: "",
    description: "",
    remark: "",
    owner: "",
    assignee: "",
    status: "",
    result: "",
    statusName: "",
    resultName: "",
    dueAt: null,
    completedAt: "",
    completedBy: "",
    createdAt: "",
    createdBy:"",
    customer: ""
}

export interface AssigneeItem {
    name: string,
    email: string,
    group: string,
}

export interface LetterVersionItem {
    id: number | string,
    orderNumber: string,
    blobVersion: string,
    lastUpdateAt: string,
    lastUpdateBy: string,
    version: string,
}

export interface LetterHistory {
    activities: RenewalTask[],
    versions: LetterVersionItem[],  
}

export const initSelectedTabItem = {
    name: "",
    columns: [],
    meta: defaultMetaData,
    isLoading: false,
    isUpdated: null,
    statusGroupName: "Common",
    apiName: "",
}

// filtering
export const defaultAwaitingFilterItem = { property: "Status", value: "Awaiting Confirmation", condition: "Equal" };
export const defaultConfirmingFilterItem = { property: "Status", value: "Confirming", condition: "Equal" };
export const defaultConfirmedFilterItem = { property: "Status", value: "Confirmed", condition: "Equal" };
export const defaultArchivedFilterItem = { property: "Status", value: "Archived", condition: "Equal" };
export const defaultPaymentOverviewHiddenFilterItem = { "property": "Status", "value": "Unpaid,Overdue", "condition": "Any" };
export const dedefaultPaymentTableHiddenFilterItem = { "property": "Status", "value": "Unpaid,Paid,Overdue", "condition": "Any" };
export const defaultAskForHelpFilterItem = { property: "Name", value: "Ask for Help", condition: "Equal" };
// sorting
export const defaultAwaitingSortItem = { property: "confirmationDueAt", condition: "Ascending" };
export const defaultConfirmingSortItem = { property: "confirmedAt", condition: "Ascending" };
export const defaultConfirmedSortItem = { property: "earliestNewServicePeriodStartDate", condition: "Ascending" };
export const defaultArchivedSortItem = { property: "earliestNewServicePeriodStartDate", condition: "Ascending" };
export const defaultPaymentSortItem = { property: "paymentDueAt", condition: "Ascending" };

// Summary card - name & value
export interface NameValuePair {
    name: string,
    value: number | string | any[],
}

// Data Sync info
export interface DataSyncInfo{
    resource:string,
    frequency:string,
}

// pagination
export const initialPage = 1;
export const initialPagination = ({
    pageIndex: 0,
    pageSize: 10,
})
export const initialFinancePaymentPagination = ({
    pageIndex: 0,
    pageSize: 100,
})

// columnPinning

export const initSubscriptionAdminColumnPinning = { 
    left: ['item', 'mrt-row-expand', 'mrt-row-select', 'product-name'], 
    right: ['renewal-letter-link', 'mrt-row-actions'] 
};

export const initSubscriptionCustomerColumnPinning = { 
    left: ['item', 'product-name'], 
    right: ['renewal-letter-link'] 
};

export const initBillingSubscriptionAdminColumnPinning = {
    left: ['item', 'mrt-row-expand', 'product-name'],
    right: ['renewal-letter-link']
};

export const initBillingSubscriptionCustomerColumnPinning = {
    left: ['item', 'product-name'],
    right: ['renewal-letter-link']
};


// timeout second
export const timeoutSec = 250;

export interface SummaryCardInfo {
    count: any | number | string,
    filters?: MRT_ColumnFiltersState,
    mode: string
};

export const defaultSummaryCardInfo:SummaryCardInfo = {
    count: "-",
    filters: [],
    mode: "-",
};

export interface SummaryCardItem {
    name: string,
    count: any| number | string,
    mode: string,
    filters?: MRT_ColumnFiltersState
    icon?:any,
}

export const defaultSummaryCardItem:SummaryCardItem = {
    name: "-",
    count: getLoadingTabName(""),
    mode: "-",
    filters: [],
};

export interface SubscriptionOption {
    name:string;
    values:StringIdDropdownItem[];
}

export  interface CustomerContactMetaData extends MetaData {
    data: CustomerContact[]
}

export interface CustomerContact {
    id:string,
    accountId?:number | null,
    fullName:string,
    email?:string | null,
    sourceEmail?:string | null,
    companyRole?:string | null,
    phone?:string | null,
    primaryKeyContact?:boolean,
    primaryBillingContact?:boolean,
    marketplaceAccess:boolean,
    keyContact?:string | null,
    billingContact?:string | null,
    techContact?:string | null,
    invoiceCopyTo?:string | null,
    lastSyncAt?: string| null,
    isBillingCopyTo?: boolean,
}

export const initCustomerContact ={
    id:"",
    accountId:null,
    fullName:"",
    email:"",
    companyRole:"",
    phone:"",
    marketplaceAccess:false,
    keyContact:null,
    billingContact:null,
    techContact:null,
    invoiceCopyTo:null,
    isInvoiceCopyTo: false,
}

export interface EditCustomerContact {
    id?:string,
    accountId:number,
    fullName:string,
    email:string | null,
    companyRole:string | null,
    phone:string | null,
    isPrimaryKeyContact:boolean,
    isPrimaryBillingContact:boolean,
    marketplaceAccess:boolean,
    keyContact:string,
    billingContact:string,
    techContact?:string | null,
    invoiceCopyTo?:string | null,
    lastSyncAt?: string| null,
    isBillingCopyTo: boolean,
    isNew: boolean,
    isDeleted: boolean,
}