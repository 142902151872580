import { CascadeItem, DropdownItem, StringIdDropdownItem } from "@app/interfaces/interfaces";
import { Option } from '@app/components/common/selects/Select/Select';
import { CustomerContactMetaData, FilterItem, InvoiceAdminMetaData, OutstandingRenewalInvoiceMetaData, RenewalOfferAdminMetaData, RenewalOrderCustomerMetaData, RenewalTaskMetaData, SortItem, SummaryCardInfo, defaultSummaryCardInfo } from "@app/interfaces/renewals";
import { MRT_ColumnFiltersState } from "material-react-table";
import { ContractStatus, TicketStatus } from "@app/api/tokenCenter.api";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import * as S from "../../../common/CommonComponentStyle.styles";
import { checkInclude } from "@app/utils/utils";
import { BssInvoiceMetaData, BssOrderMetaData } from "@app/interfaces/bss";

export const DateConverter = (date: string) => {
  return date.slice(0, 10);
};

export const getDateFromObject = (dateObj: any): string => {
  let month = Number(dateObj["$M"]) + 1;
  let monthToInput = month.toString().length == 2 ? month : `0${month}`;
  return `${dateObj["$y"]}-${monthToInput}-${dateObj["$D"]}`;
};

export const getFilterArrayDate = (array: any, index: number, extraText: string = ""): string => {
  if(array.length == 0) return "";

  let dateObj: any = array[`${index}`];
  if (dateObj == "" || dateObj == undefined) return "";
  else return `${extraText}${getDateFromObject(dateObj)}`;
};

export const ContractStatusDictionary = [
  { status: ContractStatus.Active, iconColor: "#A1E203" }, // green
  { status: ContractStatus.Inactive, iconColor: "#007AFF" }, // blue
  { status: ContractStatus.Suspended, iconColor: "#FFFF80" }, // yellow
  { status: ContractStatus.Expired, iconColor: "#505050" }, // dark gray
  { status: ContractStatus.PendingForCancel, iconColor: "#F7941D" }, // orange
  { status: ContractStatus.Cancelled, iconColor: "#ED1818" } // red 
];

export const ticketStatusDictionary = [
  { status: TicketStatus.Pending, iconColor: "#F7941D" }, // orange
  { status: TicketStatus.InProgress, iconColor: "#FFFF80" }, // yellow
  { status: TicketStatus.Cancelled, iconColor: "#ED1818" }, // red 
  { status: TicketStatus.Completed, iconColor: "#A1E203" }, // green
];

export const getDropdownItemName = (list: any[] = [], value: any) => {
  let target = null;
  if (list.length >= 1) {
    target = list.find((item: any) => item.id == value);
  }
  return target != null ? target['name'] : "";
}

export const getBalance =(balance:number, currency:string = "HKD") => {
  return <S.BalanceText>{formatBalance(balance, currency)}</S.BalanceText>
}

export const formatBalance = (v:number, currency:string = "HKD") => {
  const notation = v >= 0 ? "DR" : "CR";
  const formattedPrice = formatPrice(v, currency);
  return `${formattedPrice} ${notation}`
}

export const formatPrice = (v:number, currency:string = "HKD") => {
  const localePrice = Math.abs(v).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return `${currency} ${localePrice}`
}

export const formatNumber = (number: number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const currencyFormatter = (params: any) => {
  return '$' + formatNumber(params.value);
};

export const DropdownConverter = (list: DropdownItem[] | StringIdDropdownItem[], hasLabel: boolean = false) => {
  const options = list.map((item: DropdownItem | StringIdDropdownItem) => (
    <Option key={item.id} value={item.id} name={item.name}>{hasLabel ? item.label : item.name}</Option>
  ));
  return options;
}

export const AddSpace = (string: string) => {
  return string.replace(/([A-Z])/g, ' $1').trim();
}

export const RemoveSpace = (string: string) => {
  return string.replace(/ /g, '');
}

export const GroupedDropdownConverter = (res: any, key: string, label: string) => {
  let list: CascadeItem[] = [];
  let categoryList: string[] = Object.keys(res);
  for (let i = 0; i < categoryList.length; i++) {
    list.push(
      {
        label: `${AddSpace(categoryList[i])}`,
        options: [],
      }
    )
  }
  for (let i = 0; i < list.length; i++) {
    let subGroupList: any[] = res[`${categoryList[i]}`];
    for (let j = 0; j < subGroupList.length; j++) {
      list[i].options.push({
        key: subGroupList[j][`${key}`] as string,
        value: subGroupList[j][`${key}`] as string,
        label: subGroupList[j][`${label}`] as string
      })
    }
  }
  return list;
}

export const StringDropdownConverter = (res: any, key: string) => {
  let list: string[] = [];
  let categoryList: string[] = Object.keys(res);

  for (let i = 0; i < categoryList.length; i++) {
    let subGroupList: any[] = res[`${categoryList[i]}`];
    for (let j = 0; j < subGroupList.length; j++) {
      list.push(subGroupList[j][`${key}`] as string);
    }
  }
  return list;
}

export const updateTaskFilters = (
  defaultFilterItem: FilterItem,
  columnFilters: MRT_ColumnFiltersState,
  filters: FilterItem[],
  setFilters: any,
  extraFilterItems: FilterItem[] = []
) => {
  let list: FilterItem[] = [];
  let colFilter = columnFilters;
  let previousFilters = filters;
  let laterFilters: FilterItem[] = [defaultFilterItem, ...extraFilterItems, ...FilterProcessor(list, colFilter)];
  if (previousFilters.length == laterFilters.length
    && previousFilters.find((item: FilterItem) => item.property == "Name")
    && laterFilters.find((item: FilterItem) => item.property == "Name")
    && previousFilters.length == 1
    && laterFilters.length == 1) {
  } else {
    setFilters(laterFilters);
  }
}

export const updateOrderFilters = (
  defaultFilterItems: FilterItem[],
  columnFilters: MRT_ColumnFiltersState,
  filters: FilterItem[],
  setFilters: any,
  minFilterLength: number,
) => {
  let list: FilterItem[] = [];
  let colFilters = columnFilters;
  let previousFilters = filters;
  let laterFilters: FilterItem[] = [...defaultFilterItems, ...FilterProcessor(list, colFilters)];
  if (previousFilters.length == laterFilters.length
    && previousFilters.find((item: FilterItem) => item.property == "Status")
    && laterFilters.find((item: FilterItem) => item.property == "Status")
    && previousFilters.length == minFilterLength
    && laterFilters.length == minFilterLength) {
  } else {
    setFilters(laterFilters);
  }
}

// for sending filter API

export interface ColumnFilterItem { id: string, value: string };
export const FilterProcessor = (list: any, colFilter: any): FilterItem[] => {
  colFilter.filter((item: any) => item.value != null)
    .map((item: any) => {
      if ((item.id == "DueAt" || item.id == "CreatedAt")) {
        list.push({ property: item.id, value: item.value.format("YYYY-MM-DD"), condition: "Contains" })
      } else if (item.id == "Renewal Offer Letter") {
        list.push({ property: "OrderNumber", value: item.value, condition: "Contains" })
      } else if (item.id == "Customer") { // renewal task's customer
        list.push({ property: "Customer", value: item.value.toString(), condition: "Contains" })
      } else if (item.id == "customer") { // renewal order's customer
        list.push({ property: "CustomerName", value: item.value, condition: "Contains" })
      } else if (item.id == "statusName") {
        list.push({ property: "Status", value: item.value, condition: "Equal" })
      } else if (item.id == "resultName") {
        list.push({ property: "Result", value: item.value, condition: "Equal" })
      } else if (item.id == "noOfPendingOpenExecuteConfirmedRenewals") {
        list.push({ property: item.id, value: item.value.join(","), condition: "Any" })
      } else if (item.id == "status"
        || item.id == "noOfItems"
        || item.id == "kickRenewalCallTaskResult"
        || item.id == "requireUpFrontPayment"
        || item.id == "deposit") {
        list.push({ property: item.id, value: item.value, condition: "Equal" })
      } else if ((item.id == "confirmedBy") && item.value.toLowerCase() == "system") {
        list.push({ property: item.id, value: "ConfirmROActivity", condition: "Contains" })
      } else if (item.id == "dueAt"
        || item.id == "createdAt"
        || item.id == "confirmedAt"
        || item.id == "confirmationDueAt"
        || item.id == "paymentDueAt"
      ) {
        list.push({ property: item.id, value: getDateFromObject(item.value), condition: "EarlierThanOrEqual" })
      } else if (item.id == "earliestAnniversaryDate"
        || item.id == "earliestNewServicePeriodStartDate"
      ) {
        // not choose both
        if ((item.value[0] == "" || item.value[0] == undefined) && (item.value[1] == "" || item.value[1] == undefined)) {
          console.log('nothing added')
        }
        // not choose min date, but choosed max date
        else if ((item.value[0] == "" || item.value[0] == undefined) && (item.value[1] !== "" || item.value[1] !== undefined)) {
          list.push({ property: item.id, value: getFilterArrayDate(item.value, 1), condition: "EarlierThanOrEqual" })
        }
        // choosed min date, but not choose max date
        else if ((item.value[0] !== "" || item.value[0] !== undefined) && (item.value[1] == "" || item.value[1] == undefined)) {
          list.push({ property: item.id, value: getFilterArrayDate(item.value, 0), condition: "LaterThanOrEqual" })
        }
        // choose both
        else if ((item.value[0] !== "" || item.value[0] !== undefined) && (item.value[1] !== "" || item.value[1] !== undefined)) {
          list.push({ property: item.id, value: getFilterArrayDate(item.value, 0), condition: "LaterThanOrEqual" })
          list.push({ property: item.id, value: getFilterArrayDate(item.value, 1), condition: "EarlierThanOrEqual" })
        }
      } else if (item.id == "Total") {
        list.push({ property: item.id, value: item.value, condition: "LessThanOrEqual" })
      } else if (item.id == "Remarks") {
        list.push({ property: "Remark", value: item.value, condition: "Contains" })
      } else if (item.id == "Task Remarks") {
        list.push({ property: "CollectOverduePaymentRemark", value: item.value, condition: "Contains" })
      } else {
        list.push({ property: item.id, value: item.value, condition: "Contains" })
      }
    })

  let filteredList = list.filter((item: FilterItem) => item.value !== null || item.value !== undefined || item.value !== "");
  return filteredList;
}

// for showing applied filters tags
export const GenerateFilterTag = (item:any, property:string, t:any) => {
  // Get translation from value
  let columnKey = '';
  if(checkInclude(item.name, 'confirming') && property === 'confirmedat')
      columnKey = 'respondedat';
  else if (checkInclude(item.name, 'confirming') && property === 'confirmedby')
      columnKey = 'respondedby';
  else if(checkInclude(item.name,'payment') && property === 'status')
      columnKey = 'paymentstatus';
  else if(checkInclude(item.name,'tasks') && property === 'createdat')
      columnKey = 'taskCreatedAt';
  else if(checkInclude(item.name,'tasks') && property ==='customer')
      columnKey = 'followUpCustomer';
  else 
      columnKey = property;
  return (<S.TagText>{<Tag>{t(`table-column.${columnKey}`)}</Tag>}</S.TagText>);
};

export const SortProcessor = (sortItem: any): SortItem => {
  return sortItem.desc == false ?
    { property: sortItem.id, condition: "Ascending" }
    :
    { property: sortItem.id, condition: "Descending" }
}

export const getRenewalUserRole = (location: any) => {
  if (location.pathname.includes('/accounts/')) return 'accounts'
  else if (location.pathname.includes('/sales/')) return 'sales'
  else if (location.pathname.includes('/op/')) return 'op'
  else if (location.pathname.includes('/ar/')) return 'ar'
  else if (location.pathname.includes('/customer-success')) return 'customer-success'
  else return 'accounts'
}

export const getRenewalUserHomepageByRole = (role: string) => {
  if (['superadmin', 'as'].includes(role))                      return '/admin/renewal-center/accounts/overview'
  if (['sales', 'op', 'ar', 'customer-success'].includes(role)) return '/admin/renewal-center/' + role + '/overview'
  return '/admin/token-center/customers'
}

export const getCenterRedirectByRole = (role:string) =>{
  if (['superadmin', 'as'].includes(role))                      return 'accounts'
  if (['sales'].includes(role))                                 return 'sales'
  if (['op'].includes(role))                                    return 'op'
  if (['finance'].includes(role))                               return 'ar'   
  if (['customersuccess'].includes(role))                       return 'customer-success'
  else return 'accounts'
}

export const numberToCurrencyString = (placeholder = "--", number: number | string, currencyCode: string | null) => {
  if (number == "" || currencyCode == "")
    return placeholder;

  if (currencyCode) {
    return new Intl.NumberFormat('en-HK', {
      style: 'currency',
      currency: `${currencyCode}`
    })
      .format(number as number)
      .replace('$', 'D '); // US$1,000 -> USD 1,000
  }

  return new Intl.NumberFormat('en-HK').format(number as number);
};

export const itemEqualChecker = (array: any[], value: string) => {
  const result = array.every(element => {
    if (element[`${value}`] === array[0][`${value}`]) {
      return true;
    }
  });

  return result;
}

export const getNoOfSearchedResult = (res: RenewalOfferAdminMetaData | InvoiceAdminMetaData | RenewalTaskMetaData | BssOrderMetaData | BssInvoiceMetaData | CustomerContactMetaData) => {
  let rowCount = res.dataCounts.length == 0 ? 0 : res.dataCounts.filter((item: any) => item.name == "noOfSearchedResult")[0].value as number;
  return rowCount;
}

export const disableBtn = (field: any) => {
  return field == null ? true : false;
}

export const removeDuplicates = (data: string[]) => {
  let unique: string[] = [];
  data.forEach((item: any) => {
    if (!unique.includes(item)) {
      unique.push(item);
    }
  })
  return unique;
}

export const getSummaryCardInfoFromDataCounts = (data:InvoiceAdminMetaData | RenewalOfferAdminMetaData | RenewalOrderCustomerMetaData | OutstandingRenewalInvoiceMetaData, 
                                                 name:string,
                                                 columnFilters: MRT_ColumnFiltersState | undefined = undefined) : SummaryCardInfo => 
  data.dataCounts.length == 0 ? defaultSummaryCardInfo :  
                               { count: data.dataCounts.filter(item => item.name == name)[0].value,
                                 filters: columnFilters,
                                 mode: data.mode  
                               } as SummaryCardInfo
         
export const getSummaryCardInfoFromCount = (data:InvoiceAdminMetaData | RenewalOfferAdminMetaData | RenewalOrderCustomerMetaData | OutstandingRenewalInvoiceMetaData, 
                                            count:number,
                                            columnFilters: MRT_ColumnFiltersState | undefined = undefined) : SummaryCardInfo => 
  data.dataCounts.length == 0 ? defaultSummaryCardInfo :  
                                { count: count,
                                  filters: columnFilters,
                                  mode: data.mode  
                                } as SummaryCardInfo
