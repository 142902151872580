import { AwaitingConfirmation, ConfirmedRenewalAdmin, RenewalInvoice, ProformaInvoice, RenewalCustomer } from "@app/interfaces/renewals";
import { httpApiMock } from "./http.api.mock";
import { StringIdDropdownItem } from "@app/interfaces/interfaces";

// export const RenewalCustomerData: RenewalCustomer[]=[
//     {
//         customerId: "3082",
//         customerName: "Heng Lee Pearl Company Limited",
//         type: "SOHO",
//         noOfSubscriptions: 2,
//         accountManager: "John"
//     },
//     {
//         customerId:"3081",
//         customerName: "Caton Technology (Asia) Limited",
//         type:"SOHO",
//         noOfSubscriptions: 4,
//         accountManager: "John"
//     },
//     {
//         customerId: "3078",
//         customerName: "Leader Light Ltd.",
//         type:"SOHO",
//         noOfSubscriptions: 6,
//         accountManager: "Peace"
//     },
//     {
//         customerId: "2679",
//         customerName: "Abacare Hong Kong Limited",
//         type: "SOHO",
//         noOfSubscriptions: 1,
//         accountManager: "Carol"
//     },
//     {
//         customerId: "2108",
//         customerName: "4M INDUSTRIAL DEVELOPMENT LIMITED - HKCHITAT",
//         type: "Managed",
//         noOfSubscriptions: 10,
//         accountManager: "Crystal"
//     },
//     {
//         customerId: "8676",
//         customerName: "UMP Company Limited",
//         type:"SOHO",
//         noOfSubscriptions:6,
//         accountManager: "Carol"
//     }
// ]
export const ProformaInvoiceCustomerDetailData: ProformaInvoice[] = [
    {
        renewalInvoiceNumber: "P/INV/12345",
        status: "Overdue",
        amountPaid: 2400,
        createdAt: "2023-08-10",
        paymentDueAt: "2023-08-20",
        dueInDays: 0,
    },
    {
        renewalInvoiceNumber: "P/INV/24680",
        status: "Unpaid",
        amountPaid: 1080,
        createdAt: "2023-09-03",
        paymentDueAt: "2023-09-15",
        dueInDays: 5,
    },
    {
        renewalInvoiceNumber: "P/INV/35179",
        status: "Unpaid",
        amountPaid: 400,
        createdAt: "2023-09-19",
        paymentDueAt: "2023-09-29",
        dueInDays: 13,
    },
    {
        renewalInvoiceNumber: "P/INV/35179",
        status: "Unpaid",
        amountPaid: 400,
        createdAt: "2024-02-16",
        paymentDueAt: "2024-02-26",
        dueInDays: 13,
    },
];
// export const AwaitingRenewalAdminAccountData: AwaitingConfirmation[]=[
// {
//     id: 1,
//     orderNumber:"Offer OF/031",
//     customerId: "1700",
//     customerName: "Violin & Guitar Centre",
//     customerType: "Managed",
//     noOfItems:38,
//     confirmationDueAt: "2023-09-1500T00000000",
//     requiredUpfrontPayment: true,
//     status:"Awaiting Confirmation",
// },
// {
//     id: 2,
//     orderNumber:"Offer OF/032",
//     customerId: "2923",
//     customerName: "Shing Shun Building Services Engineering Limited",
//     customerType: "Managed",
//     noOfItems:104,
//     confirmationDueAt: "2023-09-1500T00000000",
//     requiredUpfrontPayment: true,
//     status:"Awaiting Confirmation",
// },
// {
//     id: 3,
//     orderNumber:"Offer OF/033",
//     customerId: "1188",
//     customerName: "Viewking Trading Ltd.",
//     customerType: "SOHO",
//     noOfItems:67,
//     confirmationDueAt: "2023-10-2700T00000000",
//     requiredUpfrontPayment: true,
//     status:"Awaiting Confirmation",
// }

// ];
// export const ConfirmedRenewalAdminAccountData: ConfirmedRenewalAdmin[] = [
//     {
//         id:1,
//         orderNumber: "Offer OF/001",
//         servicePeriodStartAt:"2023-08-2700T00000000",
//         servicePeriodEndAt: "2023-08-2700T00000000",
//         currency:'HKD',
//         quantity: 1,
//         unitPrice: 2400,
//         total: 2400,
//         customerContractTerm: "Yearly",
//         customerPaymentCycle: "Yearly",
//         confirmedAt: "2023-08-2700T00000000",
//         confirmationDueAt: "2023-08-2700T00000000",
//         invoiceNumber: "P/INV/12345",
//         escalation: "Escalated to Carol",
//         customerId: "2108",
//         customerName: "4M INDUSTRIAL DEVELOPMENT LIMITED - HKCHITAT",
//         customerType:"Managed",
//         productName:"MIT Service(Recurrent)",
//     },
//     {
//         id:2,
//         orderNumber: "Offer OF/002",
//         servicePeriodStartAt:"2023-08-2700T00000000",
//         servicePeriodEndAt: "2023-08-2700T00000000",
//         quantity: 1,
//         currency:'HKD',
//         unitPrice: 800,
//         customerContractTerm: "Yearly",
//         customerPaymentCycle: "Yearly",
//         total: 800,
//         confirmedAt: "2023-08-2700T00000000",
//         confirmationDueAt: "2023-08-2700T00000000",
//         invoiceNumber: "P/INV/12345",
//         escalation: "Escalated to Alexia",
//         customerId: "2108",
//         customerName: "4M INDUSTRIAL DEVELOPMENT LIMITED - HKCHITAT",
//         customerType:"Managed",
//         productName:"Microsoft Defender Office 365",
//     },
//     {
//         id:3,
//         orderNumber: "Offer OF/003",
//         servicePeriodStartAt:"2023-10-1200T00000000",
//         servicePeriodEndAt: "2023-10-2700T00000000",
//         quantity: 25,
//         currency:'HKD',
//         unitPrice: 84,
//         customerContractTerm: "Yearly",
//         customerPaymentCycle: "Monthly",
//         total: 2100,
//         confirmedAt: "2023-09-1200T00000000",
//         confirmationDueAt: "2023-10-1200T00000000",
//         invoiceNumber: "P/INV/24680",
//         escalation: "",
//         customerId: "3078",
//         customerName: "Leader Light Ltd.",
//         customerType:"Managed",
//         productName:"M365 Business Premium (with Moder +)",
//     },
//     {
//         id: 4,
//         orderNumber: "Offer OF/004",
//         servicePeriodStartAt:"2022-11-2100T00000000",
//         servicePeriodEndAt: "2023-11-2100T00000000",
//         quantity: 1,
//         currency:'HKD',
//         unitPrice: 200,
//         customerContractTerm: "Yearly",
//         customerPaymentCycle: "Yearly",
//         total: 200,
//         confirmedAt: "2023-09-1400T00000000",
//         confirmationDueAt: "2023-11-2100T00000000",
//         invoiceNumber: "P/INV/37951",
//         escalation: "",
//         customerId: "3081",
//         customerName: "Caton Technology (Asia) Limited",
//         customerType: "SOHO",
//         productName: "Proejct Plan 3"
//     },
//     {
//         id: 5,
//         orderNumber: "Offer OF/005",
//         servicePeriodStartAt:"2022-01-2600T00000000",
//         servicePeriodEndAt: "2023-01-2600T00000000",
//         quantity: 105,
//         currency:'HKD',
//         unitPrice: 84,
//         customerContractTerm: "Yearly",
//         customerPaymentCycle: "Monthly",
//         total: 8820,
//         confirmedAt: "2023-01-2600T00000000",
//         confirmationDueAt: "2023-02-2600T00000000",
//         invoiceNumber: "",
//         escalation: "",
//         customerId: "2803",
//         customerName: "Koelnmesse Limited",
//         customerType:"Managed",
//         productName:"Domain Subscription"
//     }
// ];
// export const PaymentAccountData: RenewalInvoice[] = [
//     {
//         id: 1,
//         invoiceNumber: "P/INV/12345",
//         customerId: "2679",
//         customerName: "Abacare Hong Kong Limited",
//         customerType:"Managed",
//         status: 'Overdue',
//         total: 93896.4,
//         renewalOrderId:5,
//         renewalOrderNumber: "ROL-0000000001",
//         createdAt: "2023-08-27",
//         paymentDueAt: "2023-09-07",
//         paidAt: null,
//         dueInDays: 3,
//     },
//     {
//         id:2,
//         invoiceNumber: "P/INV/24680",
//         customerId: "3082",
//         customerName: "Heng Lee Pearl Company Limited",
//         customerType:"Managed",
//         status: 'Unpaid',
//         total: 168,
//         renewalOrderId:5,
//         renewalOrderNumber: "ROL-0000000001",
//         createdAt: "2023-09-12",
//         paymentDueAt: "2023-09-22",
//         paidAt: null,
//         dueInDays: 6,
//     },
//     {
//         id:3,
//         invoiceNumber: "P/INV/37951",
//         customerId: "1620",
//         customerName: "REC-CEL JV (rcjv.com.hk)",
//         customerType:"Soho",
//         status: 'Paid',
//         total: 14895,
//         renewalOrderId:5,
//         renewalOrderNumber: "ROL-0000000001",
//         createdAt: "2023-09-12",
//         paymentDueAt: "2023-09-22",
//         paidAt: null,
//         dueInDays: 6,
//     }
// ];
// // httpApiMock.onGet(`api/renewals/customers-list`).reply((config) => {
// //     return [
// //         200,
// //         RenewalCustomerData
// //     ]
// // })
// httpApiMock.onGet(`api/renewals/customers/customerId/proforma-invoices`).reply((config) => {
//     return [
//         200,
//         ProformaInvoiceCustomerDetailData
//     ];
// })
// httpApiMock.onGet(`all-awaiting-renewal-orders`).reply((config)=>{
//     return [
//         200,
//         {
//             key:"",
//             value:"",
//             data:AwaitingRenewalAdminAccountData,
//             dataCounts:[]
//         }
//     ]
// })
// httpApiMock.onGet(`all-confirmed-renewal-orders`).reply((config)=>{
//     return [
//         200,
//         {
//             key:"",
//             value:"",
//             data:ConfirmedRenewalAdminAccountData,
//             dataCounts:[]
//         }
//     ]
// })
// httpApiMock.onGet(`api/renewals/customers/orders/invoices`).reply((config)=>{
//     return [
//         200,
//         {
//             key:"",
//             value:"",
//             data:PaymentAccountData,
//             dataCounts:[]
//         }
//     ]
// })

httpApiMock.onGet(`api/renewals/account-types`).reply((config)=>{
    return [
        200,
        [
            {id: "Customer", name: "Customer"},
            {id: "Legacy Hosted Customer", name:"Legacy Hosted Customer"},
            {id: "Terminated", name: "Terminated"}
        ] as StringIdDropdownItem[] 
    ]
})