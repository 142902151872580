import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { deleteRedirectPath, persistRedirectPath } from '@app/services/localStorage.service';
import { hasModuleAccess } from '@app/utils/authutils';

const RequireAuthUser: React.FC<WithChildrenProps> = ({ children }) => {
  // Get current path name
  const currentPath = window.location.pathname;

  // Get authentication details
  const token = useAppSelector((state) => state.auth.token);
  const group = useAppSelector((state) => state.claim.claim?.group);
  const modules = useAppSelector((state) => state.claim.claim?.modules);

  // Check if the user doesn't have access due to invalid token and group
  const hasUserAccess = token && group == "customer";
  if (!hasUserAccess) {
    persistRedirectPath(window.location.pathname); // Store path that we can redirect after success login
    return <Navigate to="/app/login" replace />; // Redirect to login page
  }

  // Check if the user doesn't have access to specific module in current path
  if (!hasModuleAccess(currentPath, modules)) {
    return <Navigate to="/not-authorized" replace />; // Redirect to not authorized page
  }

  deleteRedirectPath(); // Delete redirect path if any
  return <>{children}</>; // Render child components
};

export default RequireAuthUser;
