import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuthAdmin: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  const group = useAppSelector((state) => state.claim.claim?.group);

  return token && group === "admin" ? <>{children}</> : <Navigate to="/admin/login" replace />;
};

export default RequireAuthAdmin;
