import React from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const GuestLayout = React.lazy(() => import('@app/components/layouts/GuestLayout/GuestLayout'));
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import MarketplaceLandingPage from '@app/pages/MarketplaceLandingPage';
import { AdminSubMenuContent, UserSubMenuContent } from '@app/components/router/Menu';
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuthUser from '@app/components/router/RequireAuthUser';
import RequireAuthAdmin from '@app/components/router/RequireAuthAdmin';
import { withLoading } from '@app/hocs/withLoading.hoc';
import WelcomePage from '@app/pages/WelcomePage';
import LoginMagicLinkPage from '@app/pages/LoginPages/LoginMagicLinkPage';
import LoginAzureADPage from '@app/pages/LoginPages/LoginAzureADPage';
import AccessTokenExpiredPage from '@app/pages/ResultPages/AccessTokenExpiredPage';
import NotAuthorizedToAccessPage from '@app/pages/ResultPages/NotAuthorizedToAccessPage';
import { AccountStatementPage } from '../nft-dashboard/common/BillingCenter/AccountStatementPage';

const AccountOverviewAdminPage = React.lazy(() => import('@app/pages/admin/ProfileCenter/Index'));
const AccountDetailAdminPage = React.lazy(() => import('@app/pages/admin/ProfileCenter/_id/Index'));
const TokenTrackerPage = React.lazy(() => import('@app/pages/TokenCenterPages/TokenTrackerPage'));
const UploadedDocumentPage = React.lazy(() => import('@app/pages/DocumentCenterPages/UploadedDocumentPage'));
const UploadCenterPage = React.lazy(() => import('@app/pages/DocumentCenterPages/UploadHistoryPage'));
const SpecialAnnouncementPage = React.lazy(() => import('@app/pages/SupportCenterPages/SpecialAnnouncementPage'));
const SuperhubHotlinePage = React.lazy(() => import('@app/pages/SupportCenterPages/SuperhubHotlinePage'));
const StandardSupportSLAPage = React.lazy(() => import('@app/pages/SupportCenterPages/StandardSupportSLAPage'));
const MSAdminCenterLinksPage = React.lazy(() => import('@app/pages/SupportCenterPages/MSAdminCenterLinksPage'));
const NotificationPage = React.lazy(() => import('@app/pages/SupportCenterPages/NotificationPage'));
const SpecialAnnouncementAdminPage = React.lazy(() => import('@app/pages/SupportCenterAdminPages/SpecialAnnouncementAdminPage'));
const SuperhubHotlineAdminPage = React.lazy(() => import('@app/pages/SupportCenterAdminPages/SuperhubHotlineAdminPage'));
const StandardSupportSLAAdminPage = React.lazy(() => import('@app/pages/SupportCenterAdminPages/StandardSupportSLAAdminPage'));
const MSAdminCenterLinksAdminPage = React.lazy(() => import('@app/pages/SupportCenterAdminPages/MSAdminCenterLinksAdminPage'));
const NotificationAdminPage = React.lazy(() => import('@app/pages/SupportCenterAdminPages/NotificationAdminPage'));
const TokenConsumptionLogPage = React.lazy(() => import('@app/pages/TokenCenterPages/TokenConsumptionLogPage'));
const TokenTrackerAdminPage = React.lazy(() => import('@app/pages/admin/TokenCenterPages/TokenTrackerAdminPage'));
const TokenConsumptionLogAdminPage = React.lazy(() => import('@app/pages/admin/TokenCenterPages/TokenConsumptionLogAdminPage'));
const AccountDetailPage = React.lazy(() => import('@app/pages/DashboardPages/AccountDetailPage'));
const TokenConsumptionLogVerticalAdminPage = React.lazy(() => import('@app/pages/admin/TokenCenterPages/TokenConsumptionLogVerticalAdminPage'));
const ContractOverviewPage = React.lazy(() => import('../nft-dashboard/admin/TokenCenter/Customers/_id/contracts/Index'));
const TokenCustomerOverviewPage = React.lazy(() => import('../nft-dashboard/admin/TokenCenter/Customers/Index'));
const LogOverviewPage = React.lazy(() => import('../nft-dashboard/admin/TokenCenter/Customers/_id/contracts/_id/tickets/_id/logs/Index'));
const LogSearchResultPage = React.lazy(() => import('../nft-dashboard/admin/TokenCenter/Customers/_id/contracts/_id/tickets/_id/logs/LogSearchResultPage'));
const ContractDetailOverviewPage = React.lazy(() => import('../nft-dashboard/admin/TokenCenter/Customers/_id/contracts/contractdetails/Index'));
const RenewalAccountOverviewPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/Accounts/Index'));
const RenewalOverviewPage = React.lazy(() => import('../nft-dashboard/common/RenewalCenter/OverviewPage'));
const RenewalSalesOverviewPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/Sales/Index'));
const RenewalFinanceOverviewPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/Finance/Index'));
const RenewalCustomerSuccessOverviewPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/CustomerSuccess/Index'));
const RenewalOfferLetterPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/Accounts/OfferLetter/Index'));
const RenewalCSOpsOverviewPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/CSOps/Index'));
const RenewalCustomerOfferLetterPage = React.lazy(() => import('../nft-dashboard/common/RenewalCenter/OfferLetter/Index'));
const SubscriptionCustomerPage = React.lazy(() => import('../nft-dashboard/common/RenewalCenter/SubscriptionCustomerPage'));
const CustomerOverviewPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/CustomerOverviewPage'));
const CustomerDetailPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/CustomerDetailPage'));
const ViewAllSubscriptionsPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/ViewAllSubscriptionsPage'));
const CustomerContactsPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/CustomerContactsPage'));

// Bss Pages
const CustomerBssOrdersPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/Bss/CustomerBssOrdersPage'));
const CustomerBssInvoicesPage = React.lazy(() => import('../nft-dashboard/admin/RenewalCenter/Bss/CustomerBssInvoicesPage'));

const Logout = React.lazy(() => import('./Logout'));

export const ACCOUNT_DETAILS = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const AccountDetail = withLoading(AccountDetailPage);
const AccountOverviewAdmin = withLoading(AccountOverviewAdminPage);
const AccountDetailsAdmin = withLoading(AccountDetailAdminPage);
const UploadedDocument = withLoading(UploadedDocumentPage);
const UploadCenter = withLoading(UploadCenterPage);
const TokenTracker = withLoading(TokenTrackerPage);
const TokenConsumptionLog = withLoading(TokenConsumptionLogPage);
const TokenTrackeAdmin = withLoading(TokenTrackerAdminPage);
const TokenConsumptionLogAdmin = withLoading(TokenConsumptionLogAdminPage);
const TokenCustomerOverview = withLoading(TokenCustomerOverviewPage);
const RenewalCustomerOverview = withLoading(CustomerOverviewPage);
const RenewalCustomerDetail = withLoading(CustomerDetailPage);
const RenewalCustomerViewAllSubscriptions = withLoading(ViewAllSubscriptionsPage);
const RenewalCustomerContacts = withLoading(CustomerContactsPage);
const RenewalAccountOverview = withLoading(RenewalAccountOverviewPage);
const RenewalSalesOverview = withLoading(RenewalSalesOverviewPage);
const RenewalCSOpsOverview = withLoading(RenewalCSOpsOverviewPage);
const RenewalFinanceOverview = withLoading(RenewalFinanceOverviewPage);
const RenewalCustomerSuccessOverview = withLoading(RenewalCustomerSuccessOverviewPage);
const RenewalOfferLetter = withLoading(RenewalOfferLetterPage);
const RenewalCustomerOfferLetter = withLoading(RenewalCustomerOfferLetterPage);
const ContractOverview = withLoading(ContractOverviewPage);
const ContractDetailOverview = withLoading(ContractDetailOverviewPage);
const RenewalOverview = withLoading(RenewalOverviewPage);
const RenewalSubscription = withLoading(SubscriptionCustomerPage);
const AccountStatement = withLoading(AccountStatementPage);

const LogOverview = withLoading(LogOverviewPage);
const LogSearchResult = withLoading(LogSearchResultPage);
const SpecialAnnouncement = withLoading(SpecialAnnouncementPage);
const TokenConsumptionLogAdminVertical = withLoading(TokenConsumptionLogVerticalAdminPage);
const SuperhubHotline = withLoading(SuperhubHotlinePage);
const StandardSupportSLA = withLoading(StandardSupportSLAPage);
const MSAdminCenterLinks = withLoading(MSAdminCenterLinksPage);
const Notification = withLoading(NotificationPage);

const SpecialAnnouncementAdmin = withLoading(SpecialAnnouncementAdminPage);
const SuperhubHotlineAdmin = withLoading(SuperhubHotlineAdminPage);
const StandardSupportSLAAdmin = withLoading(StandardSupportSLAAdminPage);
const MSAdminCenterLinksAdmin = withLoading(MSAdminCenterLinksAdminPage);
const NotificationAdmin = withLoading(NotificationAdminPage);

const GuestLayoutFallback = withLoading(GuestLayout);
const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

// Bss Pages
const CustomerBssOrders = withLoading(CustomerBssOrdersPage);
const CustomerBssInvoices = withLoading(CustomerBssInvoicesPage);

export const AppRouter: React.FC = () => {
  const guestLayoutWelcomePage = (
    <GuestLayoutFallback>
      <WelcomePage />
    </GuestLayoutFallback>
  );  

  const guestLayoutLoginMsolPage = (
    <GuestLayoutFallback>
      <LoginAzureADPage />
    </GuestLayoutFallback>
  );  

  const guestLayoutLoginMagicLinkPage = (
    <GuestLayoutFallback>
      <LoginMagicLinkPage />
    </GuestLayoutFallback>
  );  

  const protectedUserLayout = (
    <RequireAuthUser>
      <MainLayout menuItems={UserSubMenuContent} />
    </RequireAuthUser>
  );

  const protectedAdminLayout = (
    <RequireAuthAdmin>
      <MainLayout menuItems={AdminSubMenuContent} />
    </RequireAuthAdmin>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={guestLayoutWelcomePage} />
        <Route path="/marketplace" element={<GuestLayoutFallback><MarketplaceLandingPage /></GuestLayoutFallback>} />
        <Route path="/billing" element={<GuestLayoutFallback><MarketplaceLandingPage /></GuestLayoutFallback>} />

        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="/login-session-expired" element={<AccessTokenExpiredPage />} />
        <Route path="/not-authorized" element={<NotAuthorizedToAccessPage />} />

        <Route path="admin/login" element={guestLayoutLoginMsolPage} />
        <Route path="admin" element={protectedAdminLayout}>
          <Route path="profile-center">
            <Route path="accounts" >
              <Route path='' element={<AccountOverviewAdmin />} />
              <Route path=':customerId' element={<AccountDetailsAdmin />} />
            </Route>
          </Route>
          <Route path="token-center">
            <Route path="token-tracker" element={<TokenTrackeAdmin />} />
            <Route path="token-consumption-log" element={<TokenConsumptionLogAdmin />} />
            <Route path="token-consumption-log-vertical" element={<TokenConsumptionLogAdminVertical />} />
            <Route path="customers">
              <Route path='' element={<TokenCustomerOverview />} />
              <Route path=':customerId' element={<ContractOverview />} />
              <Route path=':customerId/contracts/:contractId/contract-detail' element={<ContractDetailOverview />} />
              <Route path=':customerId/contracts/:contractId/tickets/:ticketId' element={<LogOverview />} />
            </Route>
            <Route path='contracts/:contractId/contract-detail' element={<ContractDetailOverview />} />
            <Route path='tickets/:ticketId' element={<LogSearchResult />} />
          </Route>
          <Route path="renewal-center">
            <Route path='accounts'>
              <Route path="overview" element={<RenewalAccountOverview />} />
              <Route path="customers" >
                <Route path="" element={<RenewalCustomerOverview />} />
                <Route path=':customerId' element={<RenewalCustomerDetail />} />
                <Route path=':customerId/bss/orders' element={<CustomerBssOrders />} />
                <Route path=':customerId/bss/invoices' element={<CustomerBssInvoices />} />
                <Route path=':customerId/subscriptions' element={<RenewalCustomerViewAllSubscriptions />} />
                <Route path=':customerId/contacts' element={<RenewalCustomerContacts />} />
                <Route path=':customerId/offers/:orderNumber' element={<RenewalOfferLetter />} />
              </Route>
            </Route>
            <Route path='sales'>
              <Route path="overview" element={<RenewalSalesOverview />} />
              <Route path="customers" >
                <Route path="" element={<RenewalCustomerOverview />} />
                <Route path=':customerId' element={<RenewalCustomerDetail />} />
                <Route path=':customerId/bss/orders' element={<CustomerBssOrders />} />
                <Route path=':customerId/bss/invoices' element={<CustomerBssInvoices />} />
                <Route path=':customerId/subscriptions' element={<RenewalCustomerViewAllSubscriptions />} />
                <Route path=':customerId/contacts' element={<RenewalCustomerContacts />} />
                <Route path=':customerId/offers/:orderNumber' element={<RenewalOfferLetter />} />
              </Route>
            </Route>
            <Route path='op'>
              <Route path="overview" element={<RenewalCSOpsOverview />} />
              <Route path="customers" >
                <Route path="" element={<RenewalCustomerOverview />} />
                <Route path=':customerId' element={<RenewalCustomerDetail />} />
                <Route path=':customerId/bss/orders' element={<CustomerBssOrders />} />
                <Route path=':customerId/bss/invoices' element={<CustomerBssInvoices />} />
                <Route path=':customerId/subscriptions' element={<RenewalCustomerViewAllSubscriptions />} />
                <Route path=':customerId/contacts' element={<RenewalCustomerContacts />} />
                <Route path=':customerId/offers/:orderNumber' element={<RenewalOfferLetter />} />
              </Route>
            </Route>
            <Route path='ar'>
            <Route path="overview" element={<RenewalFinanceOverview />} />
            <Route path="customers" >
              <Route path="" element={<RenewalCustomerOverview />} />
              <Route path=':customerId' element={<RenewalCustomerDetail />} />
              <Route path=':customerId/bss/orders' element={<CustomerBssOrders />} />
              <Route path=':customerId/bss/invoices' element={<CustomerBssInvoices />} />
              <Route path=':customerId/subscriptions' element={<RenewalCustomerViewAllSubscriptions />} />
              <Route path=':customerId/contacts' element={<RenewalCustomerContacts />} />
              <Route path=':customerId/offers/:orderNumber' element={<RenewalOfferLetter />} />
            </Route>
          </Route>
          <Route path='customer-success'>
            <Route path="overview" element={<RenewalCustomerSuccessOverview />} />
            <Route path="customers" >
              <Route path="" element={<RenewalCustomerOverview />} />
              <Route path=':customerId' element={<RenewalCustomerDetail />} />
              <Route path=':customerId/bss/orders' element={<CustomerBssOrders />} />
              <Route path=':customerId/bss/invoices' element={<CustomerBssInvoices />} />
              <Route path=':customerId/subscriptions' element={<RenewalCustomerViewAllSubscriptions />} />
              <Route path=':customerId/contacts' element={<RenewalCustomerContacts />} />
              <Route path=':customerId/offers/:orderNumber' element={<RenewalOfferLetter />} />
            </Route>
          </Route>
          </Route>
          <Route path="support-center">
            <Route path="special-announcement" element={<SpecialAnnouncementAdmin />} />
            <Route path="superhub-hotline" element={<SuperhubHotlineAdmin />} />
            <Route path="standard-support-sla" element={<StandardSupportSLAAdmin />} />
            <Route path="ms-admin-center-links" element={<MSAdminCenterLinksAdmin />} />
            <Route path="notification" element={<NotificationAdmin />} />
          </Route>
        </Route>

        <Route path="app/login/:token?" element={guestLayoutLoginMagicLinkPage} />
        <Route path='app' element={protectedUserLayout}>
          <Route path="profile-center" element={<AccountDetail />} />
          <Route path="document-center">
            <Route path="uploaded-document" element={<UploadedDocument />} />
            <Route path="upload-center" element={<UploadCenter />} />
          </Route>
          <Route path="token-center">
            <Route path="token-tracker" element={<TokenTracker />} />
            <Route path="token-consumption-log" element={<TokenConsumptionLog />} />
          </Route>
          <Route path="renewal-center">
            <Route path='' element={<RenewalOverview />} />
            <Route path='subscription' element={<RenewalSubscription />} />
            <Route path='offers' >
              <Route path=':orderNumber' element={<RenewalCustomerOfferLetter />} />
            </Route>
          </Route>
          <Route path="billing-center">
            <Route path='' element={<AccountStatement />} />
          </Route>
          <Route path="support-center">
            <Route path="special-announcement" element={<SpecialAnnouncement />} />
            <Route path="superhub-hotline" element={<SuperhubHotline />} />
            <Route path="standard-support-sla" element={<StandardSupportSLA />} />
            <Route path="ms-admin-center-links" element={<MSAdminCenterLinks />} />
            <Route path="notification" element={<Notification />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
