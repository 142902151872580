import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { AzureADForm } from '@app/components/auth/LoginForm/AzureADForm';

const LoginAzureADPage: React.FC = () => {

  // Hooks
  const { t } = useTranslation();
  
  return (
    <>
      <PageTitle>{t('common.login')}</PageTitle>
      <AzureADForm />
    </>
  );
};

export default LoginAzureADPage;
