import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Image } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doCallMsGraph, getClaimForRedirect, doMsol } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import LogoImage from '@app/assets/images/SH_Logo_CR_PNG.png';
import * as L from './LoginForm.styles';
import * as S from '../../nft-dashboard/common/CommonComponentStyle.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '@app/store/slices/authSlice';
import { readServiceUser } from '@app/services/localStorage.service';
import { setUser } from '@app/store/slices/userSlice';
import { SwitchAccountButton } from './SwitchAccountBtn';
import { useClarity } from 'use-clarity';
import { logClarityEvent } from '@app/utils/clarityutils';
import { ClarityAPIEventType } from '@app/enums/ClarityAPIEventType';

interface LoginFormData {
  token: string;
}

interface GraphDataProps {
  graphData: GraphData
}

interface GraphData {
  givenName: string;
  surname: string;
  jobTitle: string;
  userPrincipalName: string;
  id: string;
}

export const WelcomeUser: React.FC = () => {
  const { accounts } = useMsal();
  const username = accounts[0].username;
  const name = accounts[0].name;
  const tenantId = accounts[0].tenantId;
  const sessionId = accounts[0].idTokenClaims?.sid;

  return (
    <>
      <div id="profile-aad">
        <p>Profile data from AAD:</p>
        <p><strong>Tid: </strong> {tenantId}</p>
        <p><strong>Name: </strong> {name}</p>
        <p><strong>Email: </strong> {username}</p>
        <p><strong>Session: </strong> {sessionId}</p>
      </div>
    </>
  );
};

export const ProfileData: React.FC<GraphDataProps> = ({
  graphData
}) => {
  return (
    <>
      <div id="profile-graph">
        <p>Profile data from Graph API:</p>
        <p><strong>First Name: </strong> {graphData.givenName}</p>
        <p><strong>Last Name: </strong> {graphData.surname}</p>
        <p><strong>Job Title: </strong> {graphData.jobTitle}</p>
        <p><strong>Email: </strong> {graphData.userPrincipalName}</p>
        <p><strong>Id: </strong> {graphData.id}</p>
      </div>
    </>
  );
};

export const ProfileContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const RequestProfileData = () => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        dispatch(doCallMsGraph(response.accessToken))
          .unwrap()
          .then((data) => setGraphData(data))
          .catch((err) => console.error(err));
      });
  };

  return (
    <>
      <h5 className="card-title">Welcome {accounts[0].name}</h5>
      <br />
      {graphData ? (
        <ProfileData graphData={graphData} />
      ) : (
        <Button onClick={RequestProfileData}>
          Request Profile Information
        </Button>
      )}
    </>
  );
};

export const AzureADForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { event } = useClarity();

  const [isLoading, setLoading] = useState(false);
  const invalidText = <S.InvalidText>{t('login.accountNotFound')}</S.InvalidText>;
  const { instance } = useMsal();

  const handleSubmit = () => {
    setLoading(true);

    instance.loginPopup(loginRequest)
      .then((res) => {
        setUser(res.account?.name);
        notificationController.success({ message: 'Authenticated!'});
        dispatch(doMsol(res.accessToken))
          .unwrap()
          .then((res) => {
            logClarityEvent(event, ClarityAPIEventType.AUTHENTICATE_MSOL_SUCCESS_EVENT);
            getClaimForRedirect(navigate, setLoading);
          })
          .catch((err) => {
              notificationController.error({ message: t('header.notifications.loginFailed') });
              setLoading(false);
              logClarityEvent(event, ClarityAPIEventType.AUTHENTICATE_MSOL_ERROR_EVENT);
          });
      })
      .catch((err) => {
        notificationController.error({ message: t('header.notifications.loginFailed') });
        setLoading(false);
        logClarityEvent(event, ClarityAPIEventType.AUTHENTICATE_MSOL_ERROR_EVENT);
      });
  };

  return (
    <>
      <Auth.FormWrapper>
        <L.ImageWrapper>
            <Image src={LogoImage} alt="SUPERHUB" preview={false} />
        </L.ImageWrapper>
        <Auth.FormTitle>Service Portal</Auth.FormTitle>
        <L.LoginDescription>Please sign-in with Microsoft account.</L.LoginDescription>
        <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.login')}
            </Auth.SubmitButton>
            {readServiceUser() == "invalid" ?
              invalidText
              : <></>}
          </BaseForm.Item>
        </BaseForm>
        <Auth.FooterWrapper>
          <Col xs={24} lg={12} xl={12}>
            <SwitchAccountButton />
          </Col>
        </Auth.FooterWrapper>
      </Auth.FormWrapper>
    </>
  );
};
