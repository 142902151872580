import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
import * as S from '../../../nft-dashboard/common/CommonComponentStyle.styles';

interface MainHeaderProps extends WithChildrenProps {
  isToggled: boolean;
}

export const MainHeader: React.FC<MainHeaderProps> = ({ children, isToggled }) => {
  return (
    <S.Header>{children}</S.Header>
  );
};

