import { DownOutlined } from '@ant-design/icons';
import { BASE_COLORS, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
    min-width: 300px;
  }
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;

export const ProfileTitle = styled.div`
font-size: 1rem;
color: ${BASE_COLORS.darkgrey};
font-weight: ${FONT_WEIGHT.bold};
display: none;

@media only screen and ${media.md} {
  display: flex;
}
`

export const ProfileEmailTitle = styled(ProfileTitle)`
font-size: 0.5rem;
`
