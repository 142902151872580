import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MagicLinkForm } from '@app/components/auth/LoginForm/MagicLinkForm';

const LoginMagicLinkPage = () => {

    // Hooks
    const { token } = useParams();
    const { t } = useTranslation();
    
    return (
        <>
            <PageTitle>{t('common.login')}</PageTitle>
            <MagicLinkForm token={token} />
        </>
    )
}

export default LoginMagicLinkPage