import { useCan } from "@app/components/common/CustomHook/useCan";
import { DirectSearch } from "@app/components/header/components/HeaderSearch/DirectSearch";
import { getRenewalUserRole } from "@app/components/nft-dashboard/admin/TokenCenter/Customers/ConversionHelper";
import { AdminSubMenuContent } from "@app/components/router/Menu";
import { useLocation } from "react-router-dom";

export const GlobalSearch = ({ menuItems }: any) => {
  const url = window.location.href;
  const location = useLocation();
  const profileCenter = 'admin/profile-center';
  const tokenCenter = 'admin/token-center';
  const renewalCenter = 'admin/renewal-center';

  const getCenter = () => {
    if (url.includes(profileCenter)) return {
      name: 'profile',
      url: `${process.env.REACT_APP_ADMIN_ACCOUNTS_URL}`,
      placeholder: 'Search by Customer Name/ BSS ID',
    };
    else if (url.includes(tokenCenter)) return {
      name: 'token',
      url: `${process.env.REACT_APP_ADMIN_TOKEN_CENTER_URL}/tickets`,
      placeholder: 'Search by Ticket Number',
    };
    else if (url.includes(renewalCenter)) return {
      name: 'renewal',
      url: `${process.env.REACT_APP_ADMIN_RENEWAL_CENTER_URL}/${getRenewalUserRole(location)}/customers`,
      placeholder: 'Search by Customer Name/ BSS ID'
    };
  }

  const globalSearch = () => {
    const search = useCan({
      canAccess: menuItems == AdminSubMenuContent,
      Component:
        <DirectSearch
          center={getCenter()}
        />
    });
    return search;
  }
  return (globalSearch());
}
