import "./AnimatedEllipsis.css";

export const AnimatedEllipsisIcon = () => {
    return (
      <>
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    );
  };