import { useState } from "react"
import * as S from "../../../nft-dashboard/common/CommonComponentStyle.styles";
import { SearchOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { CompanyNameInput } from "@app/components/nft-dashboard/admin/Input/CompanyNameFilter";
import { TransparentButton } from "@app/components/nft-dashboard/common/AccountDetails/buttons/button.styles";
import { themeObject } from "@app/styles/themes/themeVariables";
import { BASE_COLORS } from "@app/styles/themes/constants";

export const DirectSearch = ({ center }: any) => {
    const [number, setNumber] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);

    const globalSearch = () => {
        if (center?.name == "token") {
            return (
                <S.GlobalSearchItem
                    id={`number`}
                    name={`number`}
                    label={""}
                >
                    <S.FormInput
                        size="small"
                        value={number}
                        onKeyDown={(e) => { if (e.code === "Enter") window.location.href = `${center?.url}/${number}` }}
                        onChange={(e) => setNumber(e.target.value)}
                        placeholder={center?.placeholder}
                    />
                </S.GlobalSearchItem>
            )
        } else {
            return (
                <S.GlobalSearchItem
                    id={`bssId`}
                    name={`bssId`}
                    label={""}
                >
                    <CompanyNameInput
                        name={'bssId'}
                        label={""}
                        value={number}
                        setValue={setNumber}
                        placeholder={center?.placeholder}
                        isGlobalSearch={true}
                        setLoading={setLoading}
                    />
                </S.GlobalSearchItem>
            )
        }
    }

    const disableBtn = () => {
        return center?.name !== "token" && Number.isNaN(parseInt(number)) ? true
            : isLoading == true ? true
                : false;
    }

    return (
        <S.AlignCenterCol xs={0} sm={0} md={0} lg={24} xl={24}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {globalSearch()}
                <TransparentButton disabled={disableBtn()} onClick={() => { window.location.href = `${center?.url}/${number}` }}>
                    <SearchOutlined style={{ color: disableBtn() == true? BASE_COLORS.darkgrey:themeObject['light'].primary }} />
                </TransparentButton>
            </div>
        </S.AlignCenterCol>
    )

}