import { Account, AccountDemographicsPayload } from '@app/interfaces/accounts';
import { restApi } from '@app/api/rest.api';

export interface LiteAccount {
    id: string;
    name: string;
}

export interface PagedAccountsData{
    meta:{
        totalRowCount:number;
        pageNumber:number;
        pageSize:number;
    },
    data:string[]
}

export interface PagedLiteAccountsData{
    meta:{
        totalRowCount:number;
        pageNumber:number;
        pageSize:number;
    },
    data:LiteAccount[]
}

export const getAccountsData = (pageNumber:number, pageSize: number): Promise<PagedAccountsData> =>
    restApi.get<PagedAccountsData>(`api/customers?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(({data})=>data);

export const searchAccounts = (pageNumber:number, pageSize: number, query: string): Promise<PagedLiteAccountsData> =>
    restApi.get<PagedLiteAccountsData>(`api/customers/search?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${encodeURIComponent(query)}`).then(({data})=>data);

export const getAccountData = (id?:string): Promise<Account> =>
    restApi.get<Account>('api/customers/'+id).then(({data})=>data);

export const updateAccountDemographics = (id:number, payload: AccountDemographicsPayload): Promise<undefined> =>
    restApi.put<undefined>('api/customers/'+id+'/demographics', { ...payload }).then(({ data }) => data);

