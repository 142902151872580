import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import LogoImage from '@app/assets/images/SH_Logo_CR_PNG.png';
import * as L from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { MagicLinkRequest, sendRequestMagicLink, SsoResponse } from '@app/api/auth.api';
import { useCan } from '@app/components/common/CustomHook/useCan';
import { Loading } from '@app/components/common/Loading';
import { setUser } from '@app/store/slices/userSlice';
import { getValueFromToken } from '@app/utils/jwtutils';
import { doMagicLink, getClaimForRedirect } from '@app/store/slices/authSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { useClarity } from 'use-clarity';
import { ClarityAPIEventType } from '@app/enums/ClarityAPIEventType';
import { logClarityEvent } from '@app/utils/clarityutils';

interface MagicLinkFormProps {
    token?: string;
}

export const MagicLinkForm = ({token}:MagicLinkFormProps) => {

    // Variables
    const defaultLimitInSeconds = 30;
    const hasToken = token != null || token != undefined;

    // Hooks
    const { event } = useClarity();
    const { t } = useTranslation();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ disabledForm, setDisabledForm] = useState<boolean>(false);
    const [ remainingSeconds, setRemainingSeconds ] = useState(defaultLimitInSeconds);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Functions
    const onFinishFailed = () => {};
      
    const handleSubmit = (value: any) => {
        setIsLoading(true);

        // Prepare request
        let request:MagicLinkRequest = {
            email: value.email,
        };
        
        // Send magic link request to backend api
        sendRequestMagicLink(request)
            .then((res) => {
                notificationController.success({ message: res });
                setDisabledForm(true);
                logClarityEvent(event, ClarityAPIEventType.REQUEST_MAGIC_LINK_SUCCESS_EVENT);
            })
            .catch(() => {
                notificationController.error({ message: t('login.accountNotFound') });
                logClarityEvent(event, ClarityAPIEventType.REQUEST_MAGIC_LINK_ERROR_EVENT);
            })
            .finally(() => setIsLoading(false));
    }
    const handleAccessToken = () => {
        try {
            // Set user from magic link token
            setUser(getValueFromToken(token!, "name") ?? "-");
    
            // Do login using magic link
            dispatch(doMagicLink(token!))
                .unwrap()
                .then((res) => {
                    if (!res) throw new Error("No response");
                    logClarityEvent(event, ClarityAPIEventType.AUTHENTICATE_MAGIC_LINK_SUCCESS_EVENT);
                    getClaimForRedirect(navigate, setIsLoading)
                })
                .catch(() => {
                    notificationController.error({ message: t('header.notifications.loginFailed') });
                    logClarityEvent(event, ClarityAPIEventType.AUTHENTICATE_MAGIC_LINK_ERROR_EVENT);
                    navigate('/app/login');
                });
        }
        catch 
        {
            logClarityEvent(event, ClarityAPIEventType.AUTHENTICATE_MAGIC_LINK_ERROR_EVENT);
            notificationController.error({ message: t('header.notifications.loginFailed') });
            navigate('/app/login');
        }
    }
    const showMagicLinkForm = () => {
        const component = useCan({
            canAccess: !hasToken,
            Component:
                <BaseForm layout="vertical" onFinish={handleSubmit} onFinishFailed={onFinishFailed} requiredMark="optional">
                    <Auth.FormItem
                        name="email"
                        rules={[ { required: true, message: t('common.emailRequired') },
                                { type: 'email', message: t('common.notValidEmail'), }, ]} >
                        <Auth.FormInput placeholder={t('common.email')} disabled={isLoading || disabledForm} />
                    </Auth.FormItem>
                    <BaseForm.Item noStyle>
                        <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading} disabled={disabledForm}>
                            {disabledForm ? <Auth.MagicLinkWaitingText>Wait <b>{remainingSeconds} second(s)</b> to send new one-time secure link</Auth.MagicLinkWaitingText> 
                                          : "Login with Email"}
                        </Auth.SubmitButton>
                    </BaseForm.Item>
                </BaseForm>
        });

        return component;
    }
    const showAuthenticatingMagicLink = () => {
        const component = useCan({
            canAccess: hasToken,
            Component: <Loading />
        });

        return component;
    }

    // Effects
    useEffect(() => {
        if(disabledForm) {
            const timer = setInterval(() => {
                setRemainingSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
          
            setTimeout(() => {
                setDisabledForm(false);
                setRemainingSeconds(defaultLimitInSeconds);
                clearInterval(timer);
            }, 30000);
          
            return () => clearInterval(timer);
        }
    }, [disabledForm]);
    useEffect(() => {
        if(hasToken) {
            setTimeout(() => {
                handleAccessToken();
            }, 2000);
        }
    }, []);

    // Components
    const LoginDescription = () => {
        let message = hasToken ? "Please wait while we are authenticating your login and directing you to our MyHUB."
                               : "Please enter your registered admin email address to receive one-time secure link to enter MyHUB.";

        return (
            <L.LoginDescription>
                {message}
            </L.LoginDescription>
        );
    };

    return (
        <>
            <Auth.FormWrapper>
                <L.ImageWrapper>
                    <Image src={LogoImage} alt="SUPERHUB" preview={false} />
                </L.ImageWrapper>
                <Auth.FormTitle>MyHUB</Auth.FormTitle>
                <LoginDescription />
                {showMagicLinkForm()}
                {showAuthenticatingMagicLink()}
            </Auth.FormWrapper>
        </>
    )
}
