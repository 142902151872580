import { Skeleton } from "antd";

export const CardSkeleton = () => (
    <div style={{overflow:'hidden'}}>
        <Skeleton.Input active={true} block={true} style={{height: 30, marginBottom: 15}}/>
        <Skeleton.Input active={true} block={true} style={{height: 70}}/>
    </div>
);

export const TransparentCardSkeleton = () => (
    <div style={{overflow:'hidden', width:'100%'}}>
        <Skeleton.Input active={true} block={true} style={{height: 30, marginBottom: 15 }}/>
        <Skeleton.Input active={true} block={true} style={{height: 70, }}/>
    </div>
);