import styled from 'styled-components';
import { Select as AntSelect } from 'antd';
import { Dimension } from 'interfaces/interfaces';
import { normalizeProp } from '@app/utils/utils';
import { FONT_WEIGHT, BORDER_RADIUS, FONT_SIZE, BASE_COLORS } from '@app/styles/themes/constants';
import { themeObject } from '@app/styles/themes/themeVariables';

export interface InternalSelectProps {
  $width?: Dimension;
  $shadow?: boolean;
}

export const Select = styled(AntSelect) <InternalSelectProps>`
  width: ${(props) => props.$width && normalizeProp(props.$width)};
  font-weight: ${FONT_WEIGHT.medium};
  &.ant-select-borderless {
  }

  .ant-space{
    border: var(--primary-color) solid 1px;
    border-radius: 10px;
  }
  .ant-select-selection-placeholder {
    font-size: ${FONT_SIZE.md};
    color: #DBDBDB;
  }

  .ant-select-arrow {
    color: var(--primary-color);
  }

  .anticon-check,
  .ant-select-item-option-state {
    color: #EAEAEA;
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 0.875rem;
    line-height: ${FONT_SIZE.xs};
    font-size: ${FONT_SIZE.xs};
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: ${BASE_COLORS.transparent};
    border: 1px solid ${BASE_COLORS.transparent};
  }


  &.ant-select:not(.ant-select-customize-input),
   .ant-select-selector,
   .ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
    border: 2px solid var(--primary-color);
    border-radius: 25px;
    height: 2.5 rem;
  }

  .ant-select-clear {
    color: #DBDBDB;
  }
  .ant-select-selection-item-remove {
    color: #505050;

    &:hover {
      color: #505050;
    }
  }
  .ant-select-item-option-disabled {
    color: ${BASE_COLORS.transparent};
  }

  .ant-select-selector {
    border-radius: 25px!important;
    border: 1px solid ${BASE_COLORS.transparent}!important;
    background: ${BASE_COLORS.transparent}!important;
    box-shadow: none!important;
  }

  .ant-select.ant-select-in-form-item {
    width: 80%;
  }


  .ant-picker.ant-picker-range{
    margin-right: 10px! important;
  }

  .ant-select-dropdown{
    background-color:var(--primary-color);
    color: #EAEAEA;
    font-weight: ${FONT_WEIGHT.bold};
    border-radius:10px;
  }
  
  .ant-select-item.ant-select-item-option{
    background-color:var(--primary-color);
    color: #EAEAEA;
    font-weight: ${FONT_WEIGHT.bold};
  }

  
  .ant-select-item.ant-select-item-option.ant-select-item-option-active,
  .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected{
    background:#EAEAEA;
    color: var(--primary-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-select:not(.ant-select-disabled):hover {
    border: 1px solid var(--primary-color);
  }

`;

export const ProductSelect = styled(Select) <InternalSelectProps>`
.ant-select-dropdown{
  background-color: #EAEAEA;
  color: ${themeObject['light'].primary};
  font-weight: ${FONT_WEIGHT.bold};
  border-radius:10px;
  border: 1px solid #EAEAEA;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background:${themeObject['light'].primary};
}

.ant-select-item.ant-select-item-option{
  background-color:#EAEAEA;
  font-weight: ${FONT_WEIGHT.bold};
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active,
.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected{
  background: var(--primary-color);
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
}

&.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 0.875rem;
  max-height: 30px;
  line-height: ${FONT_SIZE.xs};
  font-size: ${FONT_SIZE.xs};
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
}
`;