import * as S from "../../common/CommonComponentStyle.styles";

export const CustomizedModal = ({ showModal, setShowModal, title, width = 80, props }: any) => {

    return (
        <>
            <S.ModalForm
                open={showModal}
                width={`${width}vw`}
                title={<S.Title level={5}>{`${title}`}</S.Title>}
                closable={true}
                maskClosable={false}
                onCancel={() => setShowModal(false)}
                footer={null}
            >
                {props}
            </S.ModalForm>
        </>
    )
}