import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { readClaim } from '@app/services/localStorage.service';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { useClarity } from 'use-clarity';
import { logClarityEvent } from '@app/utils/clarityutils';
import { ClarityAPIEventType } from '@app/enums/ClarityAPIEventType';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const claim = readClaim();
  const { instance } = useMsal();
  const { event } = useClarity();

  const handleLogout = (logoutType: string | undefined) => {
    logClarityEvent(event, ClarityAPIEventType.LOGOUT_USER_EVENT);
    if (claim?.group === 'customer') handleCustomerLogout()
    if (claim?.group === 'admin')    handleAdminLogout()
  };

  const handleCustomerLogout = () => {
    navigate('/logout');
  }

  const handleAdminLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: '/logout',
      mainWindowRedirectUri: '/logout'
    });
  }

  return (
    <div {...props}>
      <Button onClick={(event) => handleLogout(claim?.group)}>
        {t('header.logout')}
      </Button>
    </div>
  );
};
