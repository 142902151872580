import { AnimatedEllipsisIcon } from "@app/components/common/icons/AnimatedEllipsisIcon";
import { FilterItem, RenewalTaskMetaData } from "@app/interfaces/renewals";

export const getTotalCount = (meta: RenewalTaskMetaData) => {
    return meta?.dataCounts.length == 0 ? 0 : meta?.dataCounts[0].value;
}

export const PaymentAheadHiddenItems: FilterItem[] = [
    { property: "Status", value: "Payment Settled *", condition: "NotEqual" }
];

export const ExecuteRenewalsHiddenItems: FilterItem[] = [
    { property: "Status", value: "Return to Negotiation *", condition: "NotEqual" },
    { property: "Status", value: "Proceeded *", condition: "NotEqual" }
];

export const getLoadingTabName = (name: string)=>{
    return (
        <div style={{display:'ruby'}}><text>{name}</text><AnimatedEllipsisIcon/></div>
    )
}