import { LiteAccount, searchAccounts } from "@app/api/account.api";
import { useState } from "react";
import { Col, type SelectProps } from 'antd';
import { BaseSelect } from '@app/components/common/BaseSelect/BaseSelect';
import * as S from "../../common/CommonComponentStyle.styles"
import { getFilteredRenewalCustomers } from "@app/api/renewalCenter.api";
import { readClaim } from "@app/services/localStorage.service";
import { RenewalCustomer } from "@app/interfaces/renewals";

let timeout: ReturnType<typeof setTimeout> | null


export const CompanyNameFilter = ({
    name,
    label,
    value,
    setValue,
    placeholder,
    isGlobalSearch = false,
    setLoading,
}: any) => {

    const [data, setData] = useState<SelectProps['options']>([]);
    const [filterCompanyName, setFilterCompanyName] = useState<string[]>([]);

    return (
        <Col xs={24} sm={24} md={8} xl={8}>
            <S.FormItem
                id={`${name}`}
                name={`${name}`}
                label={`${label}`}
            >
                <BaseSelect
                    size="small"
                    showSearch
                    allowClear
                    showArrow={isGlobalSearch == true ? false : true}
                    filterOption={false}
                    notFoundContent={null}
                    onBlur={() => setData([])}
                    onSearch={(value) => handleSearch(value, setData, isGlobalSearch, setLoading)}
                    onClear={() => { setValue(null); setFilterCompanyName([]) }}
                    onSelect={(value) => handleSelect(value, setValue)}
                    onDeselect={(value: any) => { setValue(null); setFilterCompanyName([...filterCompanyName].filter((item) => item !== value)) }}
                    placeholder={placeholder}
                    defaultValue={[]}
                    value={value}
                    options={(data || []).map((item) => ({ label: item.text, value: item.value }))}
                >
                </BaseSelect>
            </S.FormItem>
        </Col>
    )
}

export const CompanyNameInput = ({
    name,
    label,
    value,
    setValue,
    placeholder,
    isGlobalSearch,
    setLoading,
}: any) => {

    const [data, setData] = useState<SelectProps['options']>([]);
    const [filterCompanyName, setFilterCompanyName] = useState<string[]>([]);

    return (
        <BaseSelect
            size="small"
            showSearch
            allowClear
            showArrow={isGlobalSearch == true ? false : true}
            filterOption={false}
            notFoundContent={null}
            onBlur={() => setData([])}
            onSearch={(value) => handleSearch(value, setData, isGlobalSearch, setLoading)}
            onClear={() => { setValue(null); setFilterCompanyName([]) }}
            onSelect={(value) => handleSelect(value, setValue)}
            onDeselect={(value: any) => { setValue(null); setFilterCompanyName([...filterCompanyName].filter((item) => item !== value)) }}
            placeholder={placeholder}
            defaultValue={[]}
            value={value}
            options={(data || []).map((item) => ({ label: item.text, value: item.value }))}
        >
        </BaseSelect>
    )
}

export const fetchCompanyNameList = (value: string, callback: Function, isGlobalSearch: boolean, setLoading: any) => {
    if (timeout) {
        clearTimeout(timeout)
        timeout = null
    }

    const fake = () => {
        if (isGlobalSearch == true) {
            setLoading(true);
        }
        let claim = readClaim();
        let payload = {
            "filters": [
              {
                "property": "AccountSalesManager",
                "value": claim?.email!,
                "condition": "Equal"
              },
              {
                "property": "Name",
                "value": value,
                "condition": "Contains"
              }
            ],
            "sortings": [
              {
                "property": "Id",
                "condition": "Ascending"
              }
            ],
            "pagination": {
              "pageNumber": 1,
              "pageSize": 5
            }
          }
        
        if(claim?.role == "sales"){
            getFilteredRenewalCustomers(payload)
            .then((res) => {
                updateDropdown(res, callback, value);
            }).finally(() => {
                if (isGlobalSearch == true) {
                    setLoading(false);
                }
            })
        }else{
            searchAccounts(1, 5, value)
            .then((res) => {
                updateDropdown(res, callback, value);
            }).finally(() => {
                if (isGlobalSearch == true) {
                    setLoading(false);
                }
            })
        } 
    }
    if (value) {
        timeout = setTimeout(fake, 300)
    } else {
        callback([])
    }
}

const updateDropdown = (res:any, callback:Function, value: string)=>{
    const data = res.data.map((item: RenewalCustomer) => ({
        value: item.id.toString(),
        text: `(${item.id}) ${item.name}`,
    }))
    if (!Number.isNaN(parseInt(value))) {
        data.push({ value: value, text: `Search by BSS ID: #${value}...` })
    }
    callback(data)
}

const handleSearch = (newValue: string, setData: any, isGlobalSearch: boolean, setLoading: any) => {
    if (newValue.length < 3) return;
    fetchCompanyNameList(newValue, setData, isGlobalSearch, setLoading)
}

const handleSelect = (value: unknown, setValue: any) => {
    v: typeof value === 'string' || typeof value === 'number'
        ? setValue(value)
        : undefined
}