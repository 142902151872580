
import { RenewalOfferAdminMetaData, RenewalCustomer, RenewalOrder, RenewalOrderCustomerMetaData, ConfirmedRenewalsCustomerMetaData, SubscriptionsMetaData, AddRenewalOrder, RenewalInvoice, InvoiceAdminMetaData, OutstandingRenewalInvoiceMetaData, TransferOrderItemResponse, RenewalOrderItemResponse, PatchResponse, InvoicePaidPayload, CallLogMetaData, Product, ProductMetaData, Task, CustomerMetaData, RenewalTask, RenewalTaskMetaData, FilterPayload, PayInvoicePayload, RenewalOrderItemMetaData, RenewalTaskBulkUpdateRequest, GetChargeTypePayload, SubscriptionOption, DataSyncInfo, UpdateRenewalOrderPayload, LetterVersionItem, CustomerContactMetaData, CustomerContact } from '@app/interfaces/renewals';
import { restApi } from './rest.api';
import './mocks/renewalCenter.api.mock';
import { BssInvoiceMetaData, BssOrderMetaData } from '@app/interfaces/bss';

// Customer
export const getMyCustomer = ()=>
restApi.get<RenewalCustomer>(`api/renewals/me/customer`).then(({ data }) => data);

export const getMyAwaitingRenewals = (pageNumber:number, pageSize:number)=>
restApi.get<RenewalOrderCustomerMetaData>(`api/renewals/me/orders/pending?PageNumber=${pageNumber}&PageSize=${pageSize}`).then(({ data }) => data);

export const getMyConfirmingRenewals = (pageNumber: number, pageSize: number) =>
    restApi.get<RenewalOrderCustomerMetaData>(`api/renewals/me/orders/confirming?PageNumber=${pageNumber}&PageSize=${pageSize}`).then(({ data }) => data);

export const getMyConfirmedRenewals = (pageNumber: number, pageSize: number) =>
    restApi.get<RenewalOrderCustomerMetaData>(`api/renewals/me/orders/confirmed?PageNumber=${pageNumber}&PageSize=${pageSize}`).then(({ data }) => data);

export const getMyArchivedRenewals = (pageNumber: number, pageSize: number) =>
    restApi.get<RenewalOrderCustomerMetaData>(`api/renewals/me/orders/archived?PageNumber=${pageNumber}&PageSize=${pageSize}`).then(({ data }) => data);

export const getMyConfirmedSubscriptions = () =>
    restApi.get<ConfirmedRenewalsCustomerMetaData>(`api/renewals/me/orders/items/confirmed?PageNumber=1&PageSize=100`).then(({ data }) => data);

export const getMyOutstandingInvoices = () =>
    restApi.get<OutstandingRenewalInvoiceMetaData>(`api/renewals/me/orders/invoices`).then(({ data }) => data);

export const getMyRenewalOrder = (orderNumber: string) =>
    restApi.get<RenewalOrder>(`api/renewals/me/orders/${orderNumber}`).then(({ data }) => data);

export const getMyRenewalOrderInvoices = (orderNumber: string) =>
    restApi.get<RenewalOrder>(`api/renewals/me/orders/${orderNumber}/invoices`).then(({ data }) => data);

export const downloadMyLetterByOrderId = (orderNumber: string, isStd: boolean) =>
    restApi.get<any>(`api/renewals/me/orders/${orderNumber}/download?isStd=${isStd}`, { responseType: 'blob' }).then(({ data }) => data);

export const downloadMyInvoice = (invoiceNumber: string) =>
    restApi.get<any>(`api/renewals/me/orders/invoices/${invoiceNumber}/download`, { responseType: 'blob' }).then(({ data }) => data);

export const getMySubscriptions = () =>
    restApi.get<SubscriptionsMetaData>(`api/renewals/me/subscriptions`).then(({ data }) => data);

export const getMyBillingSubscriptions = () =>
    restApi.get<SubscriptionsMetaData>(`api/renewals/me/billing-subscriptions`).then(({ data }) => data);

export const getMyRenewalTasks = (orderNumber: string, name: string) =>
    restApi.get<RenewalTask[]>(`api/renewals/me/tasks/${orderNumber}?name=${name}`).then(({ data }) => data);

// POST API
export const postMyRenewalTask = (payload: FormData) =>
    restApi.post<FormData>(`api/renewals/me/tasks`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

// PUT API
export const updateMyRenewalOrderItemsQty = (orderNumber: string, payload: any) =>
    restApi.put<any>(`/api/renewals/me/orders/${orderNumber}/itemsQty`, payload).then(({ data }) => data);

// Admin
// GET API

export const getAssigneeList = () =>
    restApi.get<any>(`api/renewals/assignees`).then(({ data }) => data);

export const getAccountTypeList = () =>
    restApi.get<any>(`/api/renewals/filter`).then(({ data }) => data);

export const getRenewalCustomers = (pageNumber: number, pageSize: number) =>
    restApi.post<CustomerMetaData>(`api/renewals/customers?PageNumber=${pageNumber}&PageSize=${pageSize}`).then(({ data }) => data);

export const getFilteredRenewalCustomers = (payload: FilterPayload) =>
    restApi.post<CustomerMetaData>(`api/renewals/customers`, payload).then(({ data }) => data);

export const getFilterRenewalCustomers = (customerId: string, pageNumber: number, pageSize: number) =>
    restApi.get<CustomerMetaData>(`api/renewals/customers?customerId=${customerId}&PageNumber=${pageNumber}&PageSize=${pageSize}`).then(({ data }) => data);

export const getRenewalCustomer = (customerId: string) =>
    restApi.get<RenewalCustomer>(`api/renewals/customers/${customerId}`).then(({ data }) => data);

export const getProformaInvoicesByCustomer = (customerId: string,) =>
    restApi.get<InvoiceAdminMetaData>(`api/renewals/customers/${customerId}/orders/invoices`).then(({ data }) => data);

export const getAllAwaitingConfirmationRenewals = (pageIndex: number, pageSize: number) =>
    restApi.get<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/pending?PageNumber=${pageIndex}&PageSize=${pageSize}`).then(({ data }) => data);

export const getAllConfirmingRenewals = (pageIndex: number, pageSize: number) =>
    restApi.get<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/confirming?PageNumber=${pageIndex}&PageSize=${pageSize}`).then(({ data }) => data);

export const getFilterRenewalOrders = (payload: FilterPayload) =>
    restApi.post<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/summaries/filter`, payload).then(({ data }) => data);

export const getFilterRenewalOrdersByCustomer = (customerId: string, payload: FilterPayload) =>
    restApi.post<RenewalOfferAdminMetaData>(`api/renewals/customers/${customerId}/orders/summaries/filter`, payload).then(({ data }) => data);

export const getFilterConfirmedSubscriptions = (payload: FilterPayload) =>
    restApi.post<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/items/confirmed/filter`, payload).then(({ data }) => data);

export const getSubscriptionsByCustomer = (customerId: string) =>
    restApi.get<SubscriptionsMetaData>(`api/renewals/customers/${customerId}/subscriptions`).then(({ data }) => data);

export const getBillingSubscriptionByCustomer = (customerId: string) =>
    restApi.get<SubscriptionsMetaData>(`api/renewals/customers/${customerId}/billing-subscriptions`).then(({ data }) => data);

export const getAwaitingConfirmationRenewalsByCustomer = (customerId: string, pageIndex: number, pageSize: number) =>
    restApi.get<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/pending?customerId=${customerId}&PageNumber=${pageIndex}&PageSize=${pageSize}`).then(({ data }) => data);

// export const getConfirmingRenewalsByCustomer = (customerId: string, pageIndex: number, pageSize:number) =>
// restApi.get<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/confirming?customerId=${customerId}&PageNumber=${pageIndex}&PageSize=${pageSize}`).then(({ data }) => data);

// export const getConfirmedRenewalsByCustomer = (customerId: string, pageIndex: number, pageSize:number) =>
// restApi.get<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/confirmed?customerId=${customerId}&PageNumber=${pageIndex}&PageSize=${pageSize}`).then(({ data }) => data);

export const getConfirmedRenewalOrderItemsByCustomer = (customerId: string, pageIndex: number, pageSize: number) =>
    restApi.get<RenewalOfferAdminMetaData>(`api/renewals/customers/orders/items/confirmed?customerId=${customerId}&PageNumber=${pageIndex}&PageSize=${pageSize}`).then(({ data }) => data);

export const getFilterConfirmedRenewalOrderItems = (payload: FilterPayload) =>
    restApi.post<RenewalOrderItemMetaData>(`api/renewals/customers/orders/items/confirmed/filter`, payload).then(({ data }) => data);

export const getRenewalOrderByOrderNumber = (customerId: string, orderNumber: string) =>
    restApi.get<RenewalOrder>(`api/renewals/customers/${customerId}/orders/${orderNumber}`).then(({ data }) => data);

export const getRenewalOrderInvoices = (orderNumber: string) =>
    restApi.get<RenewalOrder>(`api/renewals/orders/${orderNumber}`).then(({ data }) => data);

export const downloadLetterByOrderId = (customerId: string, orderId: string, isStd: boolean) =>
    restApi.get<any>(`api/renewals/customers/${customerId}/orders/${orderId}/download?isStd=${isStd}`, { responseType: 'blob' }).then(({ data }) => data);

export const getFilterInvoices = (payload: FilterPayload) =>
    restApi.post<InvoiceAdminMetaData>(`api/renewals/invoices/filter`, payload).then(({ data }) => data);

export const getFilterRenewalInvoiceByCustomer = (customerId: string, payload: FilterPayload) =>
    restApi.post<InvoiceAdminMetaData>(`api/renewals/customers/${customerId}/invoices/filter`, payload).then(({ data }) => data);

export const downloadInvoice = (invoiceNumber: string) =>
    restApi.get<any>(`api/renewals/invoices/${invoiceNumber}/download`, { responseType: 'blob' }).then(({ data }) => data);

export const getCallLogsByCustomer = (customerId: string) =>
    restApi.get<CallLogMetaData>(`api/renewals/customers/${customerId}/calllogs`).then(({ data }) => data);

export const getProducts = () =>
    restApi.get<Product[]>(`api/renewals/products`).then(({ data }) => data);

export const getRenewalTasks = (payload: FilterPayload) =>
    restApi.post<RenewalTaskMetaData>(`api/renewals/tasks/assigned`, payload).then(({ data }) => data);

export const getRenewalTaskCounts = () =>
    restApi.get<any>(`api/renewals/tasks/countByName`).then(({ data }) => data);

export const getCallTasks = (customerId: string) =>
    restApi.get<Task[]>(`api/renewals/customers/${customerId}/callTasks`).then(({ data }) => data);

export const getTaskStatusResults = () =>
    restApi.get<any>(`api/renewals/tasks/status-result`).then(({ data }) => data);

export const getTaskTypes = () =>
    restApi.get<string[]>(`api/renewals/tasks/types`).then(({ data }) => data);

export const getRenewalOrderHistory = (orderNumber: string) =>
    restApi.get<RenewalTask[]>(`api/renewals/customers/orders/${orderNumber}/history`).then(({ data }) => data);

export const getDataSyncInfo = () =>
    restApi.get<any>(`api/renewals/info/data-sync`).then(({ data }) => data);

export const getLetterVersions = (customerId: string, orderNumber: string) =>
    restApi.get<LetterVersionItem[]>(`/api/renewals/customers/${customerId}/orders/${orderNumber}/versions`).then(({ data }) => data);

export const downloadLetterVersion = (customerId: string, orderNumber: string, version: number | string) =>
    restApi.get<any>(`/api/renewals/customers/${customerId}/orders/${orderNumber}/download/version/${version}`, { responseType: 'blob' }).then(({ data }) => data);

// POST API
export const postRenewalOrder = (customerId: string, payload: AddRenewalOrder): Promise<RenewalOrderItemResponse> =>
    restApi.post<RenewalOrderItemResponse>(`api/renewals/customers/${customerId}/orders`, { ...payload }).then(({ data }) => data);

export const postRenewalOrderItems = (customerId: string, orderNumber: string, payload: AddRenewalOrder): Promise<any> =>
    restApi.post<any>(`api/renewals/customers/${customerId}/orders/${orderNumber}/items`, { ...payload }).then(({ data }) => data);

export const postRenewalTask = (payload: FormData) =>
    restApi.post<FormData>(`api/renewals/tasks`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const postCallLog = (customerId: string, payload: FormData) =>
    restApi.post<FormData>(`api/renewals/customers/${customerId}/calllogs`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const postSubscription = (customerId: string, payload: FormData) =>
    restApi.post<FormData>(`api/renewals/customers/${customerId}/subscriptions`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const payForInvoice = (invoiceNumber: string, payload: PayInvoicePayload) =>
    restApi.post<any>(`api/renewals/invoices/${invoiceNumber}/pay`, payload).then(({ data }) => data);

export const getFilteredProducts = (payload: FilterPayload) =>
    restApi.post<any>(`api/renewals/products/filter`, payload).then(({ data }) => data);

export const resendEmail = (payload: FormData) =>
    restApi.post<any>(`api/renewals/emails/resend`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const getChargeTypeHint = (payload: GetChargeTypePayload) =>
    restApi.post<boolean>(`/api/renewals/item/isRequirePrepay`, payload).then(({ data }) => data);

// PUT API
export const updateRenewalOrder = (customerId: string, orderId: string, payload: UpdateRenewalOrderPayload): Promise<RenewalOrderItemResponse> =>
    restApi.put<RenewalOrderItemResponse>(`/api/renewals/customers/${customerId}/orders/${orderId}`, { ...payload }).then(({ data }) => data);

export const updateCallLog = (customerId: string, callLogId: number | string, payload: FormData) =>
    restApi.put<any>(`api/renewals/customers/${customerId}/calllogs/${callLogId}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const updateSubscription = (customerId: string, subscriptionId: string, payload: FormData) =>
    restApi.put<any>(`api/renewals/customers/${customerId}/subscriptions/${subscriptionId}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

// PATCH API
export const confirmRenewalOrder = (customerId: string, orderId: string): Promise<RenewalOrderItemResponse> =>
    restApi.patch<RenewalOrderItemResponse>(`api/renewals/customers/${customerId}/orders/${orderId}/confirm`).then(({ data }) => data);

export const archiveRenewalOrder = (customerId: string, orderId: string): Promise<PatchResponse> =>
    restApi.patch<PatchResponse>(`api/renewals/customers/${customerId}/orders/${orderId}/archive`).then(({ data }) => data);

export const unarchiveRenewalOrder = (customerId: string, orderId: string): Promise<PatchResponse> =>
    restApi.patch<PatchResponse>(`api/renewals/customers/${customerId}/orders/${orderId}/unarchive`).then(({ data }) => data);

export const paidRenewalOrderInvoice = (invoiceNumber: string, payload: InvoicePaidPayload): Promise<PatchResponse> =>
    restApi.post<PatchResponse>(`api/renewals/invoices/${invoiceNumber}/pay`, { ...payload }).then(({ data }) => data);

export const transferItemsToExistingRenewalOrder = (customerId: string, addPayload: FormData): Promise<TransferOrderItemResponse> =>
    restApi.patch<TransferOrderItemResponse>(`api/renewals/customers/${customerId}/orders/items`, addPayload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const transferItemsToNewRenewalOrder = (customerId: string, fromOrderNumber: string, addPayload: FormData): Promise<TransferOrderItemResponse> =>
    restApi.put<TransferOrderItemResponse>(`api/renewals/customers/${customerId}/orders/${fromOrderNumber}/items`, addPayload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const confirmMyRenewalOrder = (orderNumber: string): Promise<RenewalOrderItemResponse> =>
    restApi.patch<RenewalOrderItemResponse>(`api/renewals/me/orders/${orderNumber}/confirm`).then(({ data }) => data);

// update 1 specific renewal task
export const updateRenewalTask = (taskId: number, payload: FormData) =>
    restApi.patch<any>(`api/renewals/tasks/${taskId}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

// update more than 1 renewal tasks
export const bulkUpdateRenewalTasks = (payload: RenewalTaskBulkUpdateRequest) =>
    restApi.patch<any>(`api/renewals/tasks`, payload).then(({ data }) => data);

export const deleteRenewalTask = (taskId: number | string) =>
    restApi.delete<any>(`api/renewals/tasks/${taskId}`).then(({ data }) => data);

// DELETE API (soft delete)
export const deleteCallLog = (customerId: string, callLogId: number | string) =>
    restApi.delete<any>(`api/renewals/customers/${customerId}/calllogs/${callLogId}`).then(({ data }) => data);

export const deleteRenewalOrderItem = (customerId: string, orderNumber: string, payload: FormData) =>
    restApi.delete<any>(`/api/renewals/customers/${customerId}/orders/${orderNumber}/items`, { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }).then(({ data }) => data);

export const getSubscriptionOptions = () =>
    restApi.get<SubscriptionOption[]>('api/renewals/subscriptions/options').then(({ data }) => data);

export const getCustomerBssOrders = (customerId: string, pageNumber: number = 1, pageSize: number = 10) =>
    restApi.get<BssOrderMetaData>(`api/renewals/customers/${customerId}/bss/orders?PageNumber=${pageNumber}&PageSize=${pageSize}`).then(({ data }) => data);

export const getCustomerBssInvoices = (customerId: string, pageNumber: number = 1, pageSize: number = 10, isOutstanding: boolean = false) =>
    restApi.get<BssInvoiceMetaData>(`api/renewals/customers/${customerId}/bss/invoices?PageNumber=${pageNumber}&PageSize=${pageSize}&isOutstanding=${isOutstanding}`).then(({ data }) => data);

export const syncCustomerData = (customerId: number | string) =>
    restApi.post<string>(`api/renewals/customers/${customerId}/sync`).then(({ data }) => data);