import { IDropdownOptions } from "@app/components/nft-dashboard/common/AccountDetails/interfaces/IDropdownOptions";

export interface AccountDemographicsPayload {
    industry: number;
    computerEmailUsers: number;
}

export interface NameTag {
    company: string,
    name: string,
    role: string,
}

export const initAccountValues: Account = {
    id: "---",
    name: "---",
    billTo: null,
    industry: null,
    size: null,
    subscribedPlusService: null,
    subscribedMITService: null,
    contacts: {},
    hasRequestForChangeOfContacts:false,
    cloudAdvisor: null,
}

export interface Account {
    id: string,
    name: string,
    billTo?: {
        id: string,
        name: string,
    }|null,
    industry: IDropdownOptions|null,
    size: IDropdownOptions|null,
    subscribedPlusService: string|null,
    subscribedMITService: string|null
    contacts?: ContactGroup|{},
    hasRequestForChangeOfContacts:boolean,
    cloudAdvisor?: CloudAdvisor|null,
    
}

export interface CloudAdvisor {
    name?: string,
    email?: string,
}

export interface ContactGroup {
    [key: string]: Contact[],
}

export interface Contact {
    id: string,
    accountId: string,
    firstName?: string,
    lastName?: string,
    fullName?: string,
    email: string,
    phone?: string,
    mobilePhone?: string,
    fax?: string,
    optIn: boolean,
    companyRole?: string,
    contactRoles: {
        Primary: boolean,
        Billing: boolean,
        IT: boolean,
    },
    contactFeatures: {
        Storefront: boolean,
        SubscriptionAndOrderManagement: boolean,
        SecurityAlert: boolean,
        Onsite: boolean,
    },
}

export enum ContactType {
    key = "Key",
    billing = "Billing",
}

export enum ContactLevel {
    primary = "Primary",
    other = "Other",
    na = "N/A",
}

export enum ContactFormAction {
    edit = "edit",
    add = "add",
    delete = "delete",
    undefined = "undefined",
}