import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { ClaimModel } from '@app/domain/ClaimModel';
import { persistClaim, readClaim } from '@app/services/localStorage.service';

export interface ClaimState {
  claim: ClaimModel | null;
}

const initialState: ClaimState = {
  claim: readClaim(),
};

export const setClaim = createAction<PrepareAction<ClaimModel>>('user/setClaim', (newClaim) => {
  persistClaim(newClaim);

  return {
    payload: newClaim,
  };
});

export const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setClaim, (state, action) => {
      state.claim = action.payload;
    });
  },
});

export default claimSlice.reducer;
