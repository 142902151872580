import { Button, Result, Image } from 'antd'
import { useNavigate } from 'react-router-dom';
import LogoImage from '@app/assets/images/SH_Logo_CR_PNG.png';

const NotAuthorizedToAccessPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <div style={{width: '100vw', paddingTop: '48px', display:'flex', justifyContent:'center'}}>
                <Image style={{width: '300px'}} src={LogoImage} alt="SUPERHUB" preview={false} />
            </div>
            <Result
                status="403"
                title="Not Authorized to Access Page"
                subTitle={
                    <>
                        You redirected to this page because you are not authorized to access the previous page.
                    </>
                }
                extra={<Button type="primary" onClick={() => navigate(-1)}>Back</Button>}
            />
        </>
    )
}

export default NotAuthorizedToAccessPage