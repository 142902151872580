import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as S from './AccessTokenExpirationCountDown.styles';
import { clearLocalStorage } from '@app/services/localStorage.service';
import { doLogout } from '@app/store/slices/authSlice';

interface AccessTokenExpirationCountDownProps {
    expiration?: number,
}

const AccessTokenExpirationCountDown = ({ expiration }:AccessTokenExpirationCountDownProps) => {
    const [remainingTime, setRemainingTime] = useState<number>();
    const navigate = useNavigate();
    
    // Effects
    useEffect(() => {
        const interval = setInterval(() => {
          if (expiration) {
            const currentTime = Math.floor(Date.now() / 1000);
            const remaining = expiration - currentTime;
    
            if (remaining <= 0) {
              // Token has expired
              setRemainingTime(0);
              clearInterval(interval);

              // Call necessary functions whe the token expired
              clearLocalStorage();
              doLogout();
              navigate('/login-session-expired');
            } else {
              setRemainingTime(remaining);
            }
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
    }, [expiration]);

    // Function
    const formatTime = (time?:number) => {
      
      if (time == null) return "--:--:--";

      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = time % 60;
  
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    
    return (
        <>
          <S.CountDownText>Login session will expire in {formatTime(remainingTime)}</S.CountDownText>
        </>
    );
}

export default AccessTokenExpirationCountDown