import { ReactComponent as WhiteSquareIcon } from '@app/assets/icons/white-square.svg';
import * as S from '../../../nft-dashboard/common/CommonComponentStyle.styles';

export const SiderItem = (props:{isCurrentLocation:boolean, title1:string, title2:string, onClick:any})=>{

    return (
        <>
            <S.SiderItemDiv className={props.isCurrentLocation?'current':''} onClick={props.onClick} id="menu-icon" tabIndex={0} >
                <S.SiderItemText className="icon-text">{props.title1}</S.SiderItemText>
                <S.SiderItemText className="icon-text">{props.title2}</S.SiderItemText>
            </S.SiderItemDiv>
        </>
    )
}
