import { useCan } from "@app/components/common/CustomHook/useCan";
import { ClarityAPIEventType } from "@app/enums/ClarityAPIEventType";
import { useAppDispatch } from "@app/hooks/reduxHooks";
import { doVerifyMarketplaceAccount } from "@app/store/slices/authSlice"
import { logClarityEvent } from "@app/utils/clarityutils";
import { Alert, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useClarity } from "use-clarity";
import * as S from "../../common/CommonComponentStyle.styles";
import { themeObject } from "@app/styles/themes/themeVariables";
import { readClaim } from "@app/services/localStorage.service";
import { initRenewalCustomer, RenewalCustomer } from "@app/interfaces/renewals";
import { getMyCustomer } from "@app/api/renewalCenter.api";
import { getBalance } from "../../admin/TokenCenter/Customers/ConversionHelper";
import { CardSkeleton } from "@app/components/common/Skeleton/CardSkeleton";

export const AccountStatementPage = () => {
    const logoColor = themeObject['light'].primary;
    const { event } = useClarity();
    const claim = readClaim();
    const dispatch = useAppDispatch();
    const [customer, setCustomer] = useState<RenewalCustomer>(initRenewalCustomer);
    const [showValidMsg, setShowValidMsg] = useState<boolean>(false);
    const [showActivationMsg, setShowActivationMsg] = useState<boolean>(false);
    const [showInvalidMsg, setShowInvalidMsg] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const getCustomer = () => {
        setLoading(true);
        getMyCustomer()
            .then((res) => {
                setCustomer(res);
                logClarityEvent(event, `Customer-account-statement-${ClarityAPIEventType.GET_CUSTOMER_ACCOUNT_SUCCESS_EVENT}`)
                if (res.isBalanceCustomerViewEnabled == false) {
                    checkOMPAccount();
                }else{
                    setLoading(false);
                }
            })
            .catch((err) => logClarityEvent(event, `Customer-account-statement-${ClarityAPIEventType.GET_CUSTOMER_ACCOUNT_ERROR_EVENT}`))
    }

    const checkOMPAccount = () => {
        dispatch(doVerifyMarketplaceAccount({ email: (claim?.email) as string }))
            .unwrap()
            .then((res) => {
                if (res == "Validated") {
                    setShowValidMsg(true);
                    logClarityEvent(event, `Customer-${ClarityAPIEventType.ACCOUNT_STATEMENT_GET_VALID_MSG_SUCCESS_EVENT}`)
                    window.open(process.env.REACT_APP_USER_OMP_URL, '_blank')?.focus()
                } else {
                    setShowActivationMsg(true);
                    logClarityEvent(event, `Customer-${ClarityAPIEventType.ACCOUNT_STATEMENT_SEND_ACTIVATE_EMAIL_SUCCESS_EVENT}`)
                }
            })
            .catch((err) => {
                setShowInvalidMsg(true);
                logClarityEvent(event, `Customer-${ClarityAPIEventType.ACCOUNT_STATEMENT_GET_INVALID_MSG_SUCCESS_EVENT}`)
            }).finally(()=>setLoading(false))
    };

    useEffect(() => {
        getCustomer();
    }, [])

    const getBalanceCard = useCan({
        canAccess: customer.isBalanceCustomerViewEnabled == true,
        Component:
            <>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <S.ProfileStatisticCard size="default">
                            {
                                isLoading ? <CardSkeleton />
                                    :
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', color: '#b0b0b0', fontSize: '14px' }}>
                                            <S.BlackHeaderText>Balance</S.BlackHeaderText>
                                        </div>
                                        <S.SummaryCardText style={{
                                            fontSize: '20px',
                                            display: 'flex',
                                            lineHeight: '2rem',
                                            justifyContent: 'center'
                                        }}>{getBalance(customer.balance, customer.currency)}</S.SummaryCardText>
                                    </>
                            }
                        </S.ProfileStatisticCard>
                    </Col>
                </Row>
                <Row style={{width:'100%', marginTop:'20px'}}>
                    <p>Remark: It may take up to three business days for the payment to be reflected in the system.</p>
                </Row>
            </>
    })

    const getValidMsg = useCan({
        canAccess: (customer.isBalanceCustomerViewEnabled == false) && showValidMsg,
        Component: <S.AccountStatementAlert
            type="success"
            message="Your account is ready"
            showIcon
            description={
                <S.EmailTemplateAlertMessage>
                    <a href={process.env.REACT_APP_USER_OMP_URL} target="_blank">Click here</a> to check your account balance, all invoices, credit note and payment history. You can view any available invoice details and download a copy of invoice for records.
                </S.EmailTemplateAlertMessage>
            }
        />
    })

    const getActivationMsg = useCan({
        canAccess: (customer.isBalanceCustomerViewEnabled == false) && showActivationMsg,
        Component: <S.AccountStatementAlert
            type="warning"
            message="One more step"
            showIcon
            description={<S.EmailTemplateAlertMessage>
                Your account in Billing Center is pending for activation. We have sent you an activation email just now. Please check your email box to setup and confirm password.
            </S.EmailTemplateAlertMessage>}
        />
    })

    const getInvalidMsg = useCan({
        canAccess: (customer.isBalanceCustomerViewEnabled == false) && showInvalidMsg,
        Component: <S.AccountStatementAlert
            type="warning"
            message="Your email address is not registered yet"
            showIcon
            description={<S.EmailTemplateAlertMessage>To access the page, we recommend you to contact your company's administrator to submit request to <span style={{ color: logoColor }}>SUPERHUB</span> for granting you additional right. Thank you for your attention.</S.EmailTemplateAlertMessage>}
        />
    })

    const renderPageLoading = () => (
        <Spin indicator={<S.SpinIcon spin={true} />} />
    );

    const renderContent = () => {
        return (
            <>
                <S.WrapperRow style={{ marginTop: '15px' }}>
                    <Col>
                        <S.PageTitle level={1}>{customer.isBalanceCustomerViewEnabled == true ? 'Account Balance' : 'Account Statement'}</S.PageTitle>
                    </Col>
                </S.WrapperRow>
                <S.WrapperRow>
                    {getBalanceCard}
                    {getValidMsg}
                    {getActivationMsg}
                    {getInvalidMsg}
                </S.WrapperRow>
            </>
        )
    }
    return (isLoading ? renderPageLoading() : renderContent())
}