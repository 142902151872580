import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { VerifyMarketplaceAccountForm } from '@app/components/auth/VerifyMarketplaceAccountForm/VerifyMarketplaceAccountForm';

const MarketplaceLandingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>Billing Center - SUPERHUB MyHUB</PageTitle>
      <VerifyMarketplaceAccountForm />
    </>
  );
};

export default MarketplaceLandingPage;